import * as React from 'react';
import { FormEvent } from 'react';
import * as _ from 'lodash';
import { LearnerGrade } from '../../../models/learner-grade';
import Input from '../../../components/pearson/input';
import { UploadFileError } from 'models/upload-file-error';

interface RankingInputProps {
  learner: LearnerGrade;
  onChange: (learner: LearnerGrade) => void;
}

const RankingInput = ({
  learner,
  onChange
}: RankingInputProps) => {

  const getError = () => {
    if (learner.hasWarnings) {
      const warning = _.find(learner.warnings, (warning: UploadFileError) =>
        warning.errorDescription.toLowerCase().includes("rank")
      )
      if (warning) {
        return warning.errorDescription;
      }
    }
    return "";
  }

  const onRankingChange = (event: FormEvent<HTMLInputElement>) => {
    event.preventDefault();
    const regEx = new RegExp(/^[0-9\b]+$/);
    const ranking = (event.target as HTMLInputElement).value.trim();
    const rankingString = _.padStart(ranking.toString(), 5, '0');
    if (ranking === '' || regEx.test(ranking)) {
      onChange({
        ...learner,
        ranking: ranking === '' ? null : Number(ranking),
        rankingString
      });
    }
  }
  return (
    <div className="ranking-input">
      <Input
        id={learner.learnerIdentifier}
        placeholder=""
        maxLength={5}
        value={learner.ranking || learner.ranking === 0 ? learner.ranking.toString() : ""}
        errors={getError()}
        onChange={onRankingChange}
      />
    </div>
  )
};

export default RankingInput;
import * as React from "react";
import { createRef, useState, RefObject, ChangeEvent, useEffect } from "react";
import _ from "lodash";
import { AppealEvidence } from "../../../models/appeal-evidence";
import { AppealFileUpload } from "../../../models/appeal-file-upload";
import { alert } from "../../../components/pearson/alert";
import AppealEvidenceCard from "./appeal-evidence-card";
import { Button } from "../../../components/pearson/button";
import Loader from "../../../components/pearson/loader";

interface AppealEvidenceCardListProps {
  appealId: string;
  appealEvidence: AppealEvidence[];
  learningProviderId: string;
  showDownloadLink: boolean;
  showUploadButton:boolean;
  onDownloadAppealEvidence?: (fileId: string) => void;
  onUploadAppealFile?: (file: AppealFileUpload) => Promise<void>;
}

export const AppealEvidenceCardList = ({
  appealId,
  appealEvidence,
  learningProviderId,
  showDownloadLink,
  showUploadButton,
  onDownloadAppealEvidence,
  onUploadAppealFile
}: AppealEvidenceCardListProps) => {  

  const fileUploadRef = createRef() as RefObject<HTMLInputElement>;
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [refreshRequired, setRefreshRequired] = useState(false);

  useEffect(() => {
    setRefreshRequired(false)
  }, [appealEvidence]);  

  const onUpload = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault;
    if (onUploadAppealFile) {
      const fileInput = (event.target as HTMLInputElement);
      if (fileInput.files) {
        setUploadInProgress(true);
        setRefreshRequired(true)
        onUploadAppealFile({
          learningProviderId,
          appealId,
          file: fileInput.files[0],
          status: "",
        })
        .then(() => {
          alert.success("The attachment has been uploaded.")
        })
        .catch((error) => alert.error(error))
        .finally(() => {
          fileInput.value = "";
          setUploadInProgress(false);
        })
      }
    }  
  };
  
  return (
    <div className="appeal-evidence-card-list">   
      {showUploadButton && (
        <div className="upload-button">
          <Button
            label="Add attachment"
            className="primary"
            disabled={uploadInProgress || refreshRequired}
            onClick={() => fileUploadRef.current?.click()}
          />
          <input
            ref={fileUploadRef}
            id="file"
            type="file"
            style={{ display: "none" }}
            onChange={onUpload}
          />
          <Loader loading={uploadInProgress || refreshRequired } loadingStateLabel="Uploading attachment..." />
        </div>
      )}     
      <label className="gr-label">Attachments ({appealEvidence.length})</label>
      {_.map(appealEvidence, (evidence: AppealEvidence, index: number) => (
        <AppealEvidenceCard 
          key={index}           
          appealEvidence={evidence}
          showDownloadLink={showDownloadLink}
          onDownloadAppealEvidence={onDownloadAppealEvidence}
        />
      ))}
    </div>
  )
};

export default AppealEvidenceCardList;

import { connect } from "react-redux";
import { StoreState } from "store/store-state";
import { ThunkDispatch } from "redux-thunk";
import _ from "lodash";
import { AppealActions, acknowledgeAppeal, getAppealLearners, saveAppeal } from "../../../actions/appeal.actions";
import { AppealFileActions, downloadAppealEvidence, uploadAppealFile } from "../../../actions/appeal-upload.actions";
import { AppealFileUpload } from "../../../models/appeal-file-upload";
import { AppealLearner } from "../../../models/appeal-learner";
import { AppealSubmit } from "../../../models/appeal-submit";

import {
  CreateAppealPage,
  StateProps,
  OwnProps,
  DispatchProps,
} from "./create-appeal-page";


const mapStateToProps = (state: StoreState, ownProps: OwnProps): StateProps => {
  const qualificationId = ownProps.match.params.qualificationId;
  return {    
    appeal: state.appealDetail,
    appealTypes: state.appealTypes,
    learningProviderId: state.selectedLearningProviderId,
    qualification: _.find(state.qualifications, { "id": qualificationId })    
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<StoreState, void,
  AppealActions |
  AppealFileActions
>) : DispatchProps => ({
  acknowledgeAppeal: (
    learningProviderId: string,
    appealId: string) =>
    dispatch(acknowledgeAppeal(learningProviderId, appealId)),
  downloadAppealEvidence: (
    learningProviderId: string,
    appealId: string,
    fileId: string) =>
    downloadAppealEvidence(learningProviderId, appealId, fileId),
  getAppealLearners: (
    learningProviderId: string,
    qualificationId: string) : Promise<AppealLearner[]> =>
    dispatch(getAppealLearners(learningProviderId, qualificationId)),
  saveAppeal: (
    learningProviderId: string,
    qualificationId: string,
    appeal: AppealSubmit) =>
    dispatch(saveAppeal(learningProviderId, qualificationId, appeal)),
  uploadAppealFile: (
    appealFileUpload: AppealFileUpload) =>
    dispatch(uploadAppealFile(appealFileUpload))
});

export default connect<StateProps>(
  mapStateToProps,
  mapDispatchToProps
)(CreateAppealPage);

import * as React from 'react';
import { useEffect, useState } from 'react';
import * as _ from "lodash";
import "./stepper.scss";

export class Step {
  description: string;
  highlighted: boolean;
  selected: boolean;
  completed: boolean;
}

interface StepperProps {
  direction: "horizontal" | "vertical",
  currentStepNumber: number,
  steps: string[]
}

export const Stepper = ({ 
  direction,
  currentStepNumber,  
  steps
}: StepperProps) => {

  const updateStep = (stepNumber: number, steps: Step[]) => {
    const newSteps = [...steps];
    let stepCounter = 1;
    while (stepCounter < newSteps.length) {
      if (stepCounter === stepNumber) {
        newSteps[stepCounter - 1] = {
          ...newSteps[stepCounter - 1],
          highlighted: true,
          selected: true,
          completed: false
        };
        stepCounter++;
      }
      else if (stepCounter < stepNumber) {
        newSteps[stepCounter - 1] = {
          ...newSteps[stepCounter - 1],
          highlighted: false,
          selected: true,
          completed: true
        };
        stepCounter++;
      }
      else {
        newSteps[stepCounter - 1] = {
          ...newSteps[stepCounter - 1],
          highlighted: false,
          selected: false,
          completed: false
        };
        stepCounter++;
      }
    }
    return newSteps;
  }

  const [currentSteps, setCurrentSteps] = useState(  
    updateStep(currentStepNumber, steps.map((step, index) => {
      const s = new Step();
      s.description = step;
      return s;
    }))
  );

  useEffect(() => {
    setCurrentSteps(updateStep(currentStepNumber, currentSteps));
  }, [currentStepNumber]); 

  return (
    <div className={`stepper ${direction}`}>
      {_.map(currentSteps, (currentStep, index) => (
        <div key={index} className="step">
          <div className={`step-number ${currentStep.selected ? "step-number-selected" : "step-number-disabled"}`}>
            {currentStep.completed ? <span>&#10003;</span> : index + 1}
          </div>
          <div className={`step-description ${currentStep.highlighted && "step-description-active"}`}>
            {currentStep.description}
          </div>
          {index !== steps.length - 1 && (
            <div className={`progress-bar progress-bar-${steps.length} ${currentStep.selected && !currentStep.highlighted && "progress-bar-active"}`} />
          )}
        </div>
      ))}   
    </div>
  )
}

export default Stepper;
import { Dispatch, Action } from "redux";
import { ThunkAction } from "redux-thunk";

import {
  AUTH_IN_PROGRESS,
  AUTH_USER,
  UNAUTH_USER,
  AUTH_ERROR
} from "../action-types";

import { StoreState } from "../../store/store-state";
import AuthForgeRock from "./auth";

const authForgeRock = new AuthForgeRock();

interface AuthInProgressAction extends Action {
  type: AUTH_IN_PROGRESS;
}

interface AuthSigninSuccessAction extends Action {
  type: AUTH_USER;
  profile: any;
}

interface AuthSignoutSuccessAction extends Action {
  type: UNAUTH_USER;
}

interface AuthErrorAction extends Action {
  type: AUTH_ERROR;
  error: string;
  timestamp: number;
}

export type AuthAction =
  | AuthInProgressAction
  | AuthSigninSuccessAction
  | AuthSignoutSuccessAction
  | AuthErrorAction;

type ThunkResult<R> = ThunkAction<R, StoreState, undefined, AuthAction>;

export const authErrorAction = (error: string): AuthErrorAction => {
  const timestamp = Date.now();
  return {
    type: AUTH_ERROR,
    error,
    timestamp,
  };
};

export const authSigninSuccessAction = (): AuthSigninSuccessAction => ({
  type: AUTH_USER,
  profile: authForgeRock.getProfile(),
});

export const signinUser = (dispatch: Dispatch<AuthInProgressAction>) => {
  dispatch({ type: AUTH_IN_PROGRESS });
  authForgeRock.signin();
};

export const signoutUser = (dispatch: Dispatch<AuthInProgressAction>) => {
  dispatch({ type: AUTH_IN_PROGRESS });
  authForgeRock.signout();
};

export const handleAuthentication = (
  callback: (err?: string) => void
): ThunkResult<void> => {
  return (dispatch) => {
    authForgeRock
      .handleAuthentication()
      .then(() => {
        dispatch(authSigninSuccessAction());
        return callback();
      })
      .catch((err) => {
        signoutUser(dispatch);

        setTimeout(() => {
          const error = `${err.error}, Error Description: ${err.errorDescription}`;
          dispatch(authErrorAction(error));
        }, 1000);

        return callback(err);
      });
  };
};

export class UploadFileHistory {
    historyId: string;
    fileName: string;
    uploadedDate: Date;
    uploadedBy: string;
    status: string;
    statusCode: string;
    processed: string;
    warning: string;
    versionId: string;
    searchString: string;
    hasErrors: boolean;
    hasWarnings: boolean;
    isProcessed: boolean;
    statusSortOrder: string;
    latest: boolean;
    uploadType: string;
}
import * as React from 'react';
import config from "../../app.config";

export interface StateProps {
}

interface Props extends StateProps { }

export class UnavailablePage extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
    document.title = "System unavailable";
  }
  render = () => {
    return (
      <div className="unavailable-page">
        <div className="jumbotron">
          <h1>{document.title}</h1>
          <p>
            A network problem is preventing the Centre Assessment Grade capture service from opening.
          </p>
          <p>
            This may be a local network issue, so please check the <a href={config.HELP_LINKS.UNAVAILABLE_HELP} target="_blank">help page</a> for updates from Pearson on service availability.
          </p>
        </div>  
      </div>
    );
  };
}

export default UnavailablePage;

import * as React from "react";
import { withRouter, Redirect, RouteComponentProps } from "react-router";
import _ from "lodash";
import { AppealSearch } from "../../models/appeal-search";
import { AppealSearchPaging } from "../../models/appeal-search-paging";
import { AppealType } from "../../models/appeal-type";
import { Option } from '../../models/option';
import { Team } from "../../models/team";
import { TeamAppeal } from "../../models/team-appeal";
import { alert } from "../../components/pearson/alert";
import Button from "../../components/pearson/button";
import Input from "../../components/pearson/input";
import Loader from "../../components/pearson/loader";
import Select from "../../components/pearson/select";
import Tabs from "../../components/pearson/tabs";
import TeamAppealCardList from "./components/team-appeal-card-list";
import TeamAppealSearch from "./components/team-appeal-search";
import TeamSelect from "./components/selects/team-select";

export interface StateProps {
  activeIdx: number;
  appealTypes: AppealType[];
  internal: boolean;
  myWork: TeamAppeal[];
  myWorkSearch: AppealSearch;
  referenceCodeAppeal: TeamAppeal | null;
  referenceCodeIsValid: boolean;
  referenceCodeSearch: string;
  teamWork: TeamAppeal[];
  teamWorkSearch: AppealSearch;
  unallocatedWork: TeamAppeal[];
  unallocatedWorkSearch: AppealSearchPaging;
  teamId: number | null;
  teams: Team[];
}
export interface DispatchProps {
  getAppealByReferenceCode: (referenceCode: string) => Promise<void>;
  getMyTeamAppeals: (teamId: number, appealSearch: AppealSearch) => Promise<void>;
  getTeamAppeals: (teamId: number, appealSearch: AppealSearch) => Promise<void>;
  getUnallocatedTeamAppeals: (teamId: number, appealSearch: AppealSearchPaging, loadMoreWork: boolean) => Promise<void>;
  setActiveIdx: (activeIdx: number) => void;
  setTeamId: (teamId: number) => void;
  setTeamWorkSearch: (teamWorkSearch: AppealSearch) => void;
};

interface Props extends DispatchProps, StateProps, RouteComponentProps { }

export interface LocalState {
  disableLoadMoreWork: boolean;
  loading: boolean;
  myWorkSearch: AppealSearch;
  referenceCodeSearch: string;
  teamWorkSearch: AppealSearch;
  unallocatedWorkSearch: AppealSearchPaging;
}

export class AppealsManagementPage extends React.Component<Props, LocalState> {
  constructor(props: Props) {
    super(props);
    document.title = "Appeals management";
    this.state = {
      disableLoadMoreWork: true,
      loading: false,
      myWorkSearch: props.myWorkSearch,
      referenceCodeSearch: props.referenceCodeSearch,
      teamWorkSearch: props.teamWorkSearch,
      unallocatedWorkSearch: props.unallocatedWorkSearch,
    };
  }

  render = () => {
    const { activeIdx, internal, myWork, referenceCodeAppeal, referenceCodeIsValid, teamWork, unallocatedWork, teamId, teams } = this.props;
    const { loading, myWorkSearch, referenceCodeSearch, teamWorkSearch, unallocatedWorkSearch } = this.state;

    if (!internal) {
      return <Redirect to="/access-denied" />
    }

    return (
      <div className="appeals-management-page">
        <Loader loading={loading} loadingStateLabel="Loading appeals..." />
        <div className="header jumbotron">
          <h1>Appeals management</h1>
          <TeamSelect
            teamId={teamId}
            teams={teams}           
            onChange={this.teamChange}
          />
        </div>
        {teamId && (
          <Tabs
            activeIdx={activeIdx}
            onTabChange={this.tabChange}
            tabs={[
              "My work",
              "Allocate new work",
              "Teams work",
              "Find by reference"
            ]}
            panels={[
              <div className="my-work">
                <TeamAppealSearch
                  appealSearch={myWorkSearch}
                  appealStateOptional={true}
                  disableSearch={loading}
                  showPageSize={false}
                  onChange={(appealSearch) => (
                    this.setState({ myWorkSearch: { ... this.state.myWorkSearch, ...appealSearch } })
                  )}
                  onSearch={this.getMyWork}
                />
                {!loading && (
                  <TeamAppealCardList
                    appeals={myWork}
                    showNoAppealsMessage={myWorkSearch.showNoAppealsMessage}
                    onViewAppeal={(appeal) => this.viewAppeal(appeal)}
                  />
                )}
              </div>,
              <div className="allocate-new-work">
                <TeamAppealSearch
                  appealSearch={unallocatedWorkSearch}
                  appealStateOptional={false}
                  disableSearch={loading}
                  showPageSize={true}
                  onChange={(appealSearch) => (
                    this.setState({ unallocatedWorkSearch: { ... this.state.unallocatedWorkSearch, ...appealSearch } })
                  )}
                  onSearch={() => this.getUnallocatedWork(false)}
                />
                <TeamAppealCardList
                  appeals={unallocatedWork}
                  showNoAppealsMessage={unallocatedWorkSearch.showNoAppealsMessage}
                  onViewAppeal={(appeal) => this.viewAppeal(appeal)}
                />
                {unallocatedWorkSearch.hasMoreWork && (
                  <div className="load-more-work-button">
                    <Button
                      type="button"
                      label="Load more"
                      className="attention"
                      onClick={() => this.getUnallocatedWork(true)}
                    />
                  </div>
                )}            
              </div>,
              <div className="teams-work">
                <TeamAppealSearch
                  appealSearch={teamWorkSearch}
                  appealStateOptional={true}
                  disableSearch={loading}
                  showPageSize={false}
                  onChange={(appealSearch) => (
                    this.setState({ teamWorkSearch: { ...teamWorkSearch, ...appealSearch } })
                  )}
                  onSearch={this.getTeamWork}
                />
                {!loading && (
                  <>
                    {teamWork.length > 0 && (
                      <div className="filter-by-user">
                        <Select
                          id="filterByUser"
                          label="Filter by allocated to:"
                          value={this.props.teamWorkSearch.assignedPearsonUserId}
                          options={this.getTeamUserOptions()}
                          onChange={(e) => this.filterTeamWorkByUser(e.currentTarget.value)}
                        />
                      </div> 
                    )}
                    <TeamAppealCardList
                      appeals={teamWorkSearch.assignedPearsonUserId === "All"
                        ? teamWork
                        : _.filter(teamWork, ["assignedPearsonUserId", teamWorkSearch.assignedPearsonUserId])
                      }
                      showNoAppealsMessage={this.props.teamWorkSearch.showNoAppealsMessage}
                      onViewAppeal={(appeal) => this.viewAppeal(appeal)}
                    />
                  </>                  
                )}
              </div>,
              <div className="find-by-reference">
                <div className="team-appeal-search">
                  <Input
                    id="referenceCode"
                    label="Reference:"
                    errors={referenceCodeIsValid ? "" : "Invalid reference"}                    
                    maxLength={50}
                    value={referenceCodeSearch}
                    onChange={(e) => this.setState({ referenceCodeSearch: e.currentTarget.value })}
                  />
                  <div className="search-button">
                    <Button
                      label="Search"
                      className="attention"
                      disabled={loading || referenceCodeSearch.length === 0}
                      onClick={this.getAppealByReferenceCode}
                    />
                  </div>
                </div>
                {referenceCodeAppeal && (
                  <TeamAppealCardList
                    appeals={[{ ...referenceCodeAppeal }]}
                    onViewAppeal={(appeal) => this.viewAppeal(appeal)}
                  />
                )}
              </div>,
              
            ]}
          />
        )}
      </div>
    )
  };

  componentDidMount = () => {
    const { teamId, unallocatedWork, unallocatedWorkSearch } = this.props;
    this.getMyWork();
    if (teamId && unallocatedWork.length === 0) {
      this.props.getUnallocatedTeamAppeals(teamId, unallocatedWorkSearch, false);
    }
  };

  componentDidUpdate(prevProps: StateProps, prevState: LocalState) {
    const { myWorkSearch, teamId, teamWorkSearch, unallocatedWorkSearch } = this.props;
    if (teamId && prevProps.teamId !== teamId) {
      Promise.all([
        this.getMyWork(),
        this.getUnallocatedWork(false)
      ])        
    }
    if (!_.isEqual(prevProps.myWorkSearch, myWorkSearch)) {
      this.setState({myWorkSearch: { ...myWorkSearch }})
    }
    if (!_.isEqual(prevProps.teamWorkSearch, teamWorkSearch)) {
      this.setState({ teamWorkSearch: { ...teamWorkSearch } })
    }
    if (!_.isEqual(prevProps.unallocatedWorkSearch, unallocatedWorkSearch)) {
      this.setState({ unallocatedWorkSearch: { ...unallocatedWorkSearch } })
    }
  }

  filterTeamWorkByUser = (userId: string) => {
    const assignedPearsonUserId = userId === "" ? null : userId;
    this.props.setTeamWorkSearch({ ...this.props.teamWorkSearch, assignedPearsonUserId})
  }
  
  getAppealByReferenceCode = () => {
    const { referenceCodeSearch } = this.state;
    this.setState({ loading: true });
    this.props.getAppealByReferenceCode(referenceCodeSearch)
      .catch((error) => alert.error(error))
      .finally(() => this.setState({
        loading: false
      }))
  }

  getMyWork = () => {
    const { teamId } = this.props;
    const { myWorkSearch } = this.state;
    if (teamId) {
      this.setState({ loading: true });
      this.props.getMyTeamAppeals(teamId, myWorkSearch)
        .catch((error) => alert.error(error))
        .finally(() => this.setState({
          loading: false
        }))
    }
  }

  getTeamWork = () => {
    const { teamId } = this.props;
    const { teamWorkSearch } = this.state;
    if (teamId) {
      this.setState({ loading: true });
      this.props.getTeamAppeals(teamId, teamWorkSearch)
        .catch((error) => alert.error(error))
        .finally(() => this.setState({
          loading: false
        }))
    }
  }

  getUnallocatedWork = (loadMoreWork: boolean = false) => {
    const { getUnallocatedTeamAppeals, teamId } = this.props;
    const { unallocatedWorkSearch } = this.state;
    if (teamId) {
      this.setState({ loading: true });
      getUnallocatedTeamAppeals(
        teamId,
        loadMoreWork ? unallocatedWorkSearch : {...unallocatedWorkSearch, token: null}, 
        loadMoreWork
      )
      .catch((error) => alert.error(error))
      .finally(() => this.setState({ loading: false }))
    }
  }

  getTeamUserOptions = () => {
    const options: Option[] = [
      { name: "All", value: "All"},
      { name: "Unallocated", value: "" }
    ];
    _.map(_.orderBy(_.uniqBy(this.props.teamWork, "assignedPearsonUserId"), ["assignedUserName"]), 
    (appeal: TeamAppeal) => {
      if (appeal.assignedPearsonUserId) {
        options.push({ name: appeal.assignedUserName, value: appeal.assignedPearsonUserId })
      }
    })
    return options;
  }
 
  tabChange = (activeIdx: number) => {
    this.props.setActiveIdx(activeIdx);
  }

  teamChange = (teamId: number) => {
    localStorage.setItem("teamId", teamId.toString());
    this.props.setTeamId(teamId);
  }

  viewAppeal = (appeal: TeamAppeal) => {
    this.props.history.push(`/appeals-management/view/${appeal.learningProviderId}/${appeal.appealId}`);
  };
}

export default withRouter(AppealsManagementPage);
import initialState from './initialState';
import { AppealActions } from "../actions/appeal.actions"
import { AppealType } from "../models/appeal-type"
import { 
 GET_APPEAL_TYPES_SUCCESS
} from "../actions/action-types";

export function appealTypesReducer(
  state: AppealType[] = initialState.appealTypes,
  action: AppealActions 
): AppealType[] {

  switch (action.type) {
    case GET_APPEAL_TYPES_SUCCESS:
      return [...action.appealTypes];
    default:
      return state;
  }
};


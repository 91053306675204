import axios, { AxiosRequestConfig } from "axios";
import { ThunkDispatch } from "redux-thunk";
import { StoreState } from "../store/store-state";
import config from "../app.config";
import { AppealFileUpload } from "../models/appeal-file-upload";
import { AppealFileUploadStatus } from "../models/appeal-file-upload-status";
import { AppealDetail } from '../models/appeal-detail';
import { Action } from "redux";
import * as action_types from "./action-types";
import { getAppealDetailsSuccessAction, mapAppealDetail } from "./appeal.actions";

interface AppealFileUploadInProgressAction extends Action {
    type: action_types.APPEAL_FILE_UPLOAD_IN_PROGRESS;
    appealFileUploadStatus: AppealFileUploadStatus
}

interface AppealFileUploadedAction extends Action {
    type: action_types.APPEAL_FILE_UPLOADED;
    appealFileUploadStatus: AppealFileUploadStatus
}

interface AppealFileUploadFailedAction extends Action {
    type: action_types.APPEAL_FILE_UPLOAD_FAILED;
    appealFileUploadStatus: AppealFileUploadStatus
}

interface AppealFileUploadIncorrectFileTypeAction extends Action {
    type: action_types.APPEAL_FILE_UPLOAD_INCORRECT_FILE_TYPE;
    appealFileUploadStatus: AppealFileUploadStatus
}

interface GetAppealDetailsSuccessAction extends Action {
    type: action_types.GET_APPEAL_DETAILS_SUCCESS;
    appealDetail: AppealDetail;
  }

export type AppealFileActions = 
    AppealFileUploadInProgressAction | 
    AppealFileUploadedAction | 
    AppealFileUploadFailedAction | 
    AppealFileUploadIncorrectFileTypeAction |
    GetAppealDetailsSuccessAction;

export const appealFileUploadInProgressAction = (
    appealFileUploadStatus: AppealFileUploadStatus
): AppealFileUploadInProgressAction => ({
    type: action_types.APPEAL_FILE_UPLOAD_IN_PROGRESS,
    appealFileUploadStatus
});

export const appealFileUploadedAction = (
    appealFileUploadStatus: AppealFileUploadStatus
): AppealFileUploadedAction => ({
    type: action_types.APPEAL_FILE_UPLOADED,
    appealFileUploadStatus
});

export const appealFileUploadFailedAction = (
    appealFileUploadStatus: AppealFileUploadStatus
): AppealFileUploadFailedAction => ({
    type: action_types.APPEAL_FILE_UPLOAD_FAILED,
    appealFileUploadStatus
});

export const appealFileUploadIncorrectFileTypeAction = (
    appealFileUploadStatus: AppealFileUploadStatus
): AppealFileUploadIncorrectFileTypeAction => ({
    type: action_types.APPEAL_FILE_UPLOAD_INCORRECT_FILE_TYPE,
    appealFileUploadStatus
});

export const uploadAppealFile = (appealFileUploadFile: AppealFileUpload) => {
  return (dispatch: ThunkDispatch<StoreState, void, AppealFileActions>, getState: any) => {

      const fileType = appealFileUploadFile.file.name.split('.').pop()?.toLowerCase() ?? "";

      if (!config.APPEAL_EVIDENCE_FILE_EXTENTIONS.split("|").includes(fileType)) {
        dispatch(appealFileUploadIncorrectFileTypeAction({ 
            learningProviderId: appealFileUploadFile.learningProviderId,
            appealId: appealFileUploadFile.appealId,
            fileName: appealFileUploadFile.file.name,
            status: 'IncorrectFileType'
        }));
        
        return Promise.resolve();
    }

    dispatch(appealFileUploadInProgressAction({ 
        learningProviderId: appealFileUploadFile.learningProviderId,
        appealId: appealFileUploadFile.appealId,
        fileName: appealFileUploadFile.file.name,
        status: 'InProgress'
    }));

    const axiosConfig: AxiosRequestConfig = {
      headers: { "content-type": "application/json" }
    };
    return axios
      .post(
        `${config.API_GATEWAY.URL}/appeal/${appealFileUploadFile.learningProviderId}/${appealFileUploadFile.appealId}/uploadurl`,
        JSON.stringify(appealFileUploadFile.file.name),
        axiosConfig
      )
      .then(response => {

          var presignedUrl = response.data;

          const fileTags = `LearningProviderId=${appealFileUploadFile.learningProviderId}&AppealId=${appealFileUploadFile.appealId}&SourceSystem=GC`;

          const xhr = new XMLHttpRequest();        
          xhr.open("PUT", presignedUrl, true);
          xhr.setRequestHeader('x-amz-tagging', fileTags)

          xhr.send(appealFileUploadFile.file);
          xhr.onload = function() {
            if(this.status === 200) {
                dispatch(appealFileUploadedAction({ 
                    learningProviderId: appealFileUploadFile.learningProviderId,
                    appealId: appealFileUploadFile.appealId,
                    fileName: appealFileUploadFile.file.name,
                    status: 'Success'
                }));

                axios
                .post(`${config.API_GATEWAY.URL}/appeal/${appealFileUploadFile.learningProviderId}/appeal/${appealFileUploadFile.appealId}/evidence/${appealFileUploadFile.file.name}`)
                .then(response => {
                    dispatch(getAppealDetailsSuccessAction(mapAppealDetail(response.data, getState().teams, getState().user)))
                })
            }
            else {

                dispatch(appealFileUploadFailedAction({ 
                    learningProviderId: appealFileUploadFile.learningProviderId,
                    appealId: appealFileUploadFile.appealId,
                    fileName: appealFileUploadFile.file.name,
                    status: 'Failed'
                }));
            }
            xhr.onerror = function() {
              dispatch(appealFileUploadFailedAction({ 
                  learningProviderId: appealFileUploadFile.learningProviderId,
                  appealId: appealFileUploadFile.appealId,
                  fileName: appealFileUploadFile.file.name,
                  status: 'Failed'
              }));
            }
          };
      })
      .catch(error => {
        dispatch(appealFileUploadFailedAction({ 
            learningProviderId: appealFileUploadFile.learningProviderId,
            appealId: appealFileUploadFile.appealId,
            fileName: appealFileUploadFile.file.name,
            status: 'Failed'
        }));
      });
    };  
};

export const downloadAppealEvidence = (learningProviderId: string, appealId: string, fileId: string) => {
    axios
      .get(`${config.API_GATEWAY.URL}/appeal/${learningProviderId}/${appealId}/${fileId}/downloadurl`)
      .then(response => {
        const url = response.data;
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
          throw error;
      });
};
const jso = require("jso");
import * as jwt_decode from "jwt-decode";
import axios from "axios";

import { params } from "./auth-params";
import config from "../../app.config";

export default class AuthForgeRock {
  JSO_CLIENT = new jso.JSO(params);

  signin = () => {
    this.JSO_CLIENT.getToken();
  }

  handleAuthentication = () => {
    return new Promise((resolve, reject) => {
      this.JSO_CLIENT.callback();
      this.JSO_CLIENT.getToken().then((token: any) => {
        let decoded = jwt_decode(token.id_token);
        localStorage.setItem("payload", JSON.stringify(decoded));
        return resolve();
      });
    });
  }

  getProfile = () => {
    const profile = localStorage.getItem("payload");
    if (!profile) {
      return new Error("No profile found");
    }
    return JSON.parse(profile);
  }

  isAuthenticated = () => {
    const jsoToken = this.JSO_CLIENT.checkToken();
    if (jsoToken) {
      // Add 8 hours to reach actual 3600 * 8
      let expiresAt = jsoToken.expires + 28800;
      return Math.round(new Date().getTime() / 1000) < expiresAt;
    }
    // redirect to login page if not authenticated, keeping it simple.
    this.signin();

    return false;
  }

  signout = () => {
    let endSessionEndpoint = window.location.origin;
    const jsoToken = this.JSO_CLIENT.checkToken();

    if (jsoToken) {
      this.JSO_CLIENT.wipeTokens();
      localStorage.removeItem("payload");
      endSessionEndpoint = `${config.FORGEROCK.SESSION_END_ENDPOINT}/&goto=${endSessionEndpoint}`;
    }

    if (jsoToken && jsoToken.access_token) {
      axios
        .get(`${config.API_GATEWAY.URL}/user/revoke/${jsoToken.access_token}`)
        .then(() => {
          window.location.replace(endSessionEndpoint);
        });
    }
  }

  clearLocalSession = () => {
    this.JSO_CLIENT.wipeTokens();
    localStorage.removeItem("payload");
  }

  getIdToken = () => {
    const jsoToken = this.JSO_CLIENT.checkToken();
    if (!jsoToken) {
      return new Error("No jso token found");
    }
    return jsoToken.id_token;
  }

  getAccessToken = () => {
    const jsoToken = this.JSO_CLIENT.checkToken();
    if (!jsoToken) {
      return new Error("No jso token found");
    }
    return jsoToken.access_token;
  }
}

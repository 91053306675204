import _ from 'lodash';

interface GenerateAlertProps {
  type: "success" | "error" | "confirmation" | "important";
  level?: "global" | "inline";
  returnNode?: string;
  animated?: boolean;
  focusOnOpen?: boolean;
}

interface DisplayAlertProps extends GenerateAlertProps {
  alertTitle: string;
  alertText: string;
  dismiss?: () => void;
}

export class alert {
    private static displayAlert = ({
      alertTitle,
      alertText,
      type,
      level,
      returnNode,
      animated,
      focusOnOpen,
      dismiss,
    }: DisplayAlertProps) => {
      let props: GenerateAlertProps = {
        type,
        level: level ? level : "global",
        returnNode: "#" + returnNode ? returnNode : "main",
      };
      if (animated) {
        props.animated = true;
      }
      if (focusOnOpen) {
        props.focusOnOpen = true;
      }

      const alert = document.createElement("pearson-alert");
      for (let prop in props) {
        alert.setAttribute(prop, props[prop]);
      }

      alert.innerHTML =
        ` <p id="alertTitle" className="alert-title">   ` +
        `  <strong>${alertTitle}: </strong>                ` +
        ` </p>                                          ` +
        ` <p id="alertText" className="alert-text">${alertText}</p>`;

      if (dismiss) {
        alert.addEventListener("dismiss", () => {
          dismiss!();
        });
      }

      if (props.level === "inline") {
        const node = document.getElementById(props.returnNode!);
        node!.parentNode!.insertBefore(alert, node!.nextSibling);
      } else {
        document.body.appendChild(alert);
      }
    };


  static error = (error: string, dismiss?: () => void) => {
    alert.displayAlert({
      alertTitle: "Error",
      alertText: error.toString().replace(/^(Error: \.)/, ""),
      type: "error",
    });
  };
  
    // static error = (error: Error | string, dismiss?: () => void) => {
    //   const alertText = (error as Error).message;
    //   alert.displayAlert({
    //     alertTitle: "Error",
    //     alertText: alertText ?? error,
    //     type: "error",
    //   });
    // };

    static success = (message: string, dismiss?: () => void) => {
      alert.displayAlert({
        alertTitle: "Success",
        alertText: message,
        type: "success",
      });
    };
  }






import { connect } from "react-redux";
import { ErrorPage, StateProps, OwnProps } from "./error-page";
import { StoreState } from "../../store/store-state";

const mapStateToProps = (state: StoreState , ownProps: OwnProps): StateProps => {
  const correlationId = ownProps.match.params.correlationId;
  return { correlationId };
};

export default connect<StateProps>(
  mapStateToProps
)(ErrorPage);


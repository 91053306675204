interface Config {
  API_GATEWAY: { URL: string };
  FORGEROCK: { AUTHORIZE_ENDPOINT: string, SESSION_END_ENDPOINT: string, REDIRECT_URI: string, SCOPES: string[], CLIENT_ID: string};
  MEDIA_FILES : { MAX_FILE_SIZE: number }  
  APPEAL_EVIDENCE_FILE_EXTENTIONS: string;
  HELP_LINKS: {    
    APPEAL_PROCESS_GUIDANCE: string;
    APPEAL_TYPES_GUIDANCE: string;
    APPEALING_CALCULATED_GRADES: string,
    CONTACT_US: string, 
    TERMS_OF_USE: string, 
    PRIVACY_POLICY: string, 
    GENERAL_HELP_GUIDE: string,
    GRADESET_HELP_GUIDE: string,
    UNAVAILABLE_HELP: string};
}

const dev: Config = {
  API_GATEWAY: {
    URL: "https://gradecapture.api.dev.pearson-nonprod.tk/api"
    //URL: "https://localhost:5001/api"
  },
  FORGEROCK: {
    CLIENT_ID: 'dlwt_oauth',
    SCOPES: ['openid', 'profile', 'email', 'pearsonUID', 'pearsonUserType'],
    REDIRECT_URI: `${window.location.protocol}//${window.location.hostname}:${window.location.port}/callback`,
    AUTHORIZE_ENDPOINT: 'https://iam-stage.pearson.com:443/auth/oauth2/realms/root/realms/pearson/authorize',
    SESSION_END_ENDPOINT: 'https://iam-stage.pearson.com/auth/XUI/?realm=/pearson#logout'
  },
  MEDIA_FILES : {
    MAX_FILE_SIZE: 8192
  },
  APPEAL_EVIDENCE_FILE_EXTENTIONS:"Audio|mp3|wav|wma|aif|aiff|m4a|ogg|oga|mid|midi|Video|mpeg|flv|mov|wmv|mp4|avi|vts|vlc|mpg|m4v|Image|jpeg|gif|jpg|png|tiff|tif|Document|doc|docx|oft|pfd|pdf|ppt|pptx|pub|odt|xls|xlsx|txt|html|htm|epub|css|js|rtf|csv|xml|ppsx|exe|rar|zip",
  HELP_LINKS : {
    APPEAL_PROCESS_GUIDANCE: "https://qualifications.pearson.com/en/campaigns/pearson-covid-19/appealing-calculated-grades.html",
    APPEAL_TYPES_GUIDANCE: "https://qualifications.pearson.com/en/campaigns/pearson-covid-19/appealing-calculated-grades.html",
    APPEALING_CALCULATED_GRADES: 'https://qualifications.pearson.com/en/campaigns/pearson-covid-19/appealing-calculated-grades.html',
    CONTACT_US: 'https://qualifications.pearson.com/en/contact-us.html',
    TERMS_OF_USE: 'https://www.pearson.com/uk/legal-notice.html',
    PRIVACY_POLICY: 'https://www.pearson.com/corporate/privacy-notice.html',
    GENERAL_HELP_GUIDE: 'https://qualifications.pearson.com/en/campaigns/pearson-covid-19/appealing-calculated-grades.html',
    GRADESET_HELP_GUIDE: 'https://qualifications.pearson.com/content/dam/pdf/Support/pearson-june-2020-valid-gradesets.pdf',
    UNAVAILABLE_HELP: 'https://qualifications.pearson.com/en/campaigns/pearson-covid-19/appealing-calculated-grades.html'
  }
};

const prod: Config = {
  API_GATEWAY: {
    URL: '**ENVIRONMENT_SERVER_URL**/api'
  },
  FORGEROCK: {
    CLIENT_ID: 'dlwt_oauth',
    SCOPES: ['openid', 'profile', 'email', 'pearsonUID', 'pearsonUserType'],
    REDIRECT_URI: `${window.location.protocol}//${window.location.hostname}:${window.location.port}/callback`,
    AUTHORIZE_ENDPOINT: '**FORGEROCK_SERVER_URL**/auth/oauth2/realms/root/realms/pearson/authorize',
    SESSION_END_ENDPOINT: '**FORGEROCK_SERVER_URL**/auth/XUI/?realm=/pearson#logout'
  },
  MEDIA_FILES : {
    MAX_FILE_SIZE: 8192
  },
  APPEAL_EVIDENCE_FILE_EXTENTIONS: "Audio|mp3|wav|wma|aif|aiff|m4a|ogg|oga|mid|midi|Video|mpeg|flv|mov|wmv|mp4|avi|vts|vlc|mpg|m4v|Image|jpeg|gif|jpg|png|tiff|tif|Document|doc|docx|oft|pfd|pdf|ppt|pptx|pub|odt|xls|xlsx|txt|html|htm|epub|css|js|rtf|csv|xml|ppsx|exe|rar|zip",
  HELP_LINKS : {
    APPEAL_PROCESS_GUIDANCE: "https://qualifications.pearson.com/en/campaigns/pearson-covid-19/appealing-calculated-grades.html",
    APPEAL_TYPES_GUIDANCE: "https://qualifications.pearson.com/en/campaigns/pearson-covid-19/appealing-calculated-grades.html",
    APPEALING_CALCULATED_GRADES: 'https://qualifications.pearson.com/en/campaigns/pearson-covid-19/appealing-calculated-grades.html',
    CONTACT_US: 'https://qualifications.pearson.com/en/contact-us.html',
    TERMS_OF_USE: 'https://www.pearson.com/uk/legal-notice.html',
    PRIVACY_POLICY: 'https://www.pearson.com/corporate/privacy-notice.html',  
    GENERAL_HELP_GUIDE: 'https://qualifications.pearson.com/en/campaigns/pearson-covid-19/appealing-calculated-grades.html',
    GRADESET_HELP_GUIDE: 'https://qualifications.pearson.com/content/dam/pdf/Support/pearson-june-2020-valid-gradesets.pdf',
    UNAVAILABLE_HELP: 'https://qualifications.pearson.com/en/campaigns/pearson-covid-19/appealing-calculated-grades.html'
  }
};

// @ts-ignore
const config: Config = process.env.NODE_ENV === 'production' ? prod : dev;

export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};

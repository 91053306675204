import { Reducer } from "redux";
import initialState from "./initialState";
import { SET_LEARNING_PROVIDER } from "../actions/action-types";
import { LearningProviderActions } from "../actions/learning-provider.actions";


export const selectedLearningProviderIdReducer: Reducer<string | null> = (
  state: string | null = initialState.selectedLearningProviderId,
  action: LearningProviderActions
) => {
  switch (action.type) {
    case SET_LEARNING_PROVIDER:
      return action.learningProviderId;
    default:
      return state;
  }
}
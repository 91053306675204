import initialState from './initialState';
import { AppealActions } from "../actions/appeal.actions"
import { AppealOutcome } from '../models/appeal-outcome';

export function appealOutcomesReducer(
  state: AppealOutcome[] = initialState.appealOutcomes,
  action: AppealActions 
): AppealOutcome[] {

  switch (action.type) {
    default:
      return state;
  }
};


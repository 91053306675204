import axios from 'axios';
import _ from 'lodash';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { ajaxCallError, beginAjaxCall, AjaxCallActions } from './ajax-status.actions';
import { StoreState } from '../store/store-state';
import config from '../app.config';
import { UploadFile } from '../models/upload-file';
import { GET_UPLOAD_FILE_SUCCESS } from './action-types';
import { UploadFileError } from 'models/upload-file-error';
import { UploadFileErrorLearnerDetail } from 'models/upload-file-error-learner-detail';

interface GetUploadFileSuccessAction extends Action {
  type: GET_UPLOAD_FILE_SUCCESS;
  uploadFile: UploadFile | null;
}

export type getUploadFileSuccessAction =
  | GetUploadFileSuccessAction
  | AjaxCallActions;

export const getUploadFileSuccessAction = (
  uploadFile: UploadFile | null
): GetUploadFileSuccessAction => ({
    uploadFile,
    type: GET_UPLOAD_FILE_SUCCESS
});

export const getUploadFile = (learningProviderId: string, qualificationId: string) => {
  return (dispatch: ThunkDispatch<StoreState, void, getUploadFileSuccessAction>) => {
    dispatch(beginAjaxCall());
    return axios
      .get(`${config.API_GATEWAY.URL}/uploadfile/${learningProviderId}/upload/${qualificationId}`)
      .then(response => {
        dispatch(getUploadFileSuccessAction(
          response.status == 200
            ? getFile(response.data)
            : null
        ))
      })
      .catch(error => {
        dispatch(ajaxCallError(error));
        throw error;
      });
  };
};

export const refreshUploadFile = (learningProviderId: string, qualificationId: string, version: string | undefined) => {
  return (dispatch: ThunkDispatch<StoreState, void, getUploadFileSuccessAction>) => {
    dispatch(beginAjaxCall());
    return axios
      .get(`${config.API_GATEWAY.URL}/uploadfile/${learningProviderId}/upload/${qualificationId}`)
      .then(response => {
        const uploadFile = response.data;

        // Only update upload file if version has changed
        if (uploadFile.versionId !== version) {
          dispatch(getUploadFileSuccessAction(getFile(uploadFile)));
        }     
      })
      .catch(error => {
        dispatch(ajaxCallError(error));
        throw error;
      });
  };
};

const getFileline = (learner: UploadFileErrorLearnerDetail) => {
  return `"${learner.subjectCode ?? ""}|` + 
  `${learner.forenames ?? ""}|${learner.surname ?? ""}|` +
    `${learner.dateOfBirth ?? ""}|` +
    `${learner.learnerIdentifier ?? ""}|` +
    `${learner.grade ?? ""}|${learner.ranking ?? ""}"`;
}

const getSearchString = (error: UploadFileError) => {
  return `Row 
    ${error.csvRowNumber} 
    ${error.errorDescription} 
    ${getFileline(error.csvFileLineLearnerDetails!)}`.toLowerCase();
}

const getFile = (uploadFile: any) => {
  const uf = new UploadFile;
  uf.errorCount = uploadFile.errorCount;
  uf.lastUpdatedDateTime = new Date(uploadFile.lastUpdatedDateTime);
  uf.learningProviderId = uploadFile.learningProviderId;
  uf.numberOfLines = uploadFile.numberOfLines;
  uf.originalFilename = uploadFile.originalFilename;
  uf.pearsonUserId = uploadFile.pearsonUserId;
  uf.processedCount = uploadFile.processedCount;
  uf.qualificationId = uploadFile.qualificationId;
  uf.uploadType = uploadFile.uploadType;
  uf.username = uploadFile.username;
  uf.versionId = uploadFile.versionId;
  uf.warningCount = uploadFile.warningCount;
  uf.uploadFileErrors = _.map(uploadFile.uploadFileErrors, fileError => {
    return {
      ...fileError,
      csvRowNumber: fileError.csvRowNumber ?? "",
      csvRowNumberString: fileError.csvRowNumber ? _.padStart(fileError.csvRowNumber ?? "", 5, '0') : "",
      fileline: getFileline(fileError.csvFileLineLearnerDetails),
      searchString: getSearchString(fileError)
    }
  });
  return uf;
}

export type UploadFileActions =
  GetUploadFileSuccessAction 
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as _ from 'lodash';
import { StoreState } from '../../../store/store-state';
import { AppealState } from '../../../models/appeal-state';
import { Option } from '../../../models/option';
import { Qualification } from '../../../models/qualification';
import { QualificationAppealSortByOptions } from "../../../models/option";
import { QualificationSearchOption } from '../../../types/qualification-search-option';
import { SortDirection } from '../../../types/sort-direction';
import SearchBar from '../../../components/pearson/search-bar';
import Sort from '../../../components/pearson/sort';
import QualificationAppealCard from './qualification-appeal-card';


interface QualificationAppealCardListProps {
  qualifications: Qualification[];
  internal: boolean;
  searchOption: string;
  searchText: string;
  sortBy: string;
  sortDirection: SortDirection; 
  onCreateAppeal: (qualification: Qualification) => void;
  onViewAppeals: (qualification: Qualification) => void;
  onDownloadTemplate: (qualification: Qualification) => void;
  onSearch: (searchOption: QualificationSearchOption, searchText: string) => void;
  onSort: (sortBy: string, sortDirection: string) => void;
  onViewLearners: (qualification: Qualification) => void;
  onViewFileHistory: (qualification: Qualification) => void;
}

export const QualificationAppealCardList = ({
  qualifications,
  internal,
  searchOption,
  searchText,
  sortBy,
  onCreateAppeal,
  onViewAppeals,
  sortDirection,
  onDownloadTemplate,
  onSearch,
  onSort,
  onViewLearners,
  onViewFileHistory
}: QualificationAppealCardListProps) => {
  const [qualificationsFiltered, setQualificationsFiltered] = useState([...qualifications]);

  const appealStates = _.filter(
    useSelector((state: StoreState) => state.appealStates), (appealState: AppealState) => {
      return internal || appealState.internalOnly === false;
    }
  )  
  
  const getOptions = () => {
    const options: Option[] = [
      { name: "All", value: "All" },
      { name: "All appeals", value: "numberOfAppeals" }
    ];
    _.each(appealStates, (appealState: AppealState) => {
      options.push({ name: `Appeals - ${appealState.name}`, value: appealState.propertyName })    
    });
    return options;
  }

  useEffect(() => {     
    const filtered = (searchOption === "All" && searchText === "")
      ? qualifications
      : _.filter(qualifications, (qualification: Qualification) => {
        return (searchOption == "All" || qualification[searchOption] > 0) &&
            qualification.searchString
              .toLowerCase()
              .includes(searchText.toLowerCase());
        });    
    setQualificationsFiltered(
      _.orderBy(filtered, sortBy.split(','), [sortDirection])
    )
  }, [searchOption, searchText, sortBy, sortDirection, qualifications]);

  return (
   <div className="qualification-card-list">
      <div className="search-sort-container">
        <SearchBar
          searchOption={searchOption}
          searchText={searchText}
          options={getOptions()}
          onSearch={(searchOption, searchText) => {
            onSearch(searchOption as QualificationSearchOption, searchText)
          }}
        />
        <Sort
          sortBy={sortBy}
          sortDirection={sortDirection}
          options={QualificationAppealSortByOptions}
          onChange={(sortBy, sortDirection) => {
            onSort(sortBy, sortDirection)
          }}
        />
     </div>
      <div className="qualifications">
        {_.map(qualificationsFiltered, (qualification, index) => (
          <QualificationAppealCard
            key={index}
            qualification={qualification}
            internal={internal}
            onCreateAppeal={onCreateAppeal}
            onViewAppeals={onViewAppeals}
            onDownloadTemplate={onDownloadTemplate}
            onViewLearners={onViewLearners}
            onViewFileHistory={onViewFileHistory}
          />
        ))}
      </div>
   </div>  
  );
};

export default QualificationAppealCardList
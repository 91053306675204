import * as React from "react";
import { FormEvent } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "../../../components/pearson/button";

interface UploadGradesModalProps {
  show: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const UploadGradesModal = ({
  show,
  onCancel,
  onConfirm,
}: UploadGradesModalProps) => {

  const onFormSubmit = (
    event:
      FormEvent<HTMLFormElement> | 
      React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    event.preventDefault();
    onConfirm();
  };

  const onShow = () => {
    // document.getElementById("confirmCheckbox")?.focus();
  };

  return (
    <div className="upload-grades-modal">
      <Modal
        show={show}
        onHide={onCancel}
        onShow={onShow}
        aria-labelledby="Confirmation">
        <Modal.Header closeButton>
          <Modal.Title>Sign off</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={onFormSubmit}>
            <p>Please confirm that a teacher and head of department have reviewed and quality checked the grades and rankings you are about to upload to ensure their accuracy.</p>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            label="Confirm"
            type="submit"
            className="primary"
            onClick={onFormSubmit}
          />
          <Button
            label="Cancel"
            type="submit"
            className=""
            onClick={onCancel}
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UploadGradesModal;
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as _ from 'lodash';
import { StoreState } from '../../../store/store-state';
import { Appeal } from '../../../models/appeal';
import { AppealState } from '../../../models/appeal-state';
import { Option } from '../../../models/option';
import { SortDirection } from '../../../types/sort-direction';
import SearchBar from '../../../components/pearson/search-bar';
import Sort from '../../../components/pearson/sort';
import AppealCard from './appeal-card';

interface AppealCardListProps { 
  internal: boolean;
  appeals: Appeal[];
  showQualification: boolean;
  searchOption: string;
  searchText: string;
  sortBy: string;
  sortDirection: SortDirection;
  onSearch: (searchOption: string, searchText: string) => void;
  onSort: (sortBy: string, sortDirection: string) => void;
  onCancelAppeal: (appeal: Appeal) => void;
  onCompleteAppeal?: (appeal: Appeal) => void;
  onDeleteAppeal?: (appeal: Appeal) => void;
  onEditAppeal?: (appeal: Appeal) => void;
  onViewAppeal: (appeal: Appeal) => void;
}

export const AppealCardList = ({
  internal,
  appeals,
  showQualification,
  searchOption,
  searchText,
  sortBy,
  sortDirection,
  onSearch,
  onSort,
  onCancelAppeal,
  onCompleteAppeal,
  onEditAppeal,
  onViewAppeal
}: AppealCardListProps) => {

  const appealStates = useSelector((state: StoreState) => state.appealStates);
  const [appealsFiltered, setAppealsFiltered] = useState([...appeals]);

  const getOptions = () => {
    const options: Option[] = [
      { name: "All", value: "All" },
    ];
    _.each(appealStates, (appealState: AppealState) => {
      if (internal || appealState.internalOnly === false) {
        options.push({ name: appealState.name, value: appealState.id.toString() })
      }
    });
    return options;
  }

  useEffect(() => {
    const filtered = (searchOption === "All" && searchText === "")
      ? appeals
      : _.filter(appeals, (appeal: Appeal) => {
        return (searchOption == "All" || appeal.statusId.toString() === searchOption) &&
          appeal.searchString
            .toLowerCase()
            .includes(searchText.toLowerCase());
      });
    setAppealsFiltered(
      _.orderBy(filtered, sortBy.split(','), [sortDirection])
    )
  }, [searchOption, searchText, sortBy, sortDirection, appeals]);

  return (
    <div className="appeal-card-list">
      <div className="search-sort-container">
        <SearchBar
          searchOption={searchOption}
          searchText={searchText}
          options={getOptions()}
          onSearch={(option, text) => onSearch(option, text)}
        />
        <Sort
          sortBy={sortBy}
          sortDirection={sortDirection}
          options={[
            { name: "Qualification name", value: "qualificationDisplayName" },
            { name: "Appeal service", value: "appealTypeId,statusId" },
            { name: "Number of learners", value: "learnerCountString,appealTypeId" },
            { name: "Status", value: "statusId,appealTypeId" }
          ]}
          onChange={(sortBy, sortDirection) => onSort(sortBy, sortDirection)}
        />
      </div>
      <div className="appeals">
        {_.map(appealsFiltered, (appeal, index) => (
          <AppealCard
            key={index}
            appeal={appeal}
            internal={internal}
            showQualification={showQualification}
            onCancelAppeal={onCancelAppeal}
            onCompleteAppeal={onCompleteAppeal}
            onEditAppeal={onEditAppeal}
            onViewAppeal={onViewAppeal}
          />
        ))}
      </div>
    </div>
  );
};

export default AppealCardList
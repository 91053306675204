import initialState from './initialState';
import { CsvActions } from "../actions/csv.actions";
import { CsvUploadStatus } from "models/csv-upload-status";
import { CSV_UPLOAD_IN_PROGRESS, CSV_UPLOADED, CSV_UPLOAD_FAILED, CSV_UPLOAD_INCORRECT_FILE_TYPE } from "../actions/action-types";

export function csvUploadReducer(
  state: CsvUploadStatus | null = initialState.csvUploadStatus,
  action: CsvActions
): CsvUploadStatus | null {
  switch (action.type) {
    case CSV_UPLOAD_IN_PROGRESS:
    case CSV_UPLOADED:
    case CSV_UPLOAD_FAILED:
    case CSV_UPLOAD_INCORRECT_FILE_TYPE:
      return { ...action.csvUploadStatus }
    default:
      return state;
  }
};


import * as types from "../actions/action-types";
import { AjaxCallActions } from "../actions/ajax-status.actions";
import initialState from "./initialState";

function actionTypeEndsInSuccess(type: string) {
  return type.substring(type.length - 8) === "_SUCCESS";
}

export function ajaxStatusReducer(
  state: number = initialState.ajaxCallsInProgress,
  action: AjaxCallActions
): number {
  const type = action.type.toString();

  if (action.type === types.BEGIN_AJAX_CALL) {
    return state + 1;
  } else if (
    action.type === types.AJAX_CALL_ERROR ||
    actionTypeEndsInSuccess(type)
  ) {
    return state - 1;
  }
  return state;
}

export default ajaxStatusReducer;

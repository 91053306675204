export class AppealLearner {
  allowAppeal: Boolean;
  dateOfBirth: Date | null;
  forenames: string;  
  isInAppeal: Boolean;
  learnerIdentifierName: string;
  learnerIdentifier: string;  
  searchString: string;
  surname: string;
  appeals: [{
    appealId: string;
    appealTypeId: number;
  }];
}

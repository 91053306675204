import * as React from "react";
import { FormEvent } from "react";
import * as _ from 'lodash';
import Modal from "react-bootstrap/Modal";
import { AppealTemplate } from "../../../../models/appeal-template";
import Button from "../../../../components/pearson/button";
import TextArea from "../../../../components/pearson/text-area";
import AppealOutcomeSelect from "../selects/appeal-outcome-select";

interface CloseAppealModalProps {
  appealTemplates: AppealTemplate[];
  appealTypeId: number;
  show: boolean;
  onCancel: () => void;
  onSave: (outcomeId: number, letterText: string) => void;
}

const CloseAppealModal = ({
  appealTemplates,
  appealTypeId,
  show,
  onCancel,
  onSave,
}: CloseAppealModalProps) => {

  const [appealTemplate, setAppealTemplate] = React.useState<AppealTemplate | undefined>(undefined);
  const [letterText, setLetterText] = React.useState("");
  const [outcomeId, setOutcomeId] = React.useState<number|null>(null);


  const onFormSubmit = (
    event:
      FormEvent<HTMLFormElement> |
      React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    event.preventDefault();
    if (outcomeId) {
      onSave(outcomeId, letterText); 
    }
  };
  
  const onOutcomeIdChange = (outcomeId: number) => {
    setOutcomeId(outcomeId);   
    setLetterText(""); 
    setAppealTemplate(
      _.find(appealTemplates, { appealTypeId: appealTypeId, "outcomeId": outcomeId })
    );
  }

  return (
    <div className="close-appeal-modal">
      <Modal
        show={show}
        onHide={onCancel}
        aria-labelledby="Close appeal (pending outcome letter)">
        <Modal.Header closeButton>
          <Modal.Title>Close appeal (pending outcome letter)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="close-appeal-form" onSubmit={onFormSubmit}>
            <div className="outcome">
              <p>Select the appeal outcome from the list below.</p>
              <AppealOutcomeSelect
                outcomeId={outcomeId}
                onChange={onOutcomeIdChange}
              />  
            </div>              
            {appealTemplate && appealTemplate.requiresReason && (
              <div className="letter-text">             
                <p>Add outcome letter text.</p>
                <TextArea
                  label={appealTemplate.reasonTemplate}
                  placeholder="Insert text here..."
                  value={letterText}
                  onChange={(e) => setLetterText(e.currentTarget.value)}
                />
              </div>
            )}             
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            label="Close appeal"
            type="submit"
            disabled={
              outcomeId === null || 
              (appealTemplate && appealTemplate.requiresReason && letterText.length === 0)
            }
            className="primary"
            onClick={onFormSubmit}
          />         
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CloseAppealModal;
import * as React from 'react';
import { useEffect, useState } from 'react';
import * as _ from 'lodash';
import { SortDirection } from '../../../types/sort-direction';
import { LearnerGrade } from '../../../models/learner-grade';
import { Option, LearnerGradeSearchOptions, EditLearnerGradeSearchOptions } from "../../../models/option";
import Sort from '../../../components/pearson/sort';
import LearnerGradeCard from './learner-grade-card';
import SearchBar from '../../../components/pearson/search-bar';
import { LearnerGradeSearchOption } from '../../../types/learner-grade-search-option';

interface LearnerGradeCardListProps {
  id: string;
  className: string;
  title: string;
  displaySearch: boolean;
  displaySort: boolean;
  learners: LearnerGrade[];
  gradable: boolean;
  rankable: boolean;
  readOnly: boolean;
  searchOption: string;
  searchText: string;
  sortBy: string;
  sortDirection: SortDirection;
  onEdit?: (learner: LearnerGrade) => void;
  onSearch?: (searchOption: LearnerGradeSearchOption, searchText: string) => void;
  onSort?: (learnerListId: string, sortBy: string, sortDirection: SortDirection) => void;
}

export const LearnerGradeCardList = ({
  id,
  className,
  title,
  displaySearch,
  displaySort,
  learners,
  gradable,
  rankable,
  readOnly,
  searchOption,
  searchText,
  sortBy,
  sortDirection,
  onEdit,
  onSearch,
  onSort
}: LearnerGradeCardListProps) => {
  const learnerIdentifierName = learners[0].learnerIdentifierName;
  const [filteredLearnerGrades, setFilteredLearnerGrades] = useState([...learners]);  

  const sortOptions: Option[] = [
    { name: "Forenames", value: "forenames" },
    { name: "Surname", value: "surname" },
    { name: "DOB", value: "dateOfBirth" },
    { name: learnerIdentifierName, value: "learnerIdentifier" },
    {
      name: `${gradable ? "Grade" : ""}${gradable && rankable ? " and ranking" : ""}${rankable && !gradable ? "Ranking" : ""}`,        
      value: `${gradable ? "gradeIndex" : ""}${gradable && rankable ? "," : ""}${rankable ? "rankingString" : ""}`
    }
  ]; 

  useEffect(() => {
    const filteredLearnerGrades = _.filter(learners, (learner: LearnerGrade) => {
      return (searchOption === "All" ||
        ((searchOption === "LearnerGradesNotProcessed" && learner.isNotProcessed) ||
        (searchOption === "LearnerGradesProcessed" && learner.isProcessed) ||
        (searchOption === "LearnerGradesWithWarnings" && learner.hasWarnings))) &&
        (searchText === "" || learner.searchString.toLowerCase().includes(searchText.toLowerCase())); // ||
    });
    setFilteredLearnerGrades(
      sortBy === "gradeIndex,rankingString"
        ? _.orderBy(filteredLearnerGrades, sortBy.split(','), [sortDirection === "asc" ? "desc" : "asc", "asc"])
        : sortBy === "gradeIndex" 
          ? _.orderBy(filteredLearnerGrades, sortBy.split(','), [sortDirection === "asc" ? "desc" : "asc"])
          : _.orderBy(filteredLearnerGrades, sortBy.split(','), [sortDirection])
    )    
  }, [searchOption, searchText, sortBy, sortDirection, learners]);

  return (
    <div id={id} className={`learner-grade-card-list ${className}`}>
      <span className="search-sort-container">
        {filteredLearnerGrades.length > 0 && title !== "" && (
          <h3>{title}</h3>
        )}           
        {displaySearch && (
          <SearchBar
            options={readOnly ? LearnerGradeSearchOptions : EditLearnerGradeSearchOptions}
            searchOption={searchOption}
            searchText={searchText}
            onSearch={(searchOption, searchText) => onSearch!(searchOption as LearnerGradeSearchOption, searchText)}
          />
        )}
        {displaySort && filteredLearnerGrades.length > 1 && (
          <Sort
            id={id}
            sortBy={sortBy}
            sortDirection={sortDirection}
            options={sortOptions}
            onChange={(sortBy, sortDirection) => {
              if (onSort) {
                onSort(id, sortBy, sortDirection as SortDirection)
              }                  
            }}
          />
        )}           
      </span>
      {_.map(filteredLearnerGrades, (learner: LearnerGrade) => (
          <LearnerGradeCard
            key={learner.learnerIdentifier}
            learner={learner}
            gradable={gradable}
            rankable={rankable}
            readOnly={readOnly}
            onEdit={onEdit}
          />
      ))}
    </div>
  );
};

LearnerGradeCardList.defaultProps = {
  id: "learnerGradeCardList",
  className: "",
  title: "",
  displaySearch: true,
  displaySort: true,
  readOnly: true
}

export default LearnerGradeCardList
import * as React from 'react';
import { AlertMessage } from "../../../components/pearson/alert-message";
import { Qualification } from '../../../models/qualification';
import { LearnerGradeActionState } from "../../../types/learner-grade-action-state";

interface LearnerGradeActionStatusProps {
  qualification: Qualification;
  actionState: LearnerGradeActionState ;
  filename: string;
}

export const LearnerGradeActionStatus = ({
  qualification,
  actionState,
  filename
}: LearnerGradeActionStatusProps) => {
  
  return (
    <div className="action-status">
      {actionState === "InProgress" && (
        <AlertMessage 
          type="loading" 
          message="Upload in progress..." 
        />
      )}
      {actionState === "Success" && (
        <AlertMessage
          type="loading"
          message={filename === ""
            ? "The changes have been uploaded and will be processed shortly..."
            : "The template has been uploaded and will be processed shortly..."}
        />
      )}
      {actionState === "IncorrectFileType" && (
        <AlertMessage
          type="error"
          message="Only csv files are permitted for upload."
        />
      )}
      {actionState === "Failed" && (
        <AlertMessage 
          type="error" 
          message={filename === ""
            ? "Upload failed"
            : "Upload failed for " + filename} 
        />
      )}

      {actionState === "Processed" && 
        (qualification.statusCode === "PartiallyProcessed" || 
         qualification.statusCode === "Rejected") && (
          <AlertMessage
            type="error"
          message={filename === ""
          ? `${qualification.status} - Please correct the errors below.`
          : `${qualification.status} - Please correct the errors below and then upload the file again.`}
          />
        )
      }   
      {actionState === "Processed" && qualification.statusCode === "ProcessingError" && (
        <AlertMessage
          type="error"
          message={filename === ""
            ? `${qualification.status} - The changes could not be processed, please see below for details.`
            : `${qualification.status} - The file could not be processed, please see below for details.`}
        />
      )}      
      {actionState === "Processed" && qualification.statusCode === "Processed" && (
        <AlertMessage
          type="success"
          message={filename === "" 
            ? `${qualification.status} - The changes have been successfully processed.`
            : `${qualification.status} - The file has been successfully processed.`}
        />
      )}
    </div>
  );
};

export default LearnerGradeActionStatus;
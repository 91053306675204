import * as React from 'react';
import { createRef, RefObject, ChangeEvent, useState } from "react";
import Dropdown, { DropdownOption } from '../../../components/pearson/dropdown';
import UploadGradesModal from './upload-grades-modal';

interface LearnerGradeActionDropdownProps {
  readOnly: boolean;
  internal: boolean;
  showAppeals: boolean;
  onDownloadTemplate: () => void;
  onEditLearnerGrades: () => void;
  onUploadTemplate: (file: File) => void;
  onViewFileHistory: () => void;
}

export const LearnerGradeActionDropdown = ({
  readOnly,
  internal,
  showAppeals,
  onDownloadTemplate,
  onEditLearnerGrades,
  onUploadTemplate,
  onViewFileHistory
}: LearnerGradeActionDropdownProps) => {
  const [showModal, setShowModal] = useState(false);
  const fileUploadRef = createRef() as RefObject<HTMLInputElement>;

  const cancelModal = () => {
    setShowModal(false);
  };

  const getOptions = () => {
    const options: DropdownOption[] = [
      { name: "Download template", onClick: onDownloadTemplate },
    ];
    if (!showAppeals && !readOnly) {
      if (internal) {
        options.push({ name: "Edit learner grades", onClick: onEditLearnerGrades });
      }
      options.push({ name: "Upload template", onClick: () => { setShowModal(true) } });
    }
    if (internal) {
      options.push({ name: "View file history", onClick: onViewFileHistory });
    }
    return options;
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault;
    const fileInput = (event.target as HTMLInputElement);
    if (fileInput.files) {
      onUploadTemplate(fileInput.files[0]);
      fileInput.value = "";
    }
  };

  const selectFileToUpload = () => {
    setShowModal(false);
    fileUploadRef.current?.click();
  };

  return (
    <div className="action-button">
      <Dropdown
        buttonText="Actions"
        type="primary"
        options={getOptions()}
      />
      <input
        ref={fileUploadRef}
        id="file"
        type="file"
        style={{ display: "none" }}
        onChange={onChange}
      />
      <UploadGradesModal
        show={showModal}
        onCancel={cancelModal}
        onConfirm={selectFileToUpload}
      />
    </div>
  );
};

export default LearnerGradeActionDropdown;
import * as React from 'react';
import { useEffect, useState } from 'react';
import FileCard from './file-card';
import * as _ from 'lodash';
import { UploadFileHistory } from '../../../models/upload-file-history';
import { FileHistorySearchOption } from '../../../types/file-history-search-option';
import { FileHistorySearchOptions, FileHistorySortByOptions } from '../../../models/option';
import { SortDirection } from '../../../types/sort-direction';
import SearchBar from "../../../components/pearson/search-bar";
import Sort from '../../../components/pearson/sort';

interface FileListProps {
  uploadFileHistories: UploadFileHistory[]; 
  searchOption: string;
  searchText: string;
  sortBy: string;
  sortDirection: SortDirection; 
  onSearch: (searchOption: FileHistorySearchOption, searchText: string) => void;
  onSort: (sortBy: string, sortDirection: string) => void;
  onDownload: (versionId: string, fileName: string) => void;
}

export const FileCardList = ({
  uploadFileHistories,
  searchOption,
  searchText,
  sortBy,
  sortDirection,
  onSearch,
  onSort,
  onDownload
}: FileListProps) => { 

  const [uploadFileHistoriesFiltered, setUploadFileHistoriesFiltered] = useState(uploadFileHistories);  
    
  useEffect(() => {    
    const filtered = (searchOption === "All" && searchText === "")
      ? uploadFileHistories
      : _.filter(uploadFileHistories, (uploadFileHistory: UploadFileHistory) => {
        return (searchOption == "All" || uploadFileHistory.statusCode === searchOption) &&
        uploadFileHistory.searchString
              .toLowerCase()
              .includes(searchText.toLowerCase());
        });    
    setUploadFileHistoriesFiltered(
      _.orderBy(filtered, sortBy.split(','), [sortDirection])
    )
  }, [searchOption, searchText, sortBy, sortDirection, uploadFileHistories]);

  return (
    <div className="file-card-list">
      <div className="search-sort-container">
        <SearchBar
          searchOption={searchOption}
          searchText={searchText}
          options={FileHistorySearchOptions}
          onSearch={(searchOption, searchText) => {
            onSearch(searchOption as FileHistorySearchOption, searchText)
          }}
        />
        <Sort
          sortBy={sortBy}
          sortDirection={sortDirection}
          options={FileHistorySortByOptions}
          onChange={(sortBy, sortDirection) => {
            onSort(sortBy, sortDirection)
          }}
        />
      </div>
      <div className="files">
        {_.map(uploadFileHistoriesFiltered, (uploadFileHistory, index) => (
            <FileCard
              key={index}
              uploadFileHistory={uploadFileHistory}
              onDownload={onDownload}
            />
          )
        )}        
      </div>
    </div>
  );
};

export default FileCardList
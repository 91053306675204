import { connect } from "react-redux";
import { Home, StateProps } from "./home-page";
import { StoreState } from "store/store-state";


const mapStateToProps = (state: StoreState): StateProps => {
  const isAuthenticated = state.auth.authenticated;
  const user = state.user;
  const learningProviderId = state.selectedLearningProviderId;
  
  return { 
    isAuthenticated, 
    user, 
    learningProviderId 
  };
};

export default connect<StateProps>(
  mapStateToProps
)(Home);


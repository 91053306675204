import * as React from "react";
import Checkbox from "../../../components/pearson/checkbox";
import config from "../../../app.config";

interface TermsAndConditionsProps {
  hoC: boolean | undefined;
  learnerConsent: boolean | undefined;
  tandC: boolean | undefined;
  ucasLearners: boolean | undefined;
  onChange: (
    hoC: boolean | undefined, 
    learnerConsent: boolean | undefined, 
    tandC: boolean | undefined, 
    ucasLearners: boolean | undefined) => void;
}

export const TermsAndConditions = ({
  hoC,
  learnerConsent,
  tandC,
  ucasLearners,
  onChange
}: TermsAndConditionsProps) => {

  return (
    <div className="terms-and-conditions">
      <p className="gr-semi-bold">Please confirm, where appropriate:</p>
      <ul>
        <li>
          <Checkbox
            id={"ucasLearners"}
            label="One or more candidates on whose behalf this appeal has been submitted require confirmation of their final grade for UCAS/ progression purposes. (If relevant, Tick to confirm)"
            checked={ucasLearners}
            onChange={(e) =>
              onChange(hoC, learnerConsent, tandC, e.currentTarget.checked)
            }
          />
        </li>
      </ul>
      <p className="gr-semi-bold">Please confirm (mandatory for all applications)</p>
      <ul>
        <li>
          <Checkbox
            id={"tandC"}
            label="The information provided to support this application to appeal is accurate, genuine and complete. (Tick to confirm)"
            checked={tandC}
            onChange={(e) =>
              onChange(hoC, learnerConsent, e.currentTarget.checked, ucasLearners)
            }
          />
        </li>
        <li>
          <Checkbox
            id={"hoC"}
            label={
              <div>
                <span>The Head of Centre has authorised this application to appeal, including any related costs. Appeal fee information is available on our </span>
                <a href={config.HELP_LINKS.APPEALING_CALCULATED_GRADES} target="_blank">website</a>
                <span>. (Tick to confirm)</span>
              </div>
            }
            checked={hoC}
            onChange={(e) =>
              onChange(e.currentTarget.checked, learnerConsent, tandC, ucasLearners)
            }
          />
         </li>
        <li>
          <Checkbox
            id={"learnerConsent"}
            label="I understand for GCSE, AS, A levels, Extended Project Qualification and the Advanced Extension Award in maths that no learner’s grade will be lowered as a result of an appeal. For other qualifications, where this grade protection does not apply, I have obtained the learner's written consent to the appeal. (Tick to confirm)"
            checked={learnerConsent}
            onChange={(e) =>
              onChange(hoC, e.currentTarget.checked, tandC, ucasLearners)
            }
          />
        </li>
      </ul>
    </div>
  )
};

export default TermsAndConditions;

import React, { useState } from "react";
import _ from "lodash";
import { LearnerGrade } from "../../../models/learner-grade";
import LearnerGradesCardList from "../components/learner-grade-card-list";
import { LearnerGradeSearchOption } from "../../../types/learner-grade-search-option";
import { SortDirection } from "../../../types/sort-direction";
interface EditLearnerGradesProps {
  gradable: boolean;
  rankable: boolean;
  learners: LearnerGrade[];
  onEdit: (learner: LearnerGrade) => void;
}

export const EditLearnerGrades = ({
  gradable,
  rankable,
  learners,
  onEdit
}: EditLearnerGradesProps) => {
  const [searchOption, setSearchOption] = useState("All" as LearnerGradeSearchOption);
  const [searchText, setSearchText] = useState("");
  const [sortBy, setSortBy] = useState("forenames");
  const [sortDirection, setSortDirection] = useState("asc" as SortDirection);

  const onSearch = (searchOption: LearnerGradeSearchOption, searchText: string) => {
    setSearchOption(searchOption);
    setSearchText(searchText);
  }

  const onSort = (listId: string, sortBy: string, sortDirection: SortDirection) => {
    setSortBy(sortBy);
    setSortDirection(sortDirection);
  }

  return (
    <div className="edit-learner-grades">
      <LearnerGradesCardList
        gradable={gradable}
        rankable={rankable}
        readOnly={false}
        learners={learners}
        searchOption={searchOption}
        searchText={searchText}
        sortBy={sortBy}
        sortDirection={sortDirection}
        onEdit={onEdit}
        onSearch={onSearch}
        onSort={onSort}
      />
    </div>
  )
};

export default EditLearnerGrades;
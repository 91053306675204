export class Appeal {
  appealId: string;
  appealTypeId: number;
  hoC: boolean;
  learnerConsent: boolean;
  learnerCount: number;
  learnerCountString: string;
  learnerIdentifiers: string[] = []
  learningProviderCode: string;
  learningProviderId: string;
  learningProviderName: string;
  qualificationCode: string;
  qualificationDisplayName: string;
  qualificationGroupId: string;
  qualificationId: string; 
  qualificationName: string;   
  rationale: string = "";
  searchString: string;
  statusId: number;  
  tandC: boolean;  
  ucasLearners: boolean;
}
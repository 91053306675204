export class TeamAppeal {
  appealId: string;
  appealTypeId: number;
  appealTypeName: string;
  assignedPearsonUserId: string;
  assignedUserName: string;
  lastUpdatedDateTime: Date;
  learnerCount: number;
  learningProviderCode: string;
  learningProviderId: string;
  learningProviderName: string;
  statusId: number;  
  submittedDateTime: Date;
  teamId: number | null;
  teamName: string;
  ucasLearners: boolean;
  qualificationGroupId: string;
}
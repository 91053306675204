import * as React from "react";
import { Link } from "react-router-dom";
import * as _ from "lodash";
import moment from "moment";
import config from "../../../app.config";
import { LearnerGradePageMode } from "../../../types/learner-grade-page-mode";
import { CsvUploadStatus } from "../../../models/csv-upload-status";
import { Qualification } from "../../../models/qualification";
import { UploadFile } from "../../../models/upload-file";
import LearnerGradeActionDropdown from "./learner-grade-action-dropdown";
import AlertMessage from "../../../components/pearson/alert-message";
import {LearnerGradeActionState} from "../../../types/learner-grade-action-state";
import LearnerGradeActionStatus from "./learner-grade-action-status";
import Button from "../../../components/pearson/button";

interface LearnerGradeHeaderProps {
  loading: boolean;
  pageMode: LearnerGradePageMode;
  actionState: LearnerGradeActionState;
  internal: boolean;
  showAppeals: boolean;
  qualification: Qualification,
  uploadFile: UploadFile | null,
  csvUploadStatus: CsvUploadStatus | null,
  notProcessedCount: number,
  disableSaveButton: boolean,
  onCancel: () => void;
  onDownloadTemplate: () => void;
  onEditLearnerGrades: () => void;
  onSave: () => void;
  onUploadTemplate: (file: File) => void;
  onViewFileHistory: () => void;
}
export const LearnerGradeHeader = ({
  loading,
  pageMode,
  actionState,
  internal,
  showAppeals,
  qualification,
  uploadFile, 
  csvUploadStatus,
  notProcessedCount,
  disableSaveButton,
  onCancel,
  onDownloadTemplate,
  onEditLearnerGrades,
  onSave,
  onUploadTemplate,
  onViewFileHistory
}: LearnerGradeHeaderProps) => {
  return (
    <div className="header jumbotron">
      <Link
        className="back-link"
        to={`/qualifications/${qualification.qualificationGroupId}`}>
        &lt;&lt; Back to {qualification.qualificationGroupId}
      </Link>
      <h1>{`${
        qualification.qualificationGroupId} 
        ${qualification.name} 
        ${qualification.code}`}
      </h1>
      {qualification.readOnly && (
        <AlertMessage
          type="information"
          message="The deadline for submission has passed and your centre assessed grades and/or rankings are now locked. You can still view your submitted grades and ranks and download the template containing these details for your records."
        />
      )} 
      {!loading && uploadFile && (
        <>
          <div className="summary">
            <div className="status">
              <dl>
                <dt>Status:</dt>
                <dd className={"semi-bold " + qualification.status}>{status.toUpperCase()}</dd>
              </dl>
              {uploadFile.errorCount > 0 && (
                <dl>
                  <dt>Errors in file:</dt>
                  <dd>{uploadFile.errorCount}</dd>
                </dl>
              )}
              {uploadFile.warningCount > 0 && (
                <dl>
                  <dt>Learners with warnings:</dt>
                  <dd>{uploadFile.warningCount}</dd>
                </dl>
              )}
              {notProcessedCount > 0 && (
                <dl>
                  <dt>Learners not processed:</dt>
                  <dd>{notProcessedCount}</dd>
                </dl>
              )}
              {uploadFile.processedCount > 0 && (
                <dl>
                  <dt>Learners processed:</dt>
                  <dd>{uploadFile.processedCount}</dd>
                </dl>
              )}
            </div>
            {uploadFile.lastUpdatedDateTime && (
              <div className="status">
                <dl>
                  <dt>Last uploaded file:</dt>
                  <dd>{moment(uploadFile.lastUpdatedDateTime).format("DD/MM/YYYY HH:mm")}</dd>
                </dl>
                <dl>
                  <dt>Uploaded by:</dt>
                  <dd>{uploadFile.username}</dd>
                </dl>
                <dl>
                  <dt>Rows in file:</dt>
                  <dd>{uploadFile.numberOfLines}</dd>
                </dl>
                <dl>
                  <dt>Upload type:</dt>
                  <dd>{uploadFile.uploadType}</dd>
                </dl>
              </div>
            )}
          </div>
          <p className="important">
            <strong>IMPORTANT:</strong>&nbsp;&nbsp;Please check our&nbsp;
            <a href={config.HELP_LINKS.GRADESET_HELP_GUIDE} target="_blank">guide to valid grades</a>
              {!qualification.readOnly && (<>&nbsp; before submitting files</>)}.
          </p>

          <div className="actions">         
            {pageMode !== "Edit" && (
              <>
                <LearnerGradeActionDropdown
                  readOnly={qualification.readOnly}
                  internal={internal}
                  showAppeals={showAppeals}
                  onDownloadTemplate={onDownloadTemplate}
                  onEditLearnerGrades={onEditLearnerGrades}
                  onUploadTemplate={onUploadTemplate}
                  onViewFileHistory={onViewFileHistory}
                />
                {actionState && csvUploadStatus && (
                  <LearnerGradeActionStatus
                    qualification={qualification}
                    actionState={actionState}
                    filename={csvUploadStatus.fileName}
                  />
                )}
              </>
            )}
            {pageMode === "Edit" && (
              <div className="edit-buttons">
                <Button
                  label="Save"
                  type="submit"
                  className="primary"
                  disabled={disableSaveButton}
                  onClick={onSave}
                />
                <Button
                  label="Cancel"
                  type="reset"
                  onClick={onCancel}
                />
              </div>
            )}
          </div>
        </>
      )}   
    </div>        
  );
};

export default LearnerGradeHeader;

import * as React from "react";
import * as _ from "lodash";
import moment from "moment";
import { AppealLearner } from "../../../models/appeal-learner";
import Checkbox from "../../../components/pearson/checkbox";
import Icon from "../../../components/pearson/icon";

interface AppealLearnerCardProps {
  appealTypeId: number;
  learner: AppealLearner;
  readOnly: boolean;
  selected: boolean;
  onSelect: (learnerIdentifier: string, selected: boolean) => void;
}
export const AppealLearnerCard = ({
  appealTypeId,
  learner,
  readOnly,
  selected,
  onSelect
}: AppealLearnerCardProps) => {

  return (
    <div className="appeal-learner-card">
      <div className={`${readOnly ? "" : "catalog "} card-container`}>
        <div className="card">
          <div className={`info ${readOnly ? "readonly" : ""}`}>
            {!readOnly && (
              <div className="icons">
                {learner.allowAppeal && (
                  <Checkbox
                    id={`checkbox${learner.learnerIdentifier}`}
                    checked={selected}
                    onChange={(e) => {
                      onSelect(learner.learnerIdentifier, e.currentTarget.checked);
                    }}
                  />
                )}
                {!learner.allowAppeal && (
                  <span data-tooltip={`Appeal service type ${appealTypeId} already exists for this learner.\r\n Click on ‘All appeals’ above or ‘View existing appeals’ for this subject from the subject list page to view the relevant appeal.`}>
                    <Icon icon="warning" size="24" fill="warning" />
                  </span>
                )}
              </div>
            )}        
            <span className="name" aria-label="Learner name">
              <strong>{`${learner.forenames} ${learner.surname}`}</strong>
            </span>
            <dl className="dob">
              <dt>DOB:</dt>
              <dd>
                {learner.dateOfBirth
                  ? moment(learner.dateOfBirth).format("DD/MM/YYYY")
                  : ""}
              </dd>
            </dl>
            <dl className="id">
              <dt>{learner.learnerIdentifierName ?? "Learner identifier"}:</dt>
              <dd>{learner.learnerIdentifier}</dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppealLearnerCard;
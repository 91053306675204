import * as React from "react";
import * as _ from "lodash";
import moment from "moment";
import { LearnerGrade } from "../../../models/learner-grade";
import { UploadFileError } from "../../../models/upload-file-error";
import SearchCard from "../../../components/pearson/search-card";
import RankingInput from "./ranking-input";
import GradeSelect from "./grade-select";

interface LearnerGradeCardProps {
  learner: LearnerGrade; 
  gradable: boolean;
  rankable: boolean;
  readOnly: boolean;
  onEdit?: (learner: LearnerGrade) => void;
}
export const LearnerGradeCard = ({ 
  learner, 
  gradable,
  rankable,
  readOnly,
  onEdit
}: LearnerGradeCardProps) => {  
  
  return (
    <div className={`learner-grade-card${gradable ? "-gradable" : ""}`}>
      <SearchCard
        id={learner.learnerIdentifier}
        headerElement={
          <>
            <span className={`name ${learner.hasWarnings ? "warning" : ""}`} aria-label="Learner name">
              <strong>{`${learner.forenames} ${learner.surname}`}</strong>
            </span>
            <dl className="dob">
              <dt>DOB:</dt>
              <dd>
                {learner.dateOfBirth
                  ? moment(learner.dateOfBirth).format("DD/MM/YYYY")
                  : ""}
              </dd>
            </dl>
            <dl className="id">
              <dt>{learner.learnerIdentifierName ?? "Learner identifier"}:</dt>
              <dd>{learner.learnerIdentifier}</dd>
            </dl>
            {gradable && (
              <dl className="grade">
                <dt>Grade:</dt>
                <dd>{
                  readOnly
                    ? learner.grade
                    : <GradeSelect
                        learner={learner}
                        onChange={(learnerGrade) => onEdit!(learnerGrade)}
                      />
                    }
                </dd>
              </dl>
            )}            
            {rankable && (
              <dl className="ranking">
                <dt>Ranking:</dt>
                <dd>{
                  readOnly
                    ? learner.ranking
                    : <RankingInput
                      learner={learner}
                      onChange={(learner) => onEdit!(learner)}
                    />
                  }
                </dd>
              </dl>
            )}            
            {readOnly && learner.hasWarnings && (
              <dl className="warning-details warning">
                <dt className="warning">Warnings:</dt>
                <dd>
                  <ol>
                    {_.map(learner.warnings, (warning: UploadFileError, index) => (
                        <li key={index}>{warning.errorDescription}</li>
                      ))}
                  </ol>
                </dd>
              </dl>
            )}
          </>
        }
        warningMessage={
          learner.hasWarnings && learner.warnings
            ? learner.warnings.length === 1
              ? learner.warnings[0].errorDescription
              : `There are ${learner.warnings.length} warnings for this learner`
            : null
        }
        successMessage={
          learner.isProcessed ? "Learner has been processed" : null
        }
      />
    </div>
  );
};

LearnerGradeCard.defaultProps = {
  readOnly: true
}

export default LearnerGradeCard;

import * as React from "react";
import { FormEvent } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "../../../../components/pearson/button";
import AppealTypeSelect from "../selects/appeal-type-select";
import { AppealType } from "../../../../models/appeal-type";

interface SwitchAppealTypeModalProps {
  show: boolean;
  appealTypes: AppealType[];
  onCancel: () => void;
  onSwitch: (appealTypeId: number) => void;
}

const SwitchAppealTypeModal = ({
  show,
  appealTypes,
  onCancel,
  onSwitch,
}: SwitchAppealTypeModalProps) => {

  const [appealTypeId, setAppealTypeId] = React.useState<number>(0);

  const onFormSubmit = (
    event:
      FormEvent<HTMLFormElement> |
      React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    event.preventDefault();
    if(appealTypeId) {
        onSwitch(appealTypeId);
    }   
  };

  return (
    <div className="switch-appeal-type-modal">
      <Modal
        show={show}
        onHide={onCancel}
        aria-labelledby="Switch appeal service type">
        <Modal.Header closeButton>
          <Modal.Title>Select appeal service type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={onFormSubmit}>
            <AppealTypeSelect
              appealTypeId={appealTypeId}
              appealTypes={appealTypes}
              includeAllOption={false}
              onChange={(appealTypeId) => setAppealTypeId(appealTypeId)}
            />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            label="Switch"
            type="submit"
            disabled={!appealTypeId || appealTypeId === 0}
            className="primary"
            onClick={onFormSubmit}
          />         
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SwitchAppealTypeModal;
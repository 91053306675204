export const INCOMPLETE = 1;
export const ACKNOWLEDGED = 2;
export const IN_PROGRESS = 3;
export const AWAITING_FURTHER_INFOMATION = 4;
export const CLOSED_PENDING_OUTCOME_LETTER = 5;
export const CLOSED = 6;

export class AppealState {
  id: number;
  name: string;
  propertyName: string;
  internalOnly: boolean;
  appealWorkflow: boolean;
}
import * as React from "react";
import { FormEvent } from "react";
import * as _ from 'lodash';
import Modal from "react-bootstrap/Modal";
import Button from "../../../../components/pearson/button";

interface ReOpenAppealModalProps {
  show: boolean;
  onCancel: () => void;
  onReOpen: () => void;
}

const ReOpenAppealModal = ({
  show,
  onCancel,
  onReOpen,
}: ReOpenAppealModalProps) => {

  const onFormSubmit = (
    event:
      FormEvent<HTMLFormElement> |
      React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    event.preventDefault();
    onReOpen();
  };

  return (
    <div className="re-open-appeal-modal">
      <Modal
        show={show}
        onHide={onCancel}
        aria-labelledby="Re-open appeal">
        <Modal.Header closeButton>
          <Modal.Title>Re-open appeal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="close-appeal-form" onSubmit={onFormSubmit}>
            <div className="outcome">
              <p>Please confirm that the appeal should be re-opened.</p>
            </div>                          
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            label="Cancel"
            type="reset"
            onClick={onCancel}
          /> 
          <Button
            label="Re-open appeal"
            type="submit"
            className="primary"
            onClick={onFormSubmit}
          />         
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ReOpenAppealModal;
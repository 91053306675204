import * as React from "react";
import moment from "moment";
import { INCOMPLETE } from "../../../models/appeal-state";
import { AppealEvidence } from "../../../models/appeal-evidence";
import { AppealFileUpload } from "../../../models/appeal-file-upload";
import { AppealLearner } from "../../../models/appeal-learner";
import { AppealUser } from "../../../models/appeal-user";
import AppealEvidenceCardList from "./appeal-evidence-card-list";
import AppealLearnerCardList from "./appeal-learner-card-list";
import Icon from "../../../components/pearson/icon";

interface AppealDetailsProps {
  appealId: string;
  appealStatusId: number;
  appealTypeId: number;
  appealEvidence: AppealEvidence[];
  appealLearners: AppealLearner[]; 
  learningProviderId: string;
  rationale: string;
  showDownloadLink: boolean;
  showUploadButton: boolean;
  submittedByUser: AppealUser | null;
  submittedDateTime: Date | null;
  ucasLearners: boolean;
  onDownloadAppealEvidence?: (fileId: string) => void;
  onUploadAppealFile?: (file: AppealFileUpload) => Promise<void>
}

export const AppealDetails = ({
  appealId,
  appealStatusId,
  appealTypeId,
  appealEvidence,
  appealLearners,
  learningProviderId,
  rationale,
  showDownloadLink,
  showUploadButton,
  submittedByUser,
  submittedDateTime,
  ucasLearners,
  onDownloadAppealEvidence,
  onUploadAppealFile
}: AppealDetailsProps) => {
  return (
    <div className="appeal-details">         
      <h3>Appeal service type {appealTypeId}</h3>   
      {appealStatusId !== INCOMPLETE && submittedDateTime && submittedByUser && (
        <span className="raised-by">
          <dl>
            <dt>Raised by:</dt>
            <dd>{submittedByUser.username}</dd>
          </dl>
          <dl>
            <dt>Appeal date:</dt>
            <dd>{moment(submittedDateTime).format("DD/MM/YYYY HH:mm")}</dd>
          </dl>
        </span>
      )}    
      <span className="ucas-learners">
        <span className="checkbox">
          {!ucasLearners && (
            <Icon icon="multi-empty" size="24" fill="grey" />
          )}
          {ucasLearners && (
            <Icon icon="multi-correct" size="24" fill="primary" />
          )}
        </span>        
        <span className="text">One or more candidates on whose behalf this appeal has been submitted require confirmation of their final grade for UCAS/ progression purposes.</span>
      </span>
      <div className="detail">
        <label className="gr-label">{`Learners (${appealLearners.length})`}</label>
        <AppealLearnerCardList
          appealTypeId={appealTypeId}
          learners={appealLearners}
          mode="View"
          showSearch={false}
        />
      </div>
      <div className="detail">
        <label className="gr-label">Rationale</label>
        <span style={{ whiteSpace: "pre-line" }}>{rationale}</span>        
      </div>     
      <div className="detail">
        <AppealEvidenceCardList
          appealId={appealId}
          appealEvidence={appealEvidence}
          learningProviderId={learningProviderId}
          showDownloadLink={showDownloadLink}
          showUploadButton={showUploadButton}
          onDownloadAppealEvidence={onDownloadAppealEvidence}
          onUploadAppealFile={onUploadAppealFile}
        />
      </div>
    </div>
  )
};

AppealDetails.defaultProps = {
  appealStatus: "Incomplete",
  submittedByUser: null,
  submittedDateTime: null
}

export default AppealDetails;

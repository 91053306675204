import { connect } from "react-redux";
import { StoreState } from "store/store-state";
import { ThunkDispatch } from "redux-thunk";
import _ from "lodash";
import { CsvActions, downloadCsv, downloadZip } from "../../actions/csv.actions";
import { QualificationActions, getQualifications } from "../../actions/qualification.actions";
import { QualificationGroupActions, setQualificationGroup } from "../../actions/qualification-group.actions";
import { PageStateActions, setQualificationsPageState } from "../../actions/page-state.actions";
import { Qualification } from "../../models/qualification";
import { QualificationsPageState } from "../../models/page-state";

import {
  Qualifications,
  StateProps,
  OwnProps,
  DispatchProps,
} from "./qualifications";


const mapStateToProps = (state: StoreState, ownProps: OwnProps): StateProps => {
  const pageState = state.pageState.qualificationsPageState;
  const qualificationGroupId = ownProps.match.params.qualificationGroupId;
  return {
    learningProviderId: state.selectedLearningProviderId,
    qualificationGroup: _.find(state.qualificationGroups, {
      id: qualificationGroupId,
    }),
    qualifications: state.qualifications,
    internal: state.user ? state.user.isInternal : false,
    showAppeals: state.user ? state.user.showAppeals : false,
    searchOption: pageState.searchOption,
    searchText: pageState.searchText,
    sortBy: pageState.sortBy,
    sortDirection: pageState.sortDirection
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    StoreState,
    void,
    CsvActions |
    QualificationActions | 
    PageStateActions |
    QualificationGroupActions 
  >
): DispatchProps => ({
  downloadCsvFile: (learningProviderId: string, qualification: Qualification) =>
    downloadCsv(learningProviderId, qualification),
  downloadZipFile: (learningProviderId: string, qualificationGroupId: string) =>
    downloadZip(learningProviderId, qualificationGroupId),
  getQualifications: (
    learningProviderId: string,
    qualificationGroupId: string
  ) => dispatch(getQualifications(learningProviderId, qualificationGroupId)),
  setPageState: (pageState: QualificationsPageState) =>
    dispatch(setQualificationsPageState(pageState)),
  setQualificationGroup: (qualificationGroupId: string | null) =>
    dispatch(setQualificationGroup(qualificationGroupId)),
});

export default connect<StateProps>(
  mapStateToProps,
  mapDispatchToProps
)(Qualifications);


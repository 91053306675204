import * as React from 'react';
import * as _ from 'lodash';
import { AppealNote } from '../../../models/appeal-note';
import TeamAppealNoteCard from './team-appeal-note-card';

interface TeamAppealNoteCardListProps { 
  appealNotes: AppealNote[];
}

export const TeamAppealNoteCardList = ({
  appealNotes
}: TeamAppealNoteCardListProps) => {
  return (
    <div className="team-appeal-note-card-list">
      {_.map(appealNotes, (appealNote, index) => (
          <TeamAppealNoteCard
            key={index}
            id={index.toString()}
            appealNote={appealNote}
          />   
        ))
      }
    </div>
  );
};

export default TeamAppealNoteCardList
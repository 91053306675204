import * as React from 'react';
import * as _ from "lodash";

<script src="https://unpkg.com/@pearson-ux/footer" />;

interface FooterLink {
  name: string;
  href: string;
}

interface FooterProps {
  theme?: "dark";
  links: FooterLink[];  
}

export const Footer = ({ theme, links }: FooterProps) => (
  <footer className={theme} role="page-footer">
    {links.length > 0 && (
      <ul className="links">
        {_.map(links, (link, index) => (
          <li key={index}>
            <a href={link.href} target="_blank">
              {link.name}
            </a>
          </li>
        ))}
      </ul>
    )}
    <p className="copyright">
      Copyright © 1996-{new Date().getFullYear()} Pearson Education Inc.
      All Rights Reserved.
  </p>
  </footer>
);

Footer.defaultProps = {
  theme: "light",
  className: ""
}

export default Footer;
import { combineReducers } from "redux";
import { StoreState } from "../store/store-state";
import { ajaxStatusReducer as ajaxCallsInProgress } from './ajax-status.reducer';
import { appealsReducer as appeals } from './appeals.reducer';
import { appealDetailReducer as appealDetail } from './appeal-detail.reducer';
import { appealFileUploadReducer as appealFileUploadStatus } from "./appeal-file-upload.reducer";
import { appealsManagementReducer as appealsManagement } from './appeals-management.reducer';
import { appealOutcomesReducer as appealOutcomes } from './appeal-outcomes.reducer';
import { appealStatesReducer as appealStates } from './appeal-states.reducer';
import { appealTemplatesReducer as appealTemplates } from './appeal-templates.reducer';
import { appealTypesReducer as appealTypes } from './appeal-types.reducer';
import { AuthReducer as auth } from "./auth.reducer";
import { CallbackReducer as callback } from './callback.reducer';
import { csvUploadReducer as csvUploadStatus } from './csv-upload.reducer';
// import { errorReducer as error } from './error.reducer';
import { learnerGradesReducer as learnerGrades } from "./learner-grades.reducer";
import { pageStateReducer as pageState } from './page-state.reducer';
import { qualificationGroupsReducer as qualificationGroups } from "./qualification-group.reducer";
import { qualificationsReducer as qualifications } from "./qualification.reducer";
import { selectedLearningProviderIdReducer as selectedLearningProviderId } from './learning-provider.reducer';
import { selectedQualificationGroupIdReducer as selectedQualificationGroupId } from './qualification-group.reducer';
import { teamsReducer as teams } from './teams.reducer';
import { uploadFileReducer as uploadFile } from './upload-file.reducer'
import { uploadFileHistoryReducer as uploadFileHistories } from './upload-file-history.reducer'
import { userReducer as user } from './user.reducer'

const rootReducer = combineReducers<StoreState>({
  ajaxCallsInProgress,
  appealDetail,
  appeals,
  appealFileUploadStatus,
  appealsManagement,
  appealOutcomes,
  appealStates,
  appealTemplates,
  appealTypes,
  auth,
  callback,
  csvUploadStatus,
  // error,
  learnerGrades,
  pageState,
  qualificationGroups,
  qualifications,
  selectedLearningProviderId,
  selectedQualificationGroupId,
  teams, 
  uploadFile,
  uploadFileHistories,
  user
});

export default rootReducer;
import axios from 'axios';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { ajaxCallError, beginAjaxCall, AjaxCallActions } from './ajax-status.actions';
import { StoreState } from '../store/store-state';
import config from '../app.config';
import _ from 'lodash';
import moment from 'moment';
import { Appeal } from '../models/appeal';
import { AppealDetail } from '../models/appeal-detail';
import { AppealEvidence } from '../models/appeal-evidence';
import { AppealLearner } from '../models/appeal-learner';
import { AppealSubmit } from '../models/appeal-submit';
import { AppealType } from '../models/appeal-type';
import { Team } from '../models/team';
import { CLOSED_PENDING_OUTCOME_LETTER, CLOSED } from '../models/appeal-state';
import { User } from '../models/user';
import {   
  ACKNOWLEDGE_APPEAL_SUCCESS,
  DELETE_APPEAL_SUCCESS,
  GET_ALL_APPEALS_SUCCESS,
  GET_APPEAL_DETAILS_SUCCESS,
  GET_APPEAL_LEARNERS_SUCCESS, 
  GET_APPEALS_SUCCESS,  
  SAVE_APPEAL_SUCCESS,
  GET_APPEAL_TYPES_SUCCESS} from './action-types';


interface AcknowledgeAppealSuccessAction extends Action {
  type: ACKNOWLEDGE_APPEAL_SUCCESS;
  learningProviderId: string; appealId: string;
}
interface DeleteAppealSuccessAction extends Action {
  type: DELETE_APPEAL_SUCCESS;
  appealId: string;
}
interface GetAllAppealsSuccessAction extends Action {
  type: GET_ALL_APPEALS_SUCCESS;
  appeals: Appeal[];
}
interface GetAppealDetailsSuccessAction extends Action {
  type: GET_APPEAL_DETAILS_SUCCESS;
  appealDetail: AppealDetail;
}
interface GetAppealLearnersSuccessAction extends Action {
  type: GET_APPEAL_LEARNERS_SUCCESS;
  learners: AppealLearner[];
}
interface GetAppealsSuccessAction extends Action {
  type: GET_APPEALS_SUCCESS;
  appeals: Appeal[];
}
interface GetAppealTypesSuccessAction extends Action {
  type: GET_APPEAL_TYPES_SUCCESS;
  appealTypes: AppealType[];
}
interface SaveAppealSuccessAction extends Action {
  type: SAVE_APPEAL_SUCCESS;
  appealDetail: AppealDetail;
}

export type acknowledgeAppealSuccessAction =
  AcknowledgeAppealSuccessAction |
  AjaxCallActions;
export type deleteAppealSuccessAction =
  DeleteAppealSuccessAction |
  AjaxCallActions;
export type getAllAppealsSuccessAction =
  GetAllAppealsSuccessAction |
  AjaxCallActions;
export type getAppealDetailsSuccessAction =
  GetAppealDetailsSuccessAction |
  AjaxCallActions;
export type getAppealLearnersSuccessAction =
  GetAppealLearnersSuccessAction |
  AjaxCallActions;
export type getAppealsSuccessAction =
  GetAppealsSuccessAction |
  AjaxCallActions;
export type getAppealTypesSuccessAction =
  GetAppealTypesSuccessAction |
  AjaxCallActions;
export type saveAppealSuccessAction =
  SaveAppealSuccessAction |
  AjaxCallActions;

export const acknoledgeAppealSuccessAction = (
  learningProviderId: string,
  appealId: string
): AcknowledgeAppealSuccessAction => ({
  learningProviderId,
  appealId,
  type: ACKNOWLEDGE_APPEAL_SUCCESS
});
export const deleteAppealSuccessAction = (
  appealId: string
): DeleteAppealSuccessAction => ({
    appealId,
  type: DELETE_APPEAL_SUCCESS
});
export const getAllAppealsSuccessAction = (
  appeals: Appeal[]
): GetAllAppealsSuccessAction => ({
  appeals,
  type: GET_ALL_APPEALS_SUCCESS
});
export const getAppealDetailsSuccessAction = (
  appealDetail: AppealDetail
): GetAppealDetailsSuccessAction => ({
  appealDetail,
  type: GET_APPEAL_DETAILS_SUCCESS
});
export const getAppealLearnersSuccessAction = (
  learners: AppealLearner[]
): GetAppealLearnersSuccessAction => ({
  learners,
  type: GET_APPEAL_LEARNERS_SUCCESS
});
export const getAppealsSuccessAction = (
  appeals: Appeal[]
): GetAppealsSuccessAction => ({
  appeals,
  type: GET_APPEALS_SUCCESS
});
export const saveAppealSuccessAction = (
  appealDetail: AppealDetail
): SaveAppealSuccessAction => ({
    appealDetail,
  type: SAVE_APPEAL_SUCCESS
});
export const getAppealTypesSuccessAction = (
  appealTypes: AppealType[]
): GetAppealTypesSuccessAction => ({
  appealTypes,
  type: GET_APPEAL_TYPES_SUCCESS
});

export const acknowledgeAppeal = (
  learningProviderId: string,
  appealId: string) => {
  return (dispatch: ThunkDispatch<StoreState, void, acknowledgeAppealSuccessAction>) => {
    dispatch(beginAjaxCall());
    return axios
      .post(`${config.API_GATEWAY.URL}/appeal/${learningProviderId}/appeal/${appealId}/acknowledge`,
        { headers: { "content-type": "application/json" } }
      )
      .then((response) => {
        dispatch(acknoledgeAppealSuccessAction(learningProviderId, appealId));
      })
      .catch(error => {
        dispatch(ajaxCallError(error));
        throw error;
      });
  };
};

export const deleteAppeal = (
  learningProviderId: string,
  appealId: string) => {
  return (dispatch: ThunkDispatch<StoreState, void, deleteAppealSuccessAction>) => {
    dispatch(beginAjaxCall());
    return axios
      .delete(`${config.API_GATEWAY.URL}/appeal/${learningProviderId}/appeal/${appealId}`,
        { headers: { "content-type": "application/json" } }
      )
      .then((response) => {
        dispatch(deleteAppealSuccessAction(appealId));
      })
      .catch(error => {
        dispatch(ajaxCallError(error));
        throw error;
      });
  };
};

export const getAllAppeals = (learningProviderId: string) => {
  return (dispatch: ThunkDispatch<StoreState, void, getAllAppealsSuccessAction>, getState: any) => {
    dispatch(beginAjaxCall());
    return axios
      .post(`${config.API_GATEWAY.URL}/appeal/${learningProviderId}/appeals`,
        { headers: { "content-type": "application/json" } }
      )
      .then(response => {
        const appeals = response.data
        dispatch(getAllAppealsSuccessAction(
          _.orderBy(_.map(appeals, appeal => {
            return mapAppeal(appeal, getState().user);
          }), ["statusId", "appealTypeId"])
        ))
      })
      .catch(error => {
        dispatch(ajaxCallError(error));
        throw error;
      });
  };
};

export const getAppealDetails = (
  learningProviderId: string,
  appealId: string) => {
  return (dispatch: ThunkDispatch<StoreState, void, getAppealDetailsSuccessAction>, getState: any) => {
    dispatch(beginAjaxCall());
    return axios
      .get(`${config.API_GATEWAY.URL}/appeal/${learningProviderId}/appeal/${appealId}`)
      .then(response => {
        dispatch(getAppealDetailsSuccessAction(
          mapAppealDetail(response.data, getState().teams, getState().user)
        ))
      })
      .catch(error => {
        dispatch(ajaxCallError(error));
        throw error;
      });
  };
};

export const getAppealLearners = (
  learningProviderId: string,
  qualificationId: string) => {
  return (dispatch: ThunkDispatch<StoreState, void, getAppealLearnersSuccessAction>) => {
    dispatch(beginAjaxCall());
    return axios
      .get(`${config.API_GATEWAY.URL}/appeal/${learningProviderId}/appeal/${qualificationId}/learners`)
      .then(response => {
        const learners = _.orderBy(_.map(response.data, (learner) => {
          return mapAppealLearner(learner);
        }), ["forenames", "surname"]);
        dispatch(getAppealLearnersSuccessAction(learners));
        return learners;
      })
      .catch(error => {
        dispatch(ajaxCallError(error));
        throw error;
      });
  };
};

export const getAppeals = (
  learningProviderId: string,
  qualificationGroupId: string,
  qualificationId: string) => {
  return (dispatch: ThunkDispatch<StoreState, void, getAppealsSuccessAction>, getState: any) => {
    dispatch(beginAjaxCall());
    return axios
      .post(`${config.API_GATEWAY.URL}/appeal/${learningProviderId}/appeals`,
        JSON.stringify({
          qualificationGroupId: qualificationGroupId,
          qualificationId: qualificationId
        }), { headers: { "content-type": "application/json" } }
      )
      .then(response => {
        const appeals = response.data
        dispatch(getAppealsSuccessAction(
          _.orderBy(_.map(appeals, appeal => {
            return mapAppeal(appeal, getState().user);
          }), ["statusId", "appealTypeId"])
        ))
      })
      .catch(error => {
        dispatch(ajaxCallError(error));
        throw error;
      });
  };
};

export const getAppealTypes = () => {
  return (dispatch: ThunkDispatch<StoreState, void, getAppealTypesSuccessAction>) => {
    dispatch(beginAjaxCall());
    return axios
      .get(`${config.API_GATEWAY.URL}/appeal/appealtypes`)
      .then(response => {
        const appealTypes = response.data;
        dispatch(getAppealTypesSuccessAction(
          _.map(appealTypes, (appealType => {
            const at = new AppealType();
            at.id = appealType.id;
            at.isAvailable = appealType.isAvailable;
            at.name = appealType.name;
            at.sla = appealType.sla;
            at.teamId = appealType.teamId;           
            return at;
          }))
        ));
      })
      .catch(error => {
        dispatch(ajaxCallError(error));
        throw error;
      });
  };
};

export const saveAppeal = (
  learningProviderId: string,
  qualificationId: string,
  appeal: AppealSubmit) => {
  return (dispatch: ThunkDispatch<StoreState, void, saveAppealSuccessAction>, getState: any) => {
    dispatch(beginAjaxCall());
    return axios
      .post(`${config.API_GATEWAY.URL}/appeal/${learningProviderId}/appeal/${qualificationId}`,
        JSON.stringify(appeal),
        { headers: { "content-type": "application/json" } }
      )
      .then((response) => {
        let appeal = mapAppealDetail(response.data, getState().teams, getState().user);
        dispatch(saveAppealSuccessAction(appeal));
      })
      .catch(error => {
        dispatch(ajaxCallError(error));
        throw error;
      });
  };
};

const mapAppealEvidence = (appealEvidence: any) => {
const ae = new AppealEvidence();
  ae.addedDateTime = new Date(appealEvidence.addedDateTime)
  ae.fileId = appealEvidence.fileId;
  ae.fileSize = appealEvidence.fileSize;
  ae.fileStatus = appealEvidence.fileStatus;
  ae.fileStatusName = appealEvidence.fileStatusName;
  ae.filename = appealEvidence.filename;
  ae.s3Key = appealEvidence.s3Key;
  ae.username = appealEvidence.username;
  ae.allowDownload = appealEvidence.fileStatusName === "Ready"
  return ae;
}

const mapAppealProps = (appeal: any, user: User) => {
  const a = new Appeal();
  a.appealId = appeal.appealId;
  a.appealTypeId = appeal.appealTypeId;
  a.hoC = appeal.hoC;
  a.learnerConsent = appeal.learnerConsent;
  a.learningProviderId = appeal.learningProviderId;
  a.qualificationCode = appeal.qualificationCode;
  a.qualificationId = appeal.qualificationId;
  a.qualificationName = appeal.qualificationName;
  a.qualificationGroupId = appeal.qualificationGroupId;
  a.qualificationDisplayName = `${appeal.qualificationGroupId} ${appeal.qualificationName} ${appeal.qualificationCode}`;
  a.rationale = appeal.rationale;

  if (user.isInternal) {
    a.statusId = appeal.statusId;
  } else {
    a.statusId = appeal.statusId === CLOSED_PENDING_OUTCOME_LETTER ? CLOSED : appeal.statusId;
  }
  a.tandC = appeal.tandC;
  a.ucasLearners = appeal.ucasLearners;
  return a;
}

const mapAppeal = (appeal: any, user: User) => {
  const a = { ...new Appeal(), ...mapAppealProps(appeal, user) };
  a.learnerCount = appeal.learnerCount;
  a.learnerCountString = padStart(appeal.learnerCount);
  a.learnerIdentifiers = appeal.learnerIdentifiers ?? [];
  a.searchString = `${a.qualificationDisplayName} Appeal service ${a.appealTypeId} ${a.learnerCount}`.toLowerCase();
  // a.searchString = `${a.qualificationDisplayName} Appeal service ${a.appealTypeId} ${a.learnerCount} ${a.status}`.toLowerCase();
  return a;
}

export const mapAppealDetail = (appealDetail: any, teams: Team[], user: User) => {
  const a = { ...new AppealDetail(), ...mapAppealProps(appealDetail, user)};  
  a.assignedUser = appealDetail.assignedUser;  
  a.evidence = _.orderBy(
    _.map(appealDetail.evidence, (evidence: any) => {
      return mapAppealEvidence(evidence);
    }), ["addedDateTime"], ["desc"]);  
  appealDetail.evidence ?? [];  
  a.lastUpdateDateTime = new Date(appealDetail.lastUpdateDateTime);
  a.lastUpdatedUser = appealDetail.lastUpdatedUser;  
  a.learningProvider = {...appealDetail.learningProvider};
  a.learners = _.orderBy(
    _.map(appealDetail.learners, (learner) => {
      return mapAppealLearner(learner);
    }), 
  ["forenames", "surname"]);
  a.learnersInAppealCount = appealDetail.learnersInAppealCount;
  a.notes = _.orderBy(appealDetail.notes, ["addedDateTime"], ["desc"]);  
  a.outcomeId = appealDetail.outcomeId;
  a.qualification = { ...appealDetail.qualification};
  a.referenceCode = appealDetail.referenceCode;
  a.submittedDateTime = new Date(appealDetail.submittedDateTime);
  a.submittedByUser = appealDetail.submittedByUser;
  a.teamId = appealDetail.teamId;
  a.team = _.find(teams, ["id", a.teamId ])?.name ?? "";
  a.totalLearnerCount = appealDetail.totalLearnerCount;    
  return a;
}

export const mapAppealLearner = (learner: any) => {
  let l = new AppealLearner();
  l.dateOfBirth = new Date(learner.dateOfBirth);
  l.forenames = learner.forenames ?? "";
  l.isInAppeal = learner.isInAppeal;
  l.learnerIdentifier = learner.learnerIdentifier;
  l.learnerIdentifierName = learner.learnerIdentifierName;
  l.surname = learner.surname ?? "";
  l.appeals = learner.appeals ?? [];
  l.searchString = `${learner.forenames ?? ""} 
                    ${learner.surname ?? ""} 
                    ${learner.learnerIdentifier} 
                    ${learner.dateOfBirth
                      ? moment(learner.dateOfBirth).format("DD/MM/YYYY")
                      : ""}`.toLowerCase();
  return l;
}

const padStart = (number: number | null) => {
  return number ? _.padStart(number.toString(), 5, '0') : "";
}

export type AppealActions =
  AcknowledgeAppealSuccessAction | 
  DeleteAppealSuccessAction |
  GetAllAppealsSuccessAction |
  GetAppealsSuccessAction |
  GetAppealDetailsSuccessAction |
  GetAppealLearnersSuccessAction |
  GetAppealTypesSuccessAction |
  SaveAppealSuccessAction
// import { Reducer } from "redux";
import initialState from './initialState';
import { Qualification } from "../models/qualification";
import { LearningProviderActions } from "../actions/learning-provider.actions";
import { QualificationActions } from "../actions/qualification.actions";
import { QualificationGroupActions } from '../actions/qualification-group.actions';
import {
  GET_QUALIFICATIONS_SUCCESS,
  GET_QUALIFICATION_SUCCESS,
  SET_LEARNING_PROVIDER,
  SET_QUALIFICATION_GROUP
} from "../actions/action-types";

export function qualificationsReducer(
  state: Qualification[] = initialState.qualifications,
  action: LearningProviderActions | QualificationGroupActions | QualificationActions
): Qualification[] {
  switch (action.type) {
    case GET_QUALIFICATIONS_SUCCESS:
      return [...action.qualifications];
    case SET_LEARNING_PROVIDER:
    case SET_QUALIFICATION_GROUP:
      return [...initialState.qualifications];
    case GET_QUALIFICATION_SUCCESS:
      if (action.qualification) {
        // Replace single qualification
        var qualifications = state.map(function(x: Qualification) {
          if (x.id === action.qualification.id) {
            return action.qualification;
          }
          return x; 
        });
        return qualifications;
      }
      return state;
    default:
      return state;
  }
};


import * as React from 'react';
import SearchCard from '../../../components/pearson/search-card';
import { UploadFileError } from '../../../models/upload-file-error';
import _ from 'lodash';

interface FilelineErrorsCardProps {
  csvRowNumber: string;
  fileErrors: UploadFileError[];
}

export const FilelineErrorsCard = ({
  csvRowNumber,
  fileErrors
}: FilelineErrorsCardProps) => {
  
  return (
    <div className="fileline-error-card">
      <SearchCard
        id={csvRowNumber}
        headerElement={
          <>
            <span
              className="row-number error"
              aria-label={"Error"}>
              <strong>{`Row ${csvRowNumber === "" ? "N/A" : csvRowNumber}:`}</strong>
            </span>
            <div className="error-details">
              <dl className="error">
                <dt className="error">Errors:</dt>
                <dd>
                  <ol>
                    {_.map(fileErrors, (fileError: UploadFileError, index) => (
                      <li key={index}>{fileError.errorDescription}</li>
                    ))}
                  </ol>
                </dd>
              </dl>
            </div>
            <dl className="fileline-data">
              <dt>Fileline:</dt>
              <dd>{fileErrors[0].fileline}</dd>
            </dl>
          </>
        }
        errorMessage={
          fileErrors.length === 1
            ? fileErrors[0].errorDescription
            : `Fileline contains multiple errors`
        }
      />
    </div>
  );}

export default FilelineErrorsCard
import * as React from "react";
import { useSelector } from "react-redux";
import { StoreState } from "../../../store/store-state";
import _ from "lodash";
import { Appeal } from "../../../models/appeal";
import { AppealState } from "../../../models/appeal-state";

interface AppealSummaryProps {
  internal: boolean;
  appeals: Appeal[];  
}

export const AppealSummary = ({
  internal,
  appeals
}: AppealSummaryProps) => {

  const appealStates = _.filter(
    useSelector((state: StoreState) => state.appealStates), (appealState: AppealState) => {
      return internal || appealState.internalOnly === false;
    }
  ) 

  return (
    <div className="summary">
      <div className="status">
        {_.map(appealStates, (appealState, index) => {
          const count = _.filter(appeals, ["statusId", appealState.id]).length;
          return count > 0
            ? <dl key={index}>
                <dt>{appealState.name}:</dt>
                <dd>{count}</dd>
              </dl>
            :null
        })}
      </div>
    </div>
  )
};

export default AppealSummary;
import { connect } from "react-redux";
import { StoreState } from "../../store/store-state";
import { ThunkDispatch } from "redux-thunk";
import { AppealSearch } from "../../models/appeal-search";
import { AppealManagementActions, getAppealByReferenceCode, getMyTeamAppeals, getTeamAppeals, getUnallocatedTeamAppeals, setActiveIdx, setTeamId, setTeamWorkSearch } from "../../actions/appeal-management.actions";

import {
  AppealsManagementPage,
  StateProps,
  DispatchProps,
} from "./appeals-management-page";
import { AppealSearchPaging } from "models/appeal-search-paging";


const mapStateToProps = (state: StoreState): StateProps => {
  const pageState = state.appealsManagement;
  return {
    activeIdx: pageState.activeIdx,
    appealTypes: state.appealTypes,
    internal: state.user ? state.user.isInternal : false,
    myWork: pageState.myWork,
    myWorkSearch: pageState.myWorkSearch,
    referenceCodeAppeal: pageState.referenceCodeAppeal,
    referenceCodeIsValid: pageState.referenceCodeIsValid,
    referenceCodeSearch: pageState.referenceCodeSearch,
    teamWork: pageState.teamWork,
    teamWorkSearch: pageState.teamWorkSearch,
    unallocatedWork: pageState.unallocatedWork,
    unallocatedWorkSearch: pageState.unallocatedWorkSearch,
    teamId: pageState.teamId,
    teams: state.teams
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<StoreState, void,
  AppealManagementActions
>): DispatchProps => ({
  getAppealByReferenceCode: (referenceCode: string) =>
    dispatch(getAppealByReferenceCode(referenceCode)),
  getMyTeamAppeals: (teamId: number, appealSearch: AppealSearch) =>
    dispatch(getMyTeamAppeals(teamId, appealSearch)),
  getTeamAppeals: (teamId: number, appealSearch: AppealSearch) =>
    dispatch(getTeamAppeals(teamId, appealSearch)),
  getUnallocatedTeamAppeals: (teamId: number, appealSearch: AppealSearchPaging, loadMoreWork: boolean) =>
    dispatch(getUnallocatedTeamAppeals(teamId, appealSearch, loadMoreWork)),
  setActiveIdx: (activeIdx: number) =>
    dispatch(setActiveIdx(activeIdx)),
  setTeamId: (teamId: number) =>
    dispatch(setTeamId(teamId)),
  setTeamWorkSearch: (teamWorkSearch: AppealSearch) =>
    dispatch(setTeamWorkSearch(teamWorkSearch))
});

export default connect<StateProps>(
  mapStateToProps,
  mapDispatchToProps
)(AppealsManagementPage);
import axios, { AxiosRequestConfig } from 'axios';
import AuthForgeRock from './actions/auth/auth';

const uuid = require('uuid/v4');
const auth = new AuthForgeRock();

export const requireBearerToken = () => {
  axios.interceptors.request.use((config: AxiosRequestConfig) => {
    config.headers.common.Authorization = `Bearer ${auth.getIdToken()}`;
    return config;
  });
}

export const requireCorrelationId = () => {
  axios.interceptors.request.use((config: AxiosRequestConfig) => {
    config.headers.common["x-correlation-id"] = uuid();
    return config;
  });
}

export const reaponseInterceptor = () => {
  axios.interceptors.response.use((response: any) => {
    return response;
  }, (error: any) => {

   // if 401 clear session to cause re-authentication 
   if (error && error.response && error.response.status === 401) {
    auth.clearLocalSession();
    window.location.reload();
    return;
   }

   // if 503 go to unavailable page
   if (error && error.response && error.response.status === 503 && window.location.pathname != '/unavailable') {
    window.location.href = '/unavailable';
   }

   // if any other 500+ error go to error page
   if (error && error.response && error.response.status >= 500) {
    var correlationId: string = error.response.headers['x-correlation-id'];

    if (correlationId === undefined || correlationId === null || correlationId.length === 0){
      correlationId = '99999999-9999-9999-9999-999999999999'
    }

    window.location.href = '/error/' + correlationId;
   }

   if (error && error.message === 'Network Error' && window.location.pathname != '/unavailable') {
    window.location.href = '/unavailable';
   }

   return Promise.reject(error);
  });
}

import * as React from 'react';
import FilelineErrorsCard from './fileline-errors-card';
import { useState, useEffect } from 'react';
import * as _ from 'lodash';
import { UploadFileError } from '../../../models/upload-file-error';

interface FilelineErrorsCardListProps {
  searchText: string;
  fileErrors: UploadFileError[];
}

export const FilelinesErrorCardList = ({
  searchText,
  fileErrors
}: FilelineErrorsCardListProps) => { 
  const [fileErrorsGroupedByRow, setFileErrorsGroupedByRow] = useState(_.chain(fileErrors)
      .groupBy("csvRowNumber")
        .map((value, key) => ({ csvRowNumber: key, errors: value }))
        .value());  

  useEffect(() => {
    const errors = searchText === ""
      ? fileErrors
      : _.filter(fileErrors, (fileError: UploadFileError) => {
        return fileError.searchString.toLowerCase().includes(searchText.toLowerCase());
    });
    setFileErrorsGroupedByRow(_.chain(errors)
        .groupBy("csvRowNumber")
        .map((value, key) => ({ csvRowNumber: key, errors: value }))
        .value()
    );
  }, [searchText, fileErrors]);
 
  return (
    fileErrorsGroupedByRow && fileErrorsGroupedByRow.length > 0
      ? <div className="fileline-error-card-list">
          <h3>{"Errors in file"}</h3>
          {_.map(_.orderBy(fileErrorsGroupedByRow, ["csvRowNumberString"], ["asc"]),
            (fileError: any) => (
              <FilelineErrorsCard
                key={fileError.csvRowNumber}
                csvRowNumber={fileError.csvRowNumber}
                fileErrors={fileError.errors}
              />
            )
          )}
        </div>
      : null
  );
};

export default FilelinesErrorCardList
import * as React from "react";
import * as _ from "lodash";
import moment from "moment";
import { TeamAppeal } from "../../../models/team-appeal";
import { DropdownOption } from "../../../components/pearson/dropdown";
import SearchCard from "../../../components/pearson/search-card";
import AppealStateDescription from "../../appeals/components/appeal-state-description";

interface TeamAppealCardProps {
  appeal: TeamAppeal;
  onViewAppeal: (appeal: TeamAppeal) => void;
}

export const TeamAppealCard = ({
  appeal,
  onViewAppeal
}: TeamAppealCardProps) => {

  const getActions = () => {
    const actions: DropdownOption[] = [];   
    actions.push({ name: "View appeal", onClick: () => onViewAppeal(appeal) });    
    return actions;
  } 

  return (
    <div className="team-appeal-card">
      <SearchCard
        id={appeal.appealId}
        headerElement={
          <>
            <span className="learning-provider" aria-label="Learning provider name">
              <strong>
                {`${appeal.learningProviderCode} - ${appeal.learningProviderName}`}
              </strong>
            </span>                   
            <dl className="status">
              <dt>Status:</dt>
              <dd>
                <strong>
                  <AppealStateDescription statusId={appeal.statusId}/>
                </strong>
              </dd>
            </dl>     
            <dl className="appeal-type">
              <dt>Appeal service type:</dt>
              <dd>{appeal.appealTypeId}</dd>
            </dl>     
            <dl className="number-of-learners">
              <dt>No. of learners:</dt>
              <dd>{appeal.learnerCount}</dd>
            </dl>
            <dl className="team">
              <dt>Team:</dt>
              <dd>{appeal.teamName}</dd>
            </dl>
            <dl className="allocated-to">
              <dt>Allocated to:</dt>
              <dd>{appeal.assignedUserName}</dd>
            </dl>
            <dl className="date-received">
              <dt>Date received:</dt>
              <dd>{moment(appeal.submittedDateTime).format("DD/MM/YYYY HH:mm")}</dd>
            </dl>
            <dl className="last-updated">
              <dt>Last updated:</dt>
              <dd>{moment(appeal.lastUpdatedDateTime).format("DD/MM/YYYY HH:mm")}</dd>
            </dl>
            <dl className="qualification-group">
              <dt>Qualification type:</dt>
              <dd>{appeal.qualificationGroupId}</dd>
            </dl>
          </>
        }
        dropdownMenuRight={true}
        actions={getActions()}
      />
    </div>
  );
};

export default TeamAppealCard;
import * as React from "react";
import _ from "lodash";
import { AppealType } from "../../../../models/appeal-type";
import { Option } from "../../../../models/option";
import Select from "../../../../components/pearson/select";
import { FormEvent } from "react";

interface AppealTypeSelectProps {
  appealTypeId: number;
  appealTypes: AppealType[];
  includeAllOption: boolean;
  onChange: (appealTypeId: number) => void;
}
export const AppealTypeSelect = ({
  appealTypeId,
  appealTypes,
  includeAllOption,
  onChange
}: AppealTypeSelectProps) => {

  const getOptions = () => {
    const options: Option[] = [];
    if (includeAllOption) {
      options.push({ value: 0, name: "All" });
    }
    else {
      options.push({ value: 0, name: "Please select..." });
    }
    _.map(appealTypes, appealType => {
      options.push({
        name: appealType.name.replace("(only applicable following completion of an initial review service 1-5)", ""),
        value: appealType.id
      }) 
    })
    return options;
  }

  return (
    <div className="appeal-type-select">
      <Select
        id="appealTypeSelect"
        label="Appeal type:"
        value={appealTypeId}
        options={getOptions()}
        onChange={(e: FormEvent<HTMLSelectElement>) => {
          onChange(parseInt(e.currentTarget.value))
        }}
      />
    </div>
  )
};

export default AppealTypeSelect;

import * as React from "react";
import { FormEvent } from "react";
import Select from "../../../../components/pearson/select";

interface PageSizeSelectProps {
  pageSize: number;
  onChange: (pageSize: number) => void;
}

export const PageSizeSelect = ({
  pageSize,
  onChange
}: PageSizeSelectProps) => {

  return (
    <div className="page-size-select">
      <Select
        id="pageSizeSelect"
        label="Page size:"
        value={pageSize}
        options={[
          { value: 10, name: "10" },
          { value: 25,  name: "25" },
          { value: 50,  name: "50" },
          { value: 100, name: "100" }
        ]}
        onChange={(e: FormEvent<HTMLSelectElement>) => {
          onChange(parseInt(e.currentTarget.value))
        }}
      />
    </div>
  )
};

export default PageSizeSelect;

import initialState from './initialState';
import { AppealManagementActions } from '../actions/appeal-management.actions';
import { LearnerGradeActions } from '../actions/learner-grade.actions';
import { LearningProviderActions } from "../actions/learning-provider.actions"
import { QualificationGroupActions } from '../actions/qualification-group.actions';
import { PageStateActions } from "../actions/page-state.actions"
import { UploadFileHistoryActions } from '../actions/upload-file-history.actions';
import { PageState } from "../models/page-state"
import { 
  GET_LEARNER_GRADES_SUCCESS,
  // GET_TEAM_APPEAL_BY_REFERENCE_CODE_SUCCESS,
  // GET_TEAM_APPEALS_BY_USER_SUCCESS,
  // GET_TEAM_APPEALS_WORK_SUCCESS,
  GET_UPLOAD_FILE_HISTORY_SUCCESS,
  SET_ALL_APPEALS_PAGE_STATE,
  // SET_APPEALS_MANAGEMENT_PAGE_STATE,
  SET_APPEALS_PAGE_STATE,
  SET_LEARNER_GRADES_PAGE_STATE,
  SET_QUALIFICATIONS_PAGE_STATE,
  SET_UPLOAD_FILE_HISTORY_PAGE_STATE,
  SET_LEARNING_PROVIDER,
  SET_QUALIFICATION_GROUP
} from "../actions/action-types";

export function pageStateReducer(
  state: PageState = initialState.pageState,
  action: 
    AppealManagementActions |
    LearnerGradeActions | 
    LearningProviderActions | 
    PageStateActions |
    QualificationGroupActions |
    UploadFileHistoryActions
): PageState {

  switch (action.type) {      
    case SET_ALL_APPEALS_PAGE_STATE:
      return { ...state, allAppealsPageState: { ...action.pageState } };
    case SET_APPEALS_PAGE_STATE:
      return { ...state, appealsPageState: { ...action.pageState } };
    case SET_LEARNER_GRADES_PAGE_STATE:
      return { ...state, learnerGradesPageState: { ...action.pageState } };
    case SET_QUALIFICATIONS_PAGE_STATE:
      return { ...state, qualificationsPageState: { ...action.pageState } };
    // case GET_TEAM_APPEAL_BY_REFERENCE_CODE_SUCCESS:
    // case GET_TEAM_APPEALS_BY_USER_SUCCESS:
    // case GET_TEAM_APPEALS_WORK_SUCCESS:
    // case SET_APPEALS_MANAGEMENT_PAGE_STATE:
    //   return { ...state, appealsManagementPageState: { ...action.pageState } };   
    case SET_UPLOAD_FILE_HISTORY_PAGE_STATE:
      return { ...state, uploadFileHistoryPageState: { ...action.pageState } };

    case GET_LEARNER_GRADES_SUCCESS:
      return { ...state, learnerGradesPageState: initialState.pageState.learnerGradesPageState };   
    case GET_UPLOAD_FILE_HISTORY_SUCCESS:
      return { ...state, uploadFileHistoryPageState: initialState.pageState.uploadFileHistoryPageState };
      
    case SET_LEARNING_PROVIDER:
    case SET_QUALIFICATION_GROUP:
      return initialState.pageState;
    default:
      return state;
  };
};
import * as React from "react";
import * as _ from "lodash";
import { Appeal } from "../../../models/appeal";
import SearchCard from "../../../components/pearson/search-card";
import { DropdownOption } from "../../../components/pearson/dropdown";
import { ACKNOWLEDGED, INCOMPLETE } from "../../../models/appeal-state";
import { AppealStateDescription } from "./appeal-state-description";
import { useState } from "react";
import CancelAppealModal from "./cancel-appeal-modal";

interface AppealCardProps {
  appeal: Appeal;
  internal: boolean;
  onCancelAppeal: (appeal: Appeal) => void;
  onCompleteAppeal?: (appeal: Appeal) => void;
  onEditAppeal?: (appeal: Appeal) => void;
  onViewAppeal: (appeal: Appeal) => void;
}

export const AppealCard = ({
  appeal,
  onCancelAppeal,
  onCompleteAppeal,
  onEditAppeal,
  onViewAppeal
}: AppealCardProps) => {

  const [showCancelAppealModal, setShowCancelAppealModal] = useState(false);

  const getActions = () => {
    const actions: DropdownOption[] = [];
    if ((appeal.statusId === INCOMPLETE || appeal.statusId === ACKNOWLEDGED) && onCancelAppeal) {
      actions.push({ name: "Cancel appeal", onClick: () => setShowCancelAppealModal(true) })
    }
    if ((appeal.statusId === INCOMPLETE) && onCompleteAppeal) {
      actions.push({ name: "Complete appeal", onClick: () => onCompleteAppeal(appeal) }) 
    }        
    // if (internal && onEditAppeal) {
    //   actions.push({ name: "Edit appeal", onClick: () => onEditAppeal(appeal.appealId) }) 
    // }
    actions.push({ name: "View appeal", onClick: () => onViewAppeal(appeal) });    
    return actions;
  } 

  return (
    <div className="appeal-card">
      <SearchCard
        id={appeal.appealId}
        headerElement={
          <>
            <span className="qualification" aria-label="Qualification">
              <strong>{`${appeal.qualificationGroupId} 
                  ${appeal.qualificationName} 
                  ${appeal.qualificationCode}`}
              </strong>
            </span>
            <span className="appeal-type" aria-label="Appeal type">
              <strong>{`Appeal service ${appeal.appealTypeId}`}</strong>
            </span>
            <dl className="number-of-learners">
              <dt>Number of learners:</dt>
              <dd>{appeal.learnerCount}</dd>
            </dl>
            <dl className="status">
              <dt>Status:</dt>
              <dd><AppealStateDescription statusId={appeal.statusId}/></dd>
            </dl>
          </>
        }
        dropdownMenuRight={true}
        warningMessage={appeal.statusId === INCOMPLETE
          ? "This appeal is incomplete and cannot be processed."
          : null
        }
        actions={getActions()}
      />
      {showCancelAppealModal && (
        <CancelAppealModal
          show={true}
          onCancel={() => setShowCancelAppealModal(false)}
          onConfirm={() => onCancelAppeal(appeal)}
        />
      )}
    </div>
  );
};

AppealCard.defaultProps = {
  showQualification: false
}

export default AppealCard;
import * as React from "react";
import * as _ from "lodash";
import { Qualification } from "../../../models/qualification";
import SearchCard from "../../../components/pearson/search-card";
import { DropdownOption } from "../../../components/pearson/dropdown";

interface QualificationAppealCardProps {
  qualification: Qualification;
  internal: boolean;
  onCreateAppeal: (qualification: Qualification) => void;
  onViewAppeals: (qualification: Qualification) => void;
  onViewLearners: (qualification: Qualification) => void;
  onDownloadTemplate: (qualification: Qualification) => void;
  onViewFileHistory: (qualification: Qualification) => void;
}

export const QualificationAppealCard = ({
  qualification,
  internal,
  onCreateAppeal,
  onViewAppeals,
  onViewLearners,
  onDownloadTemplate,
  onViewFileHistory
}: QualificationAppealCardProps) => {
  const {
    name,
    code,
    numberOfLearners,
    numberOfAppeals,
    hasAppeals
  } = qualification;

  const getActions = () => {
    const actions: DropdownOption[] = [
      {
        name: "Create new appeal",
        onClick: () => onCreateAppeal(qualification)
      },
    ];
    if (qualification.hasAppeals) {
      actions.push({
        name: "View existing appeals",
        onClick: () => onViewAppeals(qualification)
      })
    };
    if(internal) {
      actions.push({
        name: "View learners",
        onClick: () => onViewLearners(qualification)
      })
    }    
    actions.push({
      name: "Download CAGs/ranks",
      onClick: () => onDownloadTemplate(qualification)
    })
    if(internal) {
      actions.push({
        name: "View file history", 
        onClick: () => onViewFileHistory(qualification)
      });
    }
    return actions;
  };

  return (
    <div className="qualification-appeal-card">
      <SearchCard
        id={code}
        headerElement={
          <>
            <span className="qualification" aria-label="Qualification name">
              <strong>{name}</strong>
            </span>
            <dl className="code">
              <dt>Code:</dt>
              <dd>{code}</dd>
            </dl>
            <dl className="number-of-learners">
              <dt>Graded learners:</dt>
              <dd>{numberOfLearners ?? 0}</dd>
            </dl>
            <dl className="number-of-appeals">
              <dt>Number of appeals:</dt>
              <dd>{numberOfAppeals ?? 0}</dd>
            </dl>
          </>
        }
        dropdownMenuRight={true}
        informationMessage={hasAppeals ? "Appeals" : null}
        actions={getActions()}
      />
    </div>
  );
};

export default QualificationAppealCard;

import * as React from "react";
import { RouteComponentProps, Redirect, withRouter } from "react-router-dom";
import _ from "lodash";
import config from "../../app.config";
import { QualificationSearchOption } from "../../types/qualification-search-option";
import { SortDirection } from "../../types/sort-direction";
import { Qualification } from "../../models/qualification";
import { QualificationGroup } from "../../models/qualification-group";
import { QualificationsPageState } from "../../models/page-state";
import { alert } from "../../components/pearson/alert";
import Button from "../../components/pearson/button";
import Loader from "../../components/pearson/loader";
import QualificationCardList from "./components/qualification-card-list";
import QualificationAppealCardList from "./components/qualification-appeal-card-list";


export interface StateProps {
  learningProviderId: string | null;
  qualificationGroup: QualificationGroup | undefined;
  internal: boolean;
  showAppeals: boolean;
  qualifications: Qualification[];
  searchOption: string;
  searchText: string;
  sortBy: string;
  sortDirection: SortDirection;
}

export interface DispatchProps {
  downloadCsvFile: (
    learningProviderId: string,
    qualification: Qualification
  ) => void;
  downloadZipFile: (
    learningProviderId: string,
    qualificationGroupId: string
  ) => void;
  getQualifications: (
    learningProviderId: string,
    qualificationGroupId: string
  ) => Promise<void>;
  setPageState: (
    pageState: QualificationsPageState,
  ) => void;
  setQualificationGroup: (
    qualificationGroupId: string | null
  ) => Promise<void>;
}

export type OwnProps = RouteComponentProps<{ qualificationGroupId: string }>;
interface Props extends DispatchProps, StateProps, OwnProps { }

export interface LocalState {
  loading: boolean;
}

export class Qualifications extends React.Component<Props, LocalState> {
  constructor(props: Props) {
    super(props);
    if (props.qualificationGroup) {
      document.title = props.qualificationGroup.id;
    }
    this.state = {loading: true };
  }

  render = () => {
    const {     
      learningProviderId,
      qualificationGroup,
      qualifications,
      internal,
      showAppeals,
      searchOption,
      searchText,
      sortBy,
      sortDirection
    } = this.props;
    const { 
      loading
    } = this.state;

    if (!learningProviderId || !qualificationGroup) {
      return <Redirect to="/" />;
    }
    
    return (
      <div className="page view-qualifications">
        <Loader
          loading={loading}
          loadingStateLabel="Loading qualifications..."
        />
        <div className="jumbotron header">
          <h1>
            {showAppeals
              ? `${qualificationGroup.id} Appeals`
              : `${qualificationGroup.id} Grades Capture`}
          </h1>
          {showAppeals
            ? <>
              <p>Create a new appeal or view existing appeals by accessing the relevant menu options against each subject below.</p>
              <p>For information about the type of appeals and the appeals process, check <a href={config.HELP_LINKS.APPEAL_PROCESS_GUIDANCE} target="_blank">the guidance</a>.
                </p>
              <p>You can still review your previously submitted Centre Assessed Grades and/or ranks in spreadsheet form by selecting the 'Download CAGs/ranks' option against the relevant subject.</p>
            </>
            : <p>Select a qualification below to view learners, download templates and upload assessment grades and rankings.</p>
          }
          {!loading && !showAppeals && (
            <div className="action-button">
              <Button
                label={`Download all templates`}
                className="primary"
                onClick={this.downloadAllTemplates}
              />
            </div>
          )}
        </div>
        {!loading && (
          showAppeals
            ? <QualificationAppealCardList
              qualifications={qualifications}
              internal={internal}
              searchOption={searchOption}
              searchText={searchText}
              sortBy={sortBy}
              sortDirection={sortDirection}
              onSearch={this.search}
              onSort={this.sort}
              onCreateAppeal={this.createAppeal}
              onViewAppeals={this.viewAppeals}
              onViewLearners={this.viewLearners}
              onDownloadTemplate={this.downloadTemplate}
              onViewFileHistory={this.viewFileHistory}
            />
            : <QualificationCardList
              qualifications={qualifications}
              internal={internal}
              searchOption={searchOption}
              searchText={searchText}
              sortBy={sortBy}
              sortDirection={sortDirection}
              onSearch={this.search}
              onSort={this.sort}
              onViewLearners={this.viewLearners}
              onDownloadTemplate={this.downloadTemplate}
              onViewFileHistory={this.viewFileHistory}
            />
        )}
      </div>
    );
  };

  componentDidMount = () => {
    this.getQualifications();
  };

  componentDidUpdate(prevProps: StateProps, prevState: LocalState) {
    const { qualificationGroup, setQualificationGroup } = this.props;
    if (prevProps && qualificationGroup && prevProps.qualificationGroup !== qualificationGroup) {
      setQualificationGroup(qualificationGroup.id).then(() =>
        this.getQualifications()
      );
    }
  }

  createAppeal = (qualification: Qualification) => {
    this.props.history.push(
      `/qualifications/${qualification.qualificationGroupId}/${qualification.id}/new-appeal`
    );
  };

  downloadTemplate = (qualification: Qualification) => {
    const { learningProviderId, downloadCsvFile } = this.props;
    if (learningProviderId) {
      downloadCsvFile(learningProviderId, qualification);
    }
  };

  downloadAllTemplates = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const { learningProviderId, qualificationGroup, downloadZipFile, } = this.props;
    if (learningProviderId && qualificationGroup) {
      downloadZipFile(learningProviderId, qualificationGroup.id);
    }
  };

  getQualifications = () => {
    const { learningProviderId, qualificationGroup } = this.props;
    if (learningProviderId && qualificationGroup) {
      this.setState({ loading: true });
      this.props
        .getQualifications(learningProviderId, qualificationGroup.id)
        .then(() => document.title = qualificationGroup.id)
        .catch((err) => alert.error(err))
        .finally(() => this.setState({ loading: false }));
    }
  };

  search = (searchOption: QualificationSearchOption, searchText: string) => {
    this.props.setPageState({
      searchOption,
      searchText,
      sortBy: this.props.sortBy,
      sortDirection: this.props.sortDirection
    })
  };

  sort = (sortBy: string, sortDirection: SortDirection) => {
    this.props.setPageState({
      searchOption: this.props.searchOption,
      searchText: this.props.searchText,
      sortBy,
      sortDirection
    })
  };

  viewAppeals = (qualification: Qualification) => {
    this.props.history.push(
      `/qualifications/${qualification.qualificationGroupId}/${qualification.id}/appeals`
    );
  };

  viewLearners = (qualification: Qualification) => {
    this.props.history.push(
      `/qualifications/${qualification.qualificationGroupId}/${qualification.id}/learners`
    );
  };

  viewFileHistory = (qualification: Qualification) => {
    this.props.history.push(
      `/file-history/${qualification.id}/qualifications`
    );
  };
}

export default withRouter(Qualifications);

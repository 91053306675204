import initialState from './initialState';
import { AppealManagementActions } from "../actions/appeal-management.actions";
import { AppealTemplate } from '../models/appeal-template';
import { 
 GET_APPEAL_TEMPLATES_SUCCESS
} from "../actions/action-types";

export function appealTemplatesReducer(
  state: AppealTemplate[] = initialState.appealTemplates,
  action: AppealManagementActions 
): AppealTemplate[] {

  switch (action.type) {
    case GET_APPEAL_TEMPLATES_SUCCESS:
      return [...action.appealTemplates];
    default:
      return state;
  }
};


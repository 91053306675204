import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { App, StateProps, DispatchProps } from './App';
import { StoreState } from '../store/store-state';
import { AppealActions, getAppealTypes } from "../actions/appeal.actions";
import { AppealManagementActions, getAppealTemplates, getTeams } from "../actions/appeal-management.actions";
import { AuthAction, signinUser, signoutUser } from '../actions/auth/auth.actions';
import { LearningProviderActions, setLearningProvider } from '../actions/learning-provider.actions';
import { QualificationGroupActions, getQualificationGroups } from "../actions/qualification-group.actions";
import { UserActions, getUser, getInternalUserAndLearningProvider } from '../actions/user.actions';

const mapStateToProps = (state: StoreState): StateProps => ({
  isAuthenticated: state.auth.authenticated,
  profile: state.auth.profile,
  qualificationGroups: state.qualificationGroups,
  learningProviderId: state.selectedLearningProviderId,
  user: state.user,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    StoreState,
    void,
    AppealActions |
    AppealManagementActions |
    AuthAction | 
    LearningProviderActions | 
    QualificationGroupActions | 
    UserActions
  >
): DispatchProps => ({
  getAppealTemplates: () => dispatch(getAppealTemplates()),
  getAppealTypes: () => dispatch(getAppealTypes()),
  getQualificationGroups: (learningProviderId: string) => dispatch(getQualificationGroups(learningProviderId)),
  getTeams: () => dispatch(getTeams()),
  getUser: (
    forgeRockId: string
  ) => dispatch(getUser(forgeRockId)),
  getInternalUserLearningProvider: (
    learningProviderCode: string
  ) => dispatch(getInternalUserAndLearningProvider(learningProviderCode)),
  setLearningProvider: (
    learningProviderId: string | null
  ) => dispatch(setLearningProvider(learningProviderId)),
  signinUser: () => signinUser(dispatch),
  signoutUser: () => signoutUser(dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);

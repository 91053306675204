import { Reducer } from "redux";
import { UserActions } from "../actions/user.actions";
import { User } from "../models/user";
import initialState from "./initialState";
import { GET_USER_SUCCESS, GET_INTERNAL_USER_LEARNING_PROVIDER_SUCCESS } from "../actions/action-types";

export const userReducer: Reducer<User | null> = (
  state: User | null = initialState.user,
  action: UserActions
) => {
  switch (action.type) {
    case GET_USER_SUCCESS:
    case GET_INTERNAL_USER_LEARNING_PROVIDER_SUCCESS:
      return { ...action.user }
    default:
      return state;
}}
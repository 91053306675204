import * as React from 'react';
import * as _ from 'lodash';
import { Option } from "../../models/option";
import Icon from './icon';
import Select from './select';
import { FormEvent } from 'react';
import { SortDirection } from '../../types/sort-direction';

import "./sort.scss";

interface SortProps {
  id: string;
  sortBy: string;
  sortDirection: SortDirection;
  disabled: boolean;
  options: Option[],
  onChange: (sortBy: string, sortDirection: string) => void;
}

const Sort = ({
  id,
  sortBy,
  sortDirection,
  disabled,
  options,
  onChange
}: SortProps) => {

  const sortByChange = (event: FormEvent<HTMLSelectElement>) => {
    event.preventDefault();
    onChange((event.target as HTMLSelectElement).value, sortDirection)
  }

  const sortDirectionChange = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();   
    onChange(sortBy, sortDirection === "asc" ? "desc" : "asc")
  }

  return (
    <div className="sort">
      <label className="gr-meta" htmlFor={id}>Sort by:</label>
      <div className="search-container">
        <Select
          id={id}
          placeholder="Sort by"
          options={options}
          value={sortBy}
          disabled={disabled}
          onChange={sortByChange}
        />
        <div className="btn-container">
          <button
            className="search-btn gr-secondary-bg"
            disabled={disabled}
            data-tooltip={sortDirection === "asc"
              ? "Sorted in ascending order"
              : "Sorted in descending order"}
            onClick={sortDirectionChange}>
            <Icon icon={sortDirection === "asc"
              ? "descending"
              : "ascending"}  size="18" />
          </button>
        </div>
      </div>
    </div>
  )
};

Sort.defaultProps = {
  id: "sortBy",
  disabled: false,
  sortDirection: "asc"
}

export default Sort;
import * as React from "react";
import { FormEvent } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "../../../../components/pearson/button";
import TeamSelect from "../selects/team-select";
import { Team } from "../../../../models/team";

interface TransferTeamModalProps {
  show: boolean;
  teams: Team[];
  onCancel: () => void;
  onTransfer: (teamId: number) => void;
}

const TransferTeamModal = ({
  show,
  teams,
  onCancel,
  onTransfer,
}: TransferTeamModalProps) => {

  const [teamId, setTeamId] = React.useState<number|null>(null);

  const onFormSubmit = (
    event:
      FormEvent<HTMLFormElement> |
      React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    event.preventDefault();
    if(teamId) {
      onTransfer(teamId);
    }   
  };

  return (
    <div className="transfer-team-modal">
      <Modal
        show={show}
        onHide={onCancel}
        aria-labelledby="Transfer team">
        <Modal.Header closeButton>
          <Modal.Title>Select team</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={onFormSubmit}>
            <TeamSelect
              teamId={teamId}
              teams={teams}
              onChange={(teamId) => setTeamId(teamId)}
            />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            label="Transfer"
            type="submit"
            disabled={!teamId}
            className="primary"
            onClick={onFormSubmit}
          />         
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TransferTeamModal;
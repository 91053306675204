import initialState from './initialState';
import { AppealManagementActions } from "../actions/appeal-management.actions"
import { Team } from "../models/team"
import {
  GET_TEAMS_SUCCESS
} from "../actions/action-types";

export function teamsReducer(
  state: Team[] = initialState.teams,
  action: AppealManagementActions
): Team[] {

  switch (action.type) {
    case GET_TEAMS_SUCCESS:
      return [...action.teams];
    default:
      return state;
  }
};

import * as React from "react";
import { useEffect } from "react";
import _ from "lodash";

interface TabNavigationProps {
  theme: "light" | "dark";
  tabs: string[];
  panels: JSX.Element[];
  activeIdx?: number;
  onTabChange?: (event: any) => void;
}

export const Tabs = ({
  activeIdx,
  theme,
  tabs,
  panels,
  onTabChange
}: TabNavigationProps) => {

  const tabsRef: any = React.useRef();

  useEffect(() => {    
    onTabChange && tabsRef.current.addEventListener('tabChange', (event: any) => {
      const activeIdx = event.currentTarget.attributes["activeIdx"].value;
      onTabChange!(activeIdx ? parseInt(activeIdx) : undefined);
    });  
  }, []);  

  return (
    <pearson-tabs ref={tabsRef} activeIdx={activeIdx} theme={theme} >
      <ul className="tabs" slot="tabs">
        {_.map(tabs, (tab: string, index: number) => (
          <li key={index} tabIndex={index}>{tab}</li>
        ))}
      </ul>
      <div className="panels" slot="panels">
        {_.map(panels, (panel: JSX.Element, index: number) => (
          <div key={index} data-panel>
            {panel}
          </div>
        ))}
      </div>
    </pearson-tabs>
  );
};

Tabs.defaultProps = {
  theme: "light",
  activeIdx: 0,
};

export default Tabs;
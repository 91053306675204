import * as React from "react";
import * as _ from "lodash";
import moment from "moment";
import SearchCard from "../../../components/pearson/search-card";
import { AppealNote } from "models/appeal-note";

interface TeamAppealNoteCardProps {
  id: string;
  appealNote: AppealNote;
}

export const TeamAppealNoteCard = ({
  id,
  appealNote
}: TeamAppealNoteCardProps) => {

  return (
    <div className="team-appeal-note-card">
      <SearchCard
        id={id}
        headerElement={
          <>
            <dl className="user">
              <dt>User:</dt>
              <dd>{appealNote.username}</dd>
            </dl>         
            <dl className="date">
              <dt>Date:</dt>
              <dd>{moment(appealNote.addedDateTime).format("DD/MM/YYYY HH:mm")}</dd>
            </dl>
            <dl className="note">
              <dt>Note:</dt>
              <dd style={{ whiteSpace: "pre-line" }}>{appealNote.text}</dd>
            </dl>
          </>
        }
        dropdownMenuRight={true}
      />
    </div>
  );
};

export default TeamAppealNoteCard;
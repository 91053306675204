import axios, { AxiosRequestConfig } from "axios";
import { ThunkDispatch } from "redux-thunk";
import { StoreState } from "../store/store-state";
import config from "../app.config";
import { CsvUploadFile } from "../models/csv-upload-file";
import { CsvUploadStatus } from "../models/csv-upload-status";
import { Action } from "redux";
import * as action_types from "./action-types";
import { Qualification } from "../models/qualification";
import AuthForgeRock from "../actions/auth/auth";

const authForgeRock = new AuthForgeRock();

interface CsvUploadInProgressAction extends Action {
    type: action_types.CSV_UPLOAD_IN_PROGRESS;
    csvUploadStatus: CsvUploadStatus
}

interface CsvUploadedAction extends Action {
    type: action_types.CSV_UPLOADED;
    csvUploadStatus: CsvUploadStatus
}

interface CsvUploadFailedAction extends Action {
    type: action_types.CSV_UPLOAD_FAILED;
    csvUploadStatus: CsvUploadStatus
}

interface CsvUploadIncorrectFileTypeAction extends Action {
    type: action_types.CSV_UPLOAD_INCORRECT_FILE_TYPE;
    csvUploadStatus: CsvUploadStatus
}

export type CsvActions = CsvUploadInProgressAction | CsvUploadedAction | CsvUploadFailedAction | CsvUploadIncorrectFileTypeAction;

export const csvUploadInProgressAction = (
    csvUploadStatus: CsvUploadStatus
): CsvUploadInProgressAction => ({
    type: action_types.CSV_UPLOAD_IN_PROGRESS,
    csvUploadStatus
});

export const csvUploadedAction = (
    csvUploadStatus: CsvUploadStatus
): CsvUploadedAction => ({
    type: action_types.CSV_UPLOADED,
    csvUploadStatus
});

export const csvUploadFailedAction = (
    csvUploadStatus: CsvUploadStatus
): CsvUploadFailedAction => ({
    type: action_types.CSV_UPLOAD_FAILED,
    csvUploadStatus
});

export const csvUploadIncorrectFileTypeAction = (
    csvUploadStatus: CsvUploadStatus
): CsvUploadIncorrectFileTypeAction => ({
    type: action_types.CSV_UPLOAD_INCORRECT_FILE_TYPE,
    csvUploadStatus
});

export const uploadCsv = (csvUploadFile: CsvUploadFile) => {
  return (dispatch: ThunkDispatch<StoreState, void, CsvActions>) => {

    if (csvUploadFile.file.name.split('.').pop()?.toLowerCase() !== 'csv') {

        dispatch(csvUploadIncorrectFileTypeAction({ 
            learningProviderId: csvUploadFile.learningProviderId,
            qualificationId: csvUploadFile.qualificationId,
            fileName: csvUploadFile.file.name,
            status: 'IncorrectFileType'
        }));
        
        return Promise.resolve();
    }

    dispatch(csvUploadInProgressAction({ 
        learningProviderId: csvUploadFile.learningProviderId,
        qualificationId: csvUploadFile.qualificationId,
        fileName: csvUploadFile.file.name,
        status: 'InProgress'
    }));

    const axiosConfig: AxiosRequestConfig = {
      headers: { "content-type": "application/json" }
    };
    return axios
      .post(
        `${config.API_GATEWAY.URL}/spreadsheet/${csvUploadFile.learningProviderId}/grades/${csvUploadFile.qualificationId}/uploadurl`,
        JSON.stringify(csvUploadFile.file.name),
        axiosConfig
      )
      .then(response => {

          var presignedUrl = response.data;

          var profile = authForgeRock.getProfile();

          const xhr = new XMLHttpRequest();        
          xhr.open("PUT", presignedUrl, true);
          xhr.setRequestHeader('x-amz-meta-learning-provider-id', csvUploadFile.learningProviderId)
          xhr.setRequestHeader('x-amz-meta-qualification-id', csvUploadFile.qualificationId)
          xhr.setRequestHeader('x-amz-meta-original-file-name', csvUploadFile.file.name)
          xhr.setRequestHeader('x-amz-meta-user-name', profile.name)
          xhr.setRequestHeader('x-amz-meta-pearson-user-id', profile.pearsonUID)

          xhr.send(csvUploadFile.file);
          xhr.onload = function() {
            if(this.status === 200) {

                dispatch(csvUploadedAction({ 
                    learningProviderId: csvUploadFile.learningProviderId,
                    qualificationId: csvUploadFile.qualificationId,
                    fileName: csvUploadFile.file.name,
                    status: 'Success'
                }));
            }
            else {

                dispatch(csvUploadFailedAction({ 
                    learningProviderId: csvUploadFile.learningProviderId,
                    qualificationId: csvUploadFile.qualificationId,
                    fileName: csvUploadFile.file.name,
                    status: 'Failed'
                }));
            }
            xhr.onerror = function() {
              dispatch(csvUploadFailedAction({ 
                  learningProviderId: csvUploadFile.learningProviderId,
                  qualificationId: csvUploadFile.qualificationId,
                  fileName: csvUploadFile.file.name,
                  status: 'Failed'
              }));
            }
          };
      })
      .catch(error => {
        dispatch(csvUploadFailedAction({ 
            learningProviderId: csvUploadFile.learningProviderId,
            qualificationId: csvUploadFile.qualificationId,
            fileName: csvUploadFile.file.name,
            status: 'Failed'
        }));
      });
    };  
};

export const downloadCsv = (learningProviderId: string, qualification: Qualification) => {
    const axiosConfig: AxiosRequestConfig = {
        responseType: 'blob',
     };
    axios
      .get(
        `${config.API_GATEWAY.URL}/spreadsheet/${learningProviderId}/grades/${qualification.id}/csv`,
        axiosConfig
      )
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
        const link = document.createElement('a');
        link.href = url;
        let contentDisposition = response.headers["content-disposition"];
        let match = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/i);
        let filename = match[0].replace('filename=', '');
        filename = filename.replace(/\"/g,'');
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      });
};

export const downloadZip = (learningProviderId: string, qualificationGroupId: string) => {
    const axiosConfig: AxiosRequestConfig = {
        responseType: 'arraybuffer',
        headers: { 'content-type': 'multipart/form-data' }
     };
    axios
      .get(
        `${config.API_GATEWAY.URL}/spreadsheet/${learningProviderId}/grades/${qualificationGroupId}/zip`,
        axiosConfig
      )
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
        const link = document.createElement('a');
        link.href = url;
        let contentDisposition = response.headers["content-disposition"];
        let match = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/i);
        let filename = match[0].replace('filename=', '');
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      });
};

export const downloadArchiveCsv = (learningProviderId: string, qualificationId: string, versionId: string, fileName: string) => {
    axios
      .get(
        `${config.API_GATEWAY.URL}/spreadsheet/${learningProviderId}/grades/${qualificationId}/downloadurl/${versionId}`
      )
      .then(response => {
        const url = response.data;
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    })
    .catch(error => {
        console.log(error);
    });
};




import axios from 'axios';
import { Action } from 'redux';
import { ThunkDispatch, ThunkAction } from 'redux-thunk';
import {ajaxCallError, beginAjaxCall, AjaxCallActions} from './ajax-status.actions';
import { StoreState } from '../store/store-state';
import config from '../app.config';
import _ from 'lodash';
import {QualificationGroup} from "../models/qualification-group"
import { 
  GET_QUALIFICATION_GROUPS_SUCCESS, 
  SET_QUALIFICATION_GROUP 
} from './action-types';

interface SetQualificationGroupAction extends Action {
  type: SET_QUALIFICATION_GROUP;
  qualificationGroupId: string | null
}

type ThunkResult<R> = ThunkAction<R, StoreState, undefined, QualificationGroupActions>;

export const setQualificationGroup = (qualificationGroupId: string | null)
  : ThunkResult<Promise<void>> => {
  return (dispatch) => {
    dispatch({
      type: SET_QUALIFICATION_GROUP,
      qualificationGroupId
    });
    return Promise.resolve();
  }
}

interface GetQualificationGroupsSuccessAction extends Action {
  type: GET_QUALIFICATION_GROUPS_SUCCESS;
  qualificationGroups: QualificationGroup[];
}

export type getQualificationGroupsSuccessAction =
  | GetQualificationGroupsSuccessAction
  | AjaxCallActions;

export const getQualificationGroupsSuccessAction = (
  qualificationGroups: QualificationGroup[]
): GetQualificationGroupsSuccessAction => ({
    qualificationGroups,
  type: GET_QUALIFICATION_GROUPS_SUCCESS
});

export const getQualificationGroups = (learningProviderId: string) => {  
  return (dispatch: ThunkDispatch<StoreState, void, getQualificationGroupsSuccessAction>) => {    
    dispatch(beginAjaxCall());
    return axios
      .get(`${config.API_GATEWAY.URL}/qualificationgroup/${learningProviderId}`)
      .then(response => {
        const qualificationGroups = response.data;
        dispatch(
          getQualificationGroupsSuccessAction(            
            _.map(_.sortBy(qualificationGroups, "id"), (qualificationGroup: QualificationGroup) => { 
              const qg = new QualificationGroup();
              qg.id = qualificationGroup.id;
              return qg;
            })
          )
        );
      })
      .catch(error => {
        dispatch(ajaxCallError(error));
        throw error;
      });
  };
};

export type QualificationGroupActions =
  GetQualificationGroupsSuccessAction |
  SetQualificationGroupAction
import * as React from "react";
import { RouteComponentProps, Redirect } from "react-router";
import _ from "lodash";
import { AppealDetail } from "../../../models/appeal-detail";
import { AppealFileUpload } from "../../../models/appeal-file-upload";
import { AppealLearner } from "../../../models/appeal-learner";
import { AppealSubmit } from "../../../models/appeal-submit";
import { AppealType } from "../../../models/appeal-type";
import { Qualification } from "../../../models/qualification";
import { alert } from "../../../components/pearson/alert";
import AppealForm from "../components/appeal-form";
import Loader from "../../../components/pearson/loader";

export interface StateProps {
  learningProviderId: string | null;
  qualification: Qualification | undefined;
  appeal: AppealDetail | null;
  appealTypes: AppealType[];
}

export interface DispatchProps {
  acknowledgeAppeal: (
    learningProviderId: string,
    appealId: string
  ) => Promise<void>;
  downloadAppealEvidence: (
    learningProviderId: string,
    appealId: string,
    fileId: string) => void;
  getAppealLearners: (
    learningProviderId: string,
    qualificationId: string
  ) => Promise<AppealLearner[]>;
  saveAppeal: (
    learningProviderId: string,
    qualificationId: string,
    appeal: AppealSubmit
  ) => Promise<void>;
  uploadAppealFile: (
    appealFileUpload: AppealFileUpload
  ) => Promise<void>;
};

export type OwnProps = RouteComponentProps<{ qualificationId: string }>;
interface Props extends DispatchProps, StateProps, OwnProps { }

export interface LocalState {
  availableAppealTypes: AppealType[];
  from: string;
  backToQualifications: boolean;
  learners: AppealLearner[];
  loading: boolean;
  newAppeal: AppealSubmit;
}

export class CreateAppealPage extends React.Component<Props, LocalState> {
  constructor(props: Props) {
    super(props);
    document.title = props.qualification?.displayName ?? "";
    this.state = {
      availableAppealTypes: _.filter(props.appealTypes, "isAvailable"),
      from: "",
      backToQualifications: props.location.pathname.endsWith('new-appeal'),
      loading: false,
      newAppeal: new AppealSubmit(),
      learners: []
    };
  }

  render = () => {
    const { learningProviderId, qualification, appeal, acknowledgeAppeal, saveAppeal, history} = this.props;
    const { availableAppealTypes, loading, backToQualifications, learners, newAppeal} = this.state;

    if (!learningProviderId || !qualification) {
      return <Redirect to="/" />
    }

    const backLinkName = backToQualifications
      ? `Back to ${qualification.qualificationGroupId}`
      : `Back to ${qualification.qualificationGroupId} appeals`

    const backToPath = backToQualifications
      ? `/qualifications/${qualification.qualificationGroupId}`
      : `/qualifications/${qualification.qualificationGroupId}/${qualification.id}/appeals`


    return (
      <div className="page create-appeal">
        <Loader loading={loading} loadingStateLabel="Loading..." />
        {!loading && (
          <AppealForm
            appealFormMode="New"
            appeal={newAppeal}
            appealEvidence={appeal?.evidence}
            appealTypes={availableAppealTypes}
            backLinkName={backLinkName}
            backToPath={backToPath}
            learners={learners}
            learningProviderId={learningProviderId}
            qualificationDisplayName={qualification.displayName}            
            onCancel={() => history.push(backToPath)}
            onChange={(newAppeal) => this.setState({ newAppeal })}
            onDownloadAppealEvidence={this.props.downloadAppealEvidence}
            onUploadAppealFile={this.props.uploadAppealFile}
            onAcknowledgeAppeal={() => {
              return acknowledgeAppeal(learningProviderId, newAppeal.appealId);
            }}
            onSaveAppeal={() => { 
              return saveAppeal(learningProviderId, qualification.id, newAppeal);
            }}           
          />
        )}
      </div >
    )
  };

  componentDidMount = () => {
    const { learningProviderId, qualification, getAppealLearners } = this.props;
    if (learningProviderId && qualification) {
      this.setState({ loading: true });
      getAppealLearners(learningProviderId, qualification.id)
      .then((learners) => {
        this.setState({loading: false, learners})
      })
      .catch((error) => {
        alert.error(error)
      })
      .finally(() => {
        this.setState({loading: false})
      });
    }
  };
  
  componentDidUpdate = (prevProps: StateProps, prevState: LocalState) => {
    const { learningProviderId, qualification, appeal } = this.props;
    if (learningProviderId && qualification && appeal) {
      if (!_.isEqual(prevProps.appeal, appeal)) {     
        const newAppeal = new AppealSubmit();
        newAppeal.appealId = appeal.appealId;
        newAppeal.appealTypeId = appeal.appealTypeId;
        newAppeal.hoC = appeal.hoC;
        newAppeal.learnerConsent = appeal.learnerConsent;        
        newAppeal.rationale = appeal.rationale;
        newAppeal.tandC = appeal.tandC;
        newAppeal.ucasLearners = appeal.ucasLearners;
        newAppeal.learnerIdentifiers = _.map(_.filter(appeal.learners, ["isInAppeal", true]),
          (learner: AppealLearner) => learner.learnerIdentifier
        );
        this.setState({ newAppeal })
      }
    }
  };
}

export default CreateAppealPage;
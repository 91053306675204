import * as React from 'react';

interface IconProps {
  icon: string;
  size: "18" | "24";
  fill: string;
}

export const Icon = ({ icon, size, fill }: IconProps) => (
  <svg
    focusable="false"
    className={`icon-${size} ${fill}`}
    aria-hidden="true">
    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={`#${icon}-${size}`} />
  </svg>
);         

Icon.defaultProps = {
  fill: "",
};

export default Icon;
import initialState from './initialState';
import { AppealFileActions } from "../actions/appeal-upload.actions";
import { AppealFileUploadStatus } from "models/appeal-file-upload-status";
import { APPEAL_FILE_UPLOAD_IN_PROGRESS, APPEAL_FILE_UPLOADED, APPEAL_FILE_UPLOAD_FAILED, APPEAL_FILE_UPLOAD_INCORRECT_FILE_TYPE } from "../actions/action-types";

export function appealFileUploadReducer(
  state: AppealFileUploadStatus | null = initialState.appealFileUploadStatus,
  action: AppealFileActions
): AppealFileUploadStatus | null {
  switch (action.type) {
    case APPEAL_FILE_UPLOAD_IN_PROGRESS:
    case APPEAL_FILE_UPLOADED:
    case APPEAL_FILE_UPLOAD_FAILED:
    case APPEAL_FILE_UPLOAD_INCORRECT_FILE_TYPE:
      return { ...action.appealFileUploadStatus }
    default:
      return state;
  }
};


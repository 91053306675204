import * as React from 'react';

export interface StateProps {
}

interface Props extends StateProps { }

export class NotFoundPage extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
    document.title = "Page not found";
  }
  render = () => {
    return (
      <div className="maintenance-page">
        <div className="jumbotron">
          <h1>{document.title}</h1>
        </div>
      </div>
    );
  };
}

export default NotFoundPage;

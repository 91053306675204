import * as React from 'react';
import { useEffect, useState } from 'react';
import * as _ from 'lodash';
import { Qualification } from '../../../models/qualification';
import { QualificationSearchOptions, QualificationSortByOptions } from "../../../models/option";
import { QualificationSearchOption } from '../../../types/qualification-search-option';
import { SortDirection } from '../../../types/sort-direction';
import SearchBar from '../../../components/pearson/search-bar';
import Sort from '../../../components/pearson/sort';
import QualificationCard from './qualification-card';


interface QualificationCardListProps {
  qualifications: Qualification[];
  internal: boolean;
  searchOption: string;
  searchText: string;
  sortBy: string;
  sortDirection: SortDirection; 
  onDownloadTemplate: (qualification: Qualification) => void;
  onSearch: (searchOption: QualificationSearchOption, searchText: string) => void;
  onSort: (sortBy: string, sortDirection: string) => void;
  onViewLearners: (qualification: Qualification) => void;
  onViewFileHistory: (qualification: Qualification) => void;
}

export const QualificationCardList = ({
  qualifications,
  internal,
  searchOption,
  searchText,
  sortBy,
  sortDirection,
  onDownloadTemplate,
  onSearch,
  onSort,
  onViewLearners,
  onViewFileHistory
}: QualificationCardListProps) => {
   
  const [qualificationsFiltered, setQualificationsFiltered] = useState([...qualifications]);  
    
  useEffect(() => {    
    const filtered = (searchOption === "All" && searchText === "")
      ? qualifications
      : _.filter(qualifications, (qualification: Qualification) => {
        return (searchOption == "All" || qualification.statusCode === searchOption) &&
            qualification.searchString
              .toLowerCase()
              .includes(searchText.toLowerCase());
        });    
    setQualificationsFiltered(
      _.orderBy(filtered, sortBy.split(','), [sortDirection])
    )
  }, [searchOption, searchText, sortBy, sortDirection, qualifications]);

  return (
   <div className="qualification-card-list">
      <div className="search-sort-container">
        <SearchBar
          searchOption={searchOption}
          searchText={searchText}
          options={QualificationSearchOptions}
          onSearch={(searchOption, searchText) => {
            onSearch(searchOption as QualificationSearchOption, searchText)
          }}
        />
        <Sort
          sortBy={sortBy}
          sortDirection={sortDirection}
          options={QualificationSortByOptions}
          onChange={(sortBy, sortDirection) => {
            onSort(sortBy, sortDirection)
          }}
        />
     </div>
      <div className="qualifications">
        {_.map(qualificationsFiltered, (qualification, index) => (
            <QualificationCard
              key={index}
              qualification={qualification}
              internal={internal}
              onDownloadTemplate={onDownloadTemplate}
              onViewLearners={onViewLearners}
              onViewFileHistory={onViewFileHistory}
            />
          )
        )}
      </div>
   </div>  
  );
};

export default QualificationCardList
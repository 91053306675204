import * as React from "react";
import _ from "lodash";
import { AppealLearner } from "../../../models/appeal-learner";
import { Option } from "../../../models/option";
import { useState, useEffect } from "react";
import { SortDirection } from "../../../types/sort-direction";
import SearchBar from "../../../components/pearson/search-bar";
import { LearnerAppealSearchOption } from "../../../types/learner-appeal-search-option";
import { AlertMessage } from "../../../components/pearson/alert-message";
import Checkbox from "../../../components/pearson/checkbox";
import AppealLearnerCard from "./appeal-learner-card";
import Sort from "../../../components/pearson/sort";

interface AppealLearnerCardListProps {
  mode: "New" | "Complete" | "Edit" | "View";
  appealTypeId: number;
  learners: AppealLearner[];
  selectedLearnerIdentifiers: string[];
  onChange?: (learnerIdentifiers: string[]) => void;
}

export const AppealLearnerCardList = ({
  mode,
  appealTypeId,
  learners,
  selectedLearnerIdentifiers,
  onChange
}: AppealLearnerCardListProps) => {  

  const learnerIdentifierName = learners[0]?.learnerIdentifierName ?? "";

  const [searchOption, setSearchOption] = useState("All");
  const [searchText, setSearchText] = useState("");
  const [sortBy, setSortBy] = useState("name");
  const [sortDirection, setSortDirection] = useState("asc" as SortDirection);
  const [filteredLearners, setFilteredLearners] = useState([...learners]);

  const sortOptions: Option[] = [
    { name: "Forenames", value: "forenames" },
    { name: "Surname", value: "surname" },
    { name: "DOB", value: "dateOfBirth" },
    { name: learnerIdentifierName, value: "learnerIdentifier" },
  ];

  useEffect(() => {
    const filteredLearners = _.filter(learners, (learner: AppealLearner) => {
      return searchText === "" || learner.searchString.toLowerCase().includes(searchText.toLowerCase());
    });
    setFilteredLearners(_.orderBy(filteredLearners, sortBy.split(','), [sortDirection]));
  }, [searchOption, searchText, sortBy, sortDirection, learners]);

  const onSearch = (searchOption: LearnerAppealSearchOption, searchText: string) => {
    setSearchOption(searchOption);
    setSearchText(searchText);
  }

  const onSort = (sortBy: string, sortDirection: SortDirection) => {
    setSortBy(sortBy);
    setSortDirection(sortDirection);
  }

  const [selectAll, setSelectAll] = useState(false);
  const [availableLearnerCount] = useState(
    _.filter(learners, ["allowAppeal", true]).length
  );

  useEffect(() => {
    setSelectAll(selectedLearnerIdentifiers.length === availableLearnerCount);
  }, [selectedLearnerIdentifiers]);  
  
  const onSelect = (learnerIdentifier: string, selected: boolean) => {
    onChange!(selected
      ? [...selectedLearnerIdentifiers, learnerIdentifier]
      : _.without(selectedLearnerIdentifiers, learnerIdentifier)
    )
  }

  const onSelectAll = (selectAll: boolean) => {
    onChange!(selectAll
      ? _.map(_.filter(learners, ["allowAppeal", true]), "learnerIdentifier")
      : []
    )
  }

  return (
    <div className="appeal-learner-card-list">
      {mode === "New" && availableLearnerCount === 0 && (
        <AlertMessage
          type="warning"
          message={`There are no available learners to add to an appeal service type ${appealTypeId}. For more information, hover over the warning icon against the learners.`}
        />
      )}      
      {mode !== "View" && availableLearnerCount > 0 && (
        <span className="select-all-learners">
          <Checkbox
            id="selectAllCheckbox"
            label={<label className="gr-label">Select all learners</label>}
            checked={selectAll}
            onChange={(e) => onSelectAll(e.currentTarget.checked)}
          />
        </span>
      )}   
      {mode !== "View" && (
        <div className="search-sort-container">
          <SearchBar
            searchText={searchText}
            onSearch={(searchOption, searchText) => onSearch!(searchOption as LearnerAppealSearchOption, searchText)}
          />
          <Sort
            sortBy={sortBy}
            sortDirection={sortDirection}
            options={sortOptions}
            onChange={(sortBy, sortDirection) => onSort(sortBy, sortDirection as SortDirection)}
          />
        </div>
      )}  
              
      {_.map(filteredLearners, (learner: AppealLearner, index: number) => (
        <AppealLearnerCard
          key={index}
          appealTypeId={appealTypeId}
          learner={learner}
          readOnly={mode === "View"}
          selected={selectedLearnerIdentifiers.includes(learner.learnerIdentifier)}
          onSelect={onSelect}
        />
      ))}
    </div>
  )
};

AppealLearnerCardList.defaultProps = {
  readOnly: false,
  selectedLearnerIdentifiers: [],
  showSearch: true
}

export default AppealLearnerCardList;

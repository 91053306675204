import * as React from 'react';
import SearchCard from '../../../components/pearson/search-card';
import { UploadFileHistory } from '../../../models/upload-file-history';
import _ from 'lodash';
import moment from "moment";

interface FileCardProps {
  uploadFileHistory: UploadFileHistory;
  onDownload: (versionId: string, fileName: string) => void;
}

export const FileCard = ({
  uploadFileHistory,
  onDownload
}: FileCardProps) => {
  
  const {
    historyId,
    fileName,
    uploadedDate,
    uploadedBy,
    processed,
    warning,
    status,
    hasWarnings,
    hasErrors,
    isProcessed,
    latest,
    uploadType,
    versionId,
  } = uploadFileHistory;

  return (
    <div className="file-card">
      <SearchCard
        id={historyId}
        headerElement={
          <>
            <span className="file-name" aria-label="File name">
              <strong>{fileName}{latest && ' *LATEST*'}</strong>
            </span>
            <dl className="uploaded-date">
              <dt>Uploaded:</dt>
              <dd>
                {uploadedDate
                  ? moment(uploadedDate).format("DD/MM/YYYY HH:mm")
                  : ""}
              </dd>
            </dl>
            <dl className="uploaded-by-user">
              <dt>By user:</dt>
              <dd>{uploadedBy}</dd>
            </dl>
            <dl className="procesed">
              <dt>Processed:</dt>
              <dd>{processed}</dd>
            </dl>
            <dl className="warnings">
              <dt>Warnings/Errors:</dt>
              <dd>{warning}</dd>
            </dl>
            <dl className="upload-type">
              <dt>Type:</dt>
              <dd>{uploadType}</dd>
            </dl>      
          </>
        }
        errorMessage={hasErrors ? status : null}
        warningMessage={hasWarnings ? status : null}
        successMessage={isProcessed ? status : null}
        actions={[
          {
            name: "Download csv",
            onClick: () => onDownload(versionId, fileName),
          }
        ]}
      />
    </div>
  );}

export default FileCard
import config from "../../app.config";

export const params = {
  providerID: "PearsonForgeRock",
  redirect_uri: config.FORGEROCK.REDIRECT_URI,
  authorization: config.FORGEROCK.AUTHORIZE_ENDPOINT,
  sessionEndEndpoint: config.FORGEROCK.SESSION_END_ENDPOINT,
  client_id: config.FORGEROCK.CLIENT_ID,
  scopes: {
    request: config.FORGEROCK.SCOPES,
  },
};

import axios from 'axios';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { ajaxCallError, beginAjaxCall, AjaxCallActions } from './ajax-status.actions';
import { StoreState } from '../store/store-state';
import config from '../app.config';
import _ from 'lodash';
import { GET_LEARNER_GRADES_SUCCESS, SAVE_LEARNER_GRADES_SUCCESS } from './action-types';
import { LearnerGrade } from '../models/learner-grade';
import moment from 'moment';
import { Gradeset } from '../models/gradeset';
import { LearnerGradeSubmitDto } from '../models/learner-grade-submit-dto';
import { Qualification } from '../models/qualification';
import { CsvActions, csvUploadedAction, csvUploadInProgressAction, csvUploadFailedAction } from './csv.actions';

interface GetLearnerGradesSuccessAction extends Action {
  type: GET_LEARNER_GRADES_SUCCESS;
  learners: LearnerGrade[];
}
interface SaveLearnerGradesSuccessAction extends Action {
  type: SAVE_LEARNER_GRADES_SUCCESS;
  learners: LearnerGrade[];
}

export type getLearnerGradesSuccessAction =
  GetLearnerGradesSuccessAction |
  AjaxCallActions;
export type saveLearnerGradesSuccessAction =
  SaveLearnerGradesSuccessAction |
  AjaxCallActions |
  CsvActions;

export const getLearnerGradesSuccessAction = (
  learners: LearnerGrade[]
): GetLearnerGradesSuccessAction => ({
  learners,
  type: GET_LEARNER_GRADES_SUCCESS
});
export const saveLearnerGradesSuccessActions = (
  learners: LearnerGrade[]
): SaveLearnerGradesSuccessAction => ({
  learners,
  type: SAVE_LEARNER_GRADES_SUCCESS
});

export const getLearnerGrades = (
  learningProviderId: string, 
  qualification: Qualification) => {
  return (dispatch: ThunkDispatch<StoreState, void, getLearnerGradesSuccessAction>) => {
    dispatch(beginAjaxCall());
    return getGradesets(qualification.id)
      .then((gradesets) => {
        return axios
          .get(`${config.API_GATEWAY.URL}/learningprovider/${learningProviderId}/grades/${qualification.id}`)
          .then(response => {
            const learners = response.data.learnerGrades;
            mapLearnerGrades(qualification.id, learners, qualification.gradable, qualification.rankable)
              .then((mappedLearners) => {
                dispatch(getLearnerGradesSuccessAction(
                  _.orderBy(mappedLearners, ["forenames", "surname"])
                ))
              })
          })
          .catch(error => {
            dispatch(ajaxCallError(error));
            throw error;
          });
      })
  };
};

export const saveLearnerGrades = (
  learningProviderId: string,
  qualificationId: string,
  learners: LearnerGrade[]) => {
  const learnersSubmit: LearnerGradeSubmitDto[] = _.map(learners, (learner) => {
    const l = new LearnerGradeSubmitDto();
    l.Forenames = learner.forenames;
    l.Surname = learner.surname;
    l.ULN = learner.learnerIdentifier;
    l.DateOfBirth = learner.dateOfBirth;
    l.Grade = learner.grade ?? "";
    l.Ranking = learner.ranking?.toString() ?? "";
    return l;
  })
  return (dispatch: ThunkDispatch<StoreState, void, saveLearnerGradesSuccessAction >) => {
    dispatch(beginAjaxCall());
    dispatch(csvUploadInProgressAction({
      learningProviderId: learningProviderId,
      qualificationId: qualificationId,
      fileName: "",
      status: 'InProgress'
    }));
    return axios
      .post(`${config.API_GATEWAY.URL}/LearningProvider/${learningProviderId}/grades/${qualificationId}`,
        JSON.stringify(learnersSubmit),
        { headers: { "content-type": "application/json" } }
      )
      .then((response) => {
        if (response.status === 200) {
          dispatch(csvUploadedAction({
            learningProviderId: learningProviderId,
            qualificationId: qualificationId,
            fileName: "",
            status: 'Success'
          }));
        }
        else {
          dispatch(csvUploadFailedAction({
            learningProviderId: learningProviderId,
            qualificationId: qualificationId,
            fileName:"",
            status: 'Failed'
          }));
        }
      })
      .catch(error => {
        dispatch(ajaxCallError(error));
        dispatch(csvUploadFailedAction({
          learningProviderId: learningProviderId,
          qualificationId: qualificationId,
          fileName: "",
          status: 'Failed'
        }));
        throw error;
      });
  };
};

const getGradesets = (qualificationId: string) => {
   return axios
    .get(`${config.API_GATEWAY.URL}/gradeset/${qualificationId}`)
    .then(response => {
      return response.data as Gradeset[];
    }
  )
}

const mapLearnerGrades = (qualificationId: string, learners: any, gradable: boolean, rankable: boolean) => {
  return getGradesets(qualificationId)
    .then((gradesets) => {
      return _.map(learners, (learner) => {
        const gradeset = _.find(gradesets, {
          "qualificationId": qualificationId,
          "level": learner.level
        })        
        const l = new LearnerGrade();
        l.dateOfBirth = new Date(learner.dateOfBirth);
        l.forenames = learner.forenames ?? "";
        l.learnerIdentifier = learner.uln;
        l.learnerIdentifierName = learner.ulnName;
        l.surname = learner.surname ?? "";
        l.availableGrades = gradeset ? _.orderBy(gradeset.grades, ["index"], ["desc"]) : []
        l.grade = learner.grade;
        l.gradeIndex = learner.gradeIndex ? _.padStart(learner.gradeIndex, 5, '0') : "";
        l.ranking = learner.ranking;
        l.rankingString = learner.ranking ? _.padStart(learner.ranking, 5, '0') : "";
        l.warnings = learner.warnings ?? [];
        l.hasWarnings = l.warnings.length > 0;
        if (gradable && rankable) {
          l.isNotProcessed =
            learner.grade === null &&
            learner.ranking === null &&
            l.warnings.length === 0;
          l.isProcessed =
            learner.grade !== null &&
            learner.ranking != null &&
            l.warnings.length === 0;
        } else {
          if (gradable) {
            l.isNotProcessed =
              learner.grade === null &&
              l.warnings.length === 0;
            l.isProcessed =
              learner.grade != null &&
              l.warnings.length === 0;
          }
          if (rankable) {
            l.isNotProcessed =
              learner.ranking === null &&
              l.warnings.length === 0;
            l.isProcessed =
              learner.ranking != null &&
              l.warnings.length === 0;
          }
        }
        l.searchString = `${learner.forenames ?? ""} 
        ${learner.surname ?? ""} 
        ${learner.uln} 
        ${learner.grade ?? ""} 
        ${learner.ranking ?? ""} 
        ${learner.dateOfBirth
          ? moment(learner.dateOfBirth).format("DD/MM/YYYY")
            : ""}`.toLowerCase();

        return l;
    })
  })
}

export type LearnerGradeActions =
  GetLearnerGradesSuccessAction |
  SaveLearnerGradesSuccessAction
import * as React from "react";
import { RouteComponentProps, Redirect } from "react-router";
import { createRef, RefObject } from "react";
import _ from "lodash";
import { CsvUploadFile } from "../../models/csv-upload-file";
import { CsvUploadStatus } from "../../models/csv-upload-status";
import { LearnerGrade } from "../../models/learner-grade";
import { LearnerGradesPageState } from "../../models/page-state";
import { Qualification } from "../../models/qualification";
import { UploadFile } from "../../models/upload-file";
import { alert } from "../../components/pearson/alert";
import Loader from "../../components/pearson/loader";
import ViewLearnerGrades from "./view/view-learner-grades";
import { LearnerGradePageMode } from "../../types/learner-grade-page-mode";
import EditLearnerGrades from "./edit/edit-learner-grades";
import LearnerGradeHeader from "./components/learner-grade-header";
import { LearnerGradeActionState } from "../../types/learner-grade-action-state";
import UploadGradesModal from "./components/upload-grades-modal";
import { UploadFileError } from "models/upload-file-error";

export interface StateProps {
  csvUploadStatus: CsvUploadStatus | null;
  internal: boolean;
  showAppeals: boolean;
  learningProviderId: string | null;
  learners: LearnerGrade[];
  pageState: LearnerGradesPageState;
  qualification: Qualification | undefined;
  uploadFile: UploadFile | null;
}

export interface DispatchProps {
  downloadCsvFile: (
    learningProviderId: string,
    qualification: Qualification
  ) => void;
  getLearnerGrades: (
    learningProviderId: string,
    qualification: Qualification
  ) => Promise<void>;
  getQualification: (
    learningProviderId: string,
    qualificationId: string
  ) => Promise<void>; 
  getUploadFile: (
    learningProviderId: string,
    qualificationId: string
  ) => Promise<void>;
  refreshUploadFile: (
    learningProviderId: string,
    qualificationId: string,
    version: string | undefined
  ) => Promise<void>;
  saveLearnerGrades: (
    learningProviderId: string,
    qualificationId: string,
    learners: LearnerGrade[]
  ) => Promise<void>;
  setPageState : (
    pageState: LearnerGradesPageState
  ) => void;
  uploadCsvFile: (
    csvUploadFile: CsvUploadFile
  ) => Promise<void>;
};

export type OwnProps = RouteComponentProps<{ qualificationId: string }>;
interface Props extends DispatchProps, StateProps, OwnProps { }

export interface LocalState {
  loading: boolean;
  pageMode: LearnerGradePageMode,
  actionState: LearnerGradeActionState;
  editedLearners: LearnerGrade[];
  isDirty: boolean;
  refreshTimer: NodeJS.Timeout;
  refreshRequired: boolean; 
  refreshVersion: string | undefined;
  showModal: boolean;
}

export class LearnerGrades extends React.Component<Props, LocalState> {
  fileUploadRef = createRef() as RefObject<HTMLInputElement>; 

  constructor(props: Props) {
    super(props);
    if (props.qualification) {
      const { qualificationGroupId, name, code } = props.qualification;
      document.title = `${qualificationGroupId} ${name} ${code}`;
    }
    this.state = {
      loading: true,
      pageMode: "View",
      actionState: null,
      editedLearners: [],
      isDirty: false,
      refreshTimer: setInterval(() => this.refreshForFileResults(), 3000),
      refreshRequired: false,
      refreshVersion: undefined,
      showModal: false
    };
  }

  render = () => {
    const {
      csvUploadStatus,
      internal,
      showAppeals,
      learningProviderId,
      learners,
      pageState,
      qualification,
      uploadFile      
    } = this.props;

    const {
      loading,
      pageMode,
      actionState,
      isDirty,
      editedLearners
    } = this.state;

    if (!learningProviderId || !qualification) {
      return <Redirect to="/" />
    }

    return (
      <div className="page learner-grades">
        <Loader loading={loading} loadingStateLabel="Loading learners..." />
        <LearnerGradeHeader
          loading={loading}
          pageMode={pageMode}
          internal={internal}
          showAppeals={showAppeals}
          actionState={actionState}
          qualification={qualification}
          uploadFile={uploadFile}
          csvUploadStatus={csvUploadStatus}
          disableSaveButton={!isDirty}
          notProcessedCount={_.filter(learners, ["isNotProcessed", true]).length}
          onCancel={() => this.setState({pageMode: "View"})}
          onDownloadTemplate={this.downloadTemplate}
          onEditLearnerGrades={this.editLearnerGrades}
          onSave={() => this.setState({ showModal: true })}
          onUploadTemplate={this.uploadTemplates}
          onViewFileHistory={this.viewFileHistory}
        />
        {!loading && uploadFile && (
          <>
            {(pageMode === "View" || pageMode === "Upload") && (
              <ViewLearnerGrades
                pageState={pageState}
                gradable={qualification.gradable}
                rankable={qualification.rankable}
                learners={learners}
                uploadFileErrors={uploadFile.uploadFileErrors}
                setPageState={this.props.setPageState}
              />
           )}
            {pageMode === "Edit" && (
              <EditLearnerGrades
                gradable={qualification.gradable}
                rankable={qualification.rankable}
                learners={editedLearners}
                onEdit={this.editLearnerGrade}
              />
            )}
            <UploadGradesModal
              show={this.state.showModal}
              onCancel={() => this.setState({showModal: false})}
              onConfirm={this.saveLearnerGrades}
            />
          </>
        )}
      </div>
    );
  };

  componentDidMount = () => {
    const { learningProviderId, qualification, getLearnerGrades, getUploadFile } = this.props;
    if (learningProviderId && qualification) {
      this.setState({ loading: true });
      Promise.all([
        getLearnerGrades(learningProviderId, qualification),
        getUploadFile(learningProviderId, qualification.id)
      ])
      .catch((error) => alert.error(error))
      .finally(() => this.setState({ 
        loading: false, 
        editedLearners: [...this.props.learners] 
      }));
    }
  };

  componentDidUpdate(prevProps: StateProps, prevState: LocalState) {
    const { learningProviderId, qualification, uploadFile, learners, getLearnerGrades, getQualification } = this.props;
    if (learningProviderId && qualification) {
     
      if (this.state.refreshRequired && this.state.refreshVersion !== uploadFile?.versionId) {
        Promise.all([
          getQualification(learningProviderId, qualification.id),
          getLearnerGrades(learningProviderId, qualification)
        ])
          .catch((error) => alert.error(error))
          .finally(() => this.setState({
            actionState: "Processed",
            refreshRequired: false,
            refreshVersion: undefined
          }));
      }

      if (learners.sort() !== prevProps.learners.sort()) {
        this.setState({
          editedLearners: [...learners]
        })
      }
    }
  }

  cancelEditLearnerGrades = () => {
    this.setState({
      pageMode: "View",
      isDirty: false,
      editedLearners: []
    })
  }

  downloadTemplate = () => {
    const { learningProviderId, qualification } = this.props;
    if (learningProviderId && qualification) {
      this.props.downloadCsvFile(learningProviderId, qualification);
    }
  };

  editLearnerGrade = (newLearner: LearnerGrade) => {
    this.setState({
      isDirty: true,
      editedLearners: this.validateLearnerGrades(newLearner)
    })
  }

  editLearnerGrades = () => {
    this.setState({
      pageMode: "Edit",
      isDirty: false,
      editedLearners: this.props.learners
    })
  }

  getWarning = (warningMessage: string): UploadFileError => {
    const warning: UploadFileError = {
      csvFileLineLearnerDetails: null,
      csvRowNumber: null,
      csvRowNumberString: "",
      errorDescription: warningMessage,
      fileline: "",
      searchString: "",
      severity: 1
    }
    return warning;
  }

  saveLearnerGrades = () => {
    const { learningProviderId, qualification, uploadFile } = this.props;
    if (learningProviderId && qualification) {    
      this.props.saveLearnerGrades(
        learningProviderId,
        qualification.id,
        this.state.editedLearners)
        .then(() => {
          this.setState({
            pageMode: "View",
            isDirty: false,
            refreshRequired: true,
            refreshVersion: uploadFile?.versionId,
            actionState: "InProgress",
            showModal: false,
            editedLearners: []
          })
        })
        .catch((error) => alert.error(error));
    }   
  }

  validateLearnerGrades = (editedLearner: LearnerGrade | null) => {
    const { qualification } = this.props;
    let learners: LearnerGrade[] = _.map(this.state.editedLearners, (learner: LearnerGrade) => {
      let l: LearnerGrade = { ...learner,
        hasWarnings: false,
        isNotProcessed: false,
        isProcessed: false,
        warnings: [] as UploadFileError[],
        grade: editedLearner && editedLearner.learnerIdentifier === learner.learnerIdentifier
          ? editedLearner.grade
          : learner.grade,
        gradeIndex: editedLearner && editedLearner.learnerIdentifier === learner.learnerIdentifier
          ? editedLearner.gradeIndex
          : learner.gradeIndex,
        ranking: editedLearner && editedLearner.learnerIdentifier === learner.learnerIdentifier
          ? editedLearner.ranking
          : learner.ranking,
        rankingString: editedLearner && editedLearner.learnerIdentifier === learner.learnerIdentifier
          ? editedLearner.rankingString
          : learner.rankingString
      };
      if (l.grade === null || l.grade === "") {
        l.hasWarnings = true;
        l.warnings = [...l.warnings, this.getWarning("Grade field not populated.")];
      }
      if (l.ranking === null) {
        l.hasWarnings = true;
        l.warnings = [...l.warnings, this.getWarning("Learner rank is missing.")];
      }
      return l;
    });   

    if (qualification && qualification.rankable) {
      const duplicateLearnerRanks = _.groupBy(learners, learner => {
        return qualification.gradable && learner.grade && learner.ranking
          ? `${learner.grade},${learner.ranking}`
          : !qualification.gradable && learner.ranking
            ? `${learner.ranking}`
            : "";
      })
      _.forEach(duplicateLearnerRanks, (learners, rank) => {
        if (rank !== null && rank !== "" && learners.length > 1) {
          _.forEach(learners, learner => {
            const l = {...learner,
              hasWarnings: true,
              warnings: [...learner.warnings, this.getWarning("Duplicate rank provided for learner.")]
            }
            learners = [
              ..._.filter(learners, (learner: LearnerGrade) => {
                return learner.learnerIdentifier !== l.learnerIdentifier;
            }), l]
          })
        }
      })
    }
    return learners;
  }

  viewFileHistory = () => {
    const { learningProviderId, qualification } = this.props;
    if (learningProviderId && qualification) {
      this.props.history.push(
        `/file-history/${qualification.id}/learners`
      );
    }
  }
  
  uploadTemplates = (file: File) => {
    const {
      learningProviderId,
      qualification,
      uploadCsvFile,
    } = this.props;
    if (learningProviderId && qualification) {
      const csvUploadFile: CsvUploadFile = {
        learningProviderId,
        qualificationId: qualification.id,
        file: file,
        status: "",
      };
      uploadCsvFile(csvUploadFile)
        .then(() => {
          this.setState({ refreshRequired: true, 
            refreshVersion: this.props.uploadFile?.versionId,
            actionState: "InProgress"});
        })
        .catch((error) => alert.error(error))
    }
  }

  refreshForFileResults() {
    if (this.state.refreshRequired) {
      const { learningProviderId, qualification } = this.props;
      const { status } = this.props.csvUploadStatus!;
      switch (status) {
        case "IncorrectFileType":
          this.setState({ refreshRequired: false, 
            refreshVersion: undefined,
            actionState: status });
        case "Failed":
          this.setState({ refreshRequired: false, 
            refreshVersion: undefined,
            actionState: status });
        case "Success":
          this.setState({ actionState: status });
          if (learningProviderId && qualification) {
            this.props.refreshUploadFile(learningProviderId, qualification.id, this.state.refreshVersion)
              .then()
              .catch((error) => alert.error(error));
          }
      }
    }
  };
}

export default LearnerGrades;
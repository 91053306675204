import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import PearsonLoader from '../pearson/loader';

export interface DispatchProps {
  handleAuthentication: (callback: (err: string) => void) => void;
  callbackInProgress: () => void;
  signoutUser: () => void;
  callbackComplete: () => void;
}

export interface CallbackProps {
  loading: boolean,
  error: string
}

type Props = CallbackProps & DispatchProps & RouteComponentProps<{}>;

export class Callback extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
  }

  componentWillMount() {
    this.props.callbackInProgress();
    this.forceUpdate();
  }

  shouldComponentUpdate(nextProps: Props) {
    return this.props.loading === false;
  }

  componentWillUpdate(nextProps: Props) {
    if (/access_token|id_token|error/.test(nextProps.location.hash)) {
      this.props.handleAuthentication((err) => {
        if (err) {
          return this.props.history.push('/');
        }
        this.props.callbackComplete();
        return this.props.history.push('/Home');
      });
    } else {
      this.props.history.push('/');
    }
  }

  render() {
    return (
      <div className="gr-col-one gr-col-span-twelve">
        <PearsonLoader loading={this.props.loading}/>
      </div>
    );
  }
}

export default Callback;

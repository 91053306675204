import * as React from 'react';
import Spinner from "react-bootstrap/Spinner";
import Icon from './icon';

interface AlertMessageProps {
  type: "success" | "error" | "warning" | "information" | "loading" | "info";
  message: string  | JSX.Element;
  className: string;
}

export const AlertMessage = ({ 
  type,
  message,
  className,
}: AlertMessageProps) => {
  return (
    <div className={"alert-message"}>    

      {type === "error" && (
        <div className={"alert alert-danger" + className} role="alert">
          <span className="icon">
            <Icon icon="warning" size="24" fill="error" />
          </span>
          {message}
        </div>
      )}

      {type === "warning" && (
        <div className={"alert alert-warning" + className} role="alert">
          <span className="icon">
            <Icon icon="warning" size="24" fill="warning" />
          </span>
          {message}
        </div>
      )}

      {type === "info" && (
        <div className={"alert"} role="alert">
          <span className="icon">
            <Icon icon="information" size="24" fill="information" />
          </span>
          {message}
        </div>
      )}

      {type === "information" && (
        <div className={"alert alert-info" + className} role="alert">
          <span className="icon">
            <Icon icon="information" size="24" fill="information" />
          </span>
          {message}
        </div>
      )}

      {type === "loading" && (
        <div className={"alert alert-info" + className} role="alert">
          <Spinner animation="border" role="status">
            <span className="sr-only">{message}</span>
          </Spinner>
          &nbsp;&nbsp;&nbsp;
          {message}
        </div>
      )}

      {type === "success" && (
        <div className={"alert alert-success" + className} role="alert">
          <span className="icon">
            <Icon icon="correct" size="24" fill="success" />
          </span>         
          {message}
        </div>
      )}
    </div>
  );
};

AlertMessage.defaultProps = {
  className: ""
}
export default AlertMessage;
import { connect } from "react-redux";
import { UnavailablePage, StateProps } from "./unavailable-page";
import { StoreState } from "../../store/store-state";

const mapStateToProps = (state: StoreState): StateProps => {

  return { };
};

export default connect<StateProps>(
  mapStateToProps
)(UnavailablePage);


import * as React from "react";
import TextArea from "../../../components/pearson/text-area";

interface AppealRationaleProps {
  rationale: string;
  onChange: (rationale: string) => void;
}
export const AppealRationale = ({
  rationale,
  onChange
}: AppealRationaleProps) => {

  return (
    <div className="appeal-rationale">
      <TextArea
        label="Rationale:"
        name="Rationale"
        value={rationale}
        maxLength={8000}
        onChange={(e) => onChange(e.currentTarget.value)}
      />   
    </div>
  )
};

export default AppealRationale;

import * as React from 'react';
import * as _ from 'lodash';
{/* <script src="https://unpkg.com/@pearson-ux/toggle" />; */}

interface ToggleProps {
  id: string;
  on: boolean;
  disabled: boolean;
  label: string;
  value: string | boolean;
  onChange: () => void;
}

const Toggle = ({
  id,
  on,
  disabled,
  label,
  value,
  onChange
}: ToggleProps) => {
  return (
    <div className="toggle">
      <label htmlFor={id} id={`label${id}`} className="gr-label">{label}</label>
      <button
        id={id}
        className="gr-toggle"
        name="toggle-0"
        role="switch"
        aria-labelledby={`label${id}`}
        aria-checked={on}
        disabled={disabled}
        {...on ? on : {}}
        onClick={onChange}
      >
        <svg focusable="false" aria-hidden="true">
          <use xlinkHref="#check-sm-18"/>
        </svg>
        <svg focusable="false" aria-hidden="true">
          <use xlinkHref="#remove-sm-18"/>
        </svg>
      </button >
    </div>
  )
};

Toggle.defaultProps = {
  disabled: false,
  value: undefined
}

export default Toggle;
import { Action } from "redux";

import { CALLBACK_IN_PROGRESS, CALLBACK_COMPLETE } from "./action-types";

interface CallbackInProgressAction extends Action {
  type: CALLBACK_IN_PROGRESS;
}

interface CallbackCompleteAction extends Action {
  type: CALLBACK_COMPLETE;
}

export type CallbackActions = CallbackInProgressAction | CallbackCompleteAction;

export const loading = (): CallbackInProgressAction => ({
  type: CALLBACK_IN_PROGRESS,
});

export const callbackComplete = (): CallbackCompleteAction => ({
  type: CALLBACK_COMPLETE,
});

import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { ajaxCallError, beginAjaxCall, AjaxCallActions } from './ajax-status.actions';
import { StoreState } from '../store/store-state';
import config from '../app.config';
import { UploadFileHistory } from '../models/upload-file-history';
import { GET_UPLOAD_FILE_HISTORY_SUCCESS } from './action-types';
import { getStatusDescription, getStatusSortOrder } from './qualification.actions';

interface GetUploadFileHistorySuccessAction extends Action {
  type: GET_UPLOAD_FILE_HISTORY_SUCCESS;
  uploadFileHistories: UploadFileHistory[] | null;
}

export type getUploadFileHistorySuccessAction =
  | GetUploadFileHistorySuccessAction
  | AjaxCallActions;

export const getUploadFileHistorySuccessAction = (
    uploadFileHistories: UploadFileHistory[] | null
): GetUploadFileHistorySuccessAction => ({
    uploadFileHistories,
    type: GET_UPLOAD_FILE_HISTORY_SUCCESS
});

export const getUploadFileHistory = (learningProviderId: string, qualificationId: string) => {
  return (dispatch: ThunkDispatch<StoreState, void, getUploadFileHistorySuccessAction>) => {
    dispatch(beginAjaxCall());
    return axios
      .get(`${config.API_GATEWAY.URL}/LearningProviderGradesHistory/${learningProviderId}/grades/${qualificationId}`)
      .then(response => {
        const uploadFileHistories = response.data;
        dispatch(getUploadFileHistorySuccessAction(
            _.map(uploadFileHistories, (UploadFileHistory) => {
                return mapUploadFileHistory(UploadFileHistory);
              })
        ))
      })
      .catch(error => {
        dispatch(ajaxCallError(error));
        throw error;
      });
  };
};

const mapUploadFileHistory = (uploadFile: any) => {
  const ufh = new UploadFileHistory;
  ufh.historyId = uploadFile.historyId;
  ufh.fileName = uploadFile.fileName;
  ufh.uploadedDate = new Date(uploadFile.uploadedDate);
  ufh.uploadedBy = uploadFile.uploadedBy;
  ufh.statusCode = uploadFile.status;
  ufh.status = getStatusDescription(uploadFile.status);
  ufh.processed = uploadFile.processed;
  ufh.warning = uploadFile.warning;
  ufh.latest = uploadFile.latest;
  ufh.uploadType = uploadFile.uploadType;
  ufh.versionId = uploadFile.versionId;
  ufh.searchString = `${ufh.fileName ?? ""} 
  ${ufh.uploadedBy ?? ""}
  ${ufh.status ?? ""}
  ${ufh.uploadedDate
  ? moment(ufh.uploadedDate).format("DD/MM/YYYY HH:mm")
    : ""}`.toLowerCase();  

  ufh.hasErrors = uploadFile.status === "PartiallyProcessed" ||
    uploadFile.status === "Rejected" ||
    uploadFile.status === "ProcessingError";
  ufh.hasWarnings = uploadFile.status === "NoDataReceived";
  ufh.isProcessed = uploadFile.status === "Processed";
  ufh.statusSortOrder = getStatusSortOrder(uploadFile.status);
  return ufh;
}

export type UploadFileHistoryActions =
GetUploadFileHistorySuccessAction 
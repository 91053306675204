import * as React from "react";
import { useState, useEffect } from "react";
import _ from "lodash";
import { AppealEvidence } from "../../../models/appeal-evidence";
import { AppealFileUpload } from "../../../models/appeal-file-upload";
import { AppealLearner } from "../../../models/appeal-learner";
import { AppealSubmit } from "../../../models/appeal-submit";
import { AppealType } from "../../../models/appeal-type";
import { alert } from "../../../components/pearson/alert";
import AppealDetails from "./appeal-details";
import AppealEvidenceCardList from "./appeal-evidence-card-list";
import AppealLearnerCardList from "./appeal-learner-card-list";
import AppealRationale from "./appeal-rationale";
import AppealTypeRadioGroup from "./appeal-type-radio-group";
import Button from "../../../components/pearson/button";
import Stepper from "../../../components/pearson/stepper";
import TermsAndConditions from "./terms-and-conditions";
import { Link } from "react-router-dom";
import Icon from "../../../components/pearson/icon";
import config from "../../../app.config";

export enum Step {
  "appealType" = 1,
  "learners" = 2,
  "rationale" = 3,
  "evidence" = 4,
  "tAndCs" = 5,
  "submit" = 6
}

interface AppealFormProps {
  appeal: AppealSubmit;
  appealEvidence: AppealEvidence[];
  appealFormMode: "New" | "Complete" |"Edit";
  appealTypes: AppealType[];  
  backLinkName: string;
  backToPath: string;
  learners: AppealLearner[];
  learningProviderId: string; 
  qualificationDisplayName: string;
  onAcknowledgeAppeal: () => Promise<void>;
  onCancel: () => void;
  onChange: (appeal: AppealSubmit) => void;
  onDownloadAppealEvidence: (learningProviderId: string, appealId: string, fileId: string) => void;
  onSaveAppeal: () => Promise<void>;
  onUploadAppealFile: (file: AppealFileUpload) => Promise<void>;
}

export const AppealForm = ({
  appeal,
  appealEvidence,
  appealFormMode,
  appealTypes,
  backLinkName,
  backToPath,
  learners,
  learningProviderId,
  qualificationDisplayName,
  onAcknowledgeAppeal,
  onCancel,
  onChange,
  onDownloadAppealEvidence,
  onSaveAppeal,
  onUploadAppealFile
}: AppealFormProps) => {

  const updateAllowedLearenrs = (): AppealLearner[] => {
    return _.map(learners, learner => {
      return {
        ...learner,
        allowAppeal: !(_.find(learner.appeals, learnerAppeal => {
          return learnerAppeal.appealId !== appeal.appealId &&
            learnerAppeal.appealTypeId === appeal.appealTypeId;
        }))
      }
    })
  }

  const [allowedLearners, setAllowedLearners] = useState(updateAllowedLearenrs());
  const [stepNumber, setStepNumber] = useState(1);

  useEffect(() => {
    setAllowedLearners(updateAllowedLearenrs());
  }, [appeal.appealTypeId])

  const disableNextButton = () => {
    switch (stepNumber) {
      case Step.appealType:
        return appeal.appealTypeId === undefined;
      case Step.learners:
        return appeal.learnerIdentifiers.length === 0;
      case Step.rationale:
        return appeal.rationale.length === 0;
      case Step.evidence:
        return appeal.appealTypeId === 1 && appealEvidence.length === 0;
      case Step.tAndCs:
        return !(appeal.tandC && appeal.hoC && appeal.learnerConsent);
      default:
        return false;
    }
  }
   
  const downloadAppealEvidence = (fileId: string) => {
    onDownloadAppealEvidence(learningProviderId, appeal.appealId, fileId);
  }

  const onNext = () => {
    switch (stepNumber) {
      case Step.rationale:
        if (appealFormMode === "New" && appeal.appealId === undefined) {
          onSaveAppeal()
            .then(() => setStepNumber(stepNumber + 1))
            .catch((error) => alert.error(error));
        } else {
          setStepNumber(stepNumber + 1)
        }
        break;
      case Step.submit:
        onSaveAppeal()
          .then(() => {
            onAcknowledgeAppeal()
            .then(() => {
              alert.success("The appeal has been submitted");
              onCancel();
            })          
            .catch ((error) => alert.error(error));
          })
          .catch((error) => alert.error(error));
        break;
      default:
        setStepNumber(stepNumber + 1);
    }
  }

  const onPrevious = () => {
    setStepNumber(stepNumber - 1);
  }

  const stepHeader = () => {
    switch (stepNumber) {
      case Step.appealType:
        return "Step 1:  Select an appeal service type";
      case Step.learners:
        return `Step 2: Add learners (${appeal.learnerIdentifiers.length})`;
      case Step.rationale:
        return "Step 3: Appeal explanation/information";
      case Step.evidence:
        return "Step 4: Add attachments";
      case Step.tAndCs:
        return "Step 5: Confirm terms and conditions";
      case Step.submit:
        return "Step 6: Review and submit";
      default:
        return false;
    }
  }

  return (
    <div className="appeal-form">
      <div className="header jumbotron">
        <Link className="back-link" to={backToPath}>&lt;&lt; {backLinkName}</Link>
        <div className="grid">
          <h1 className="page-header">{`${appealFormMode} appeal`}</h1>   
          <h2 className="page-sub-header">{qualificationDisplayName}</h2>       
          <Stepper
            direction="horizontal"
            currentStepNumber={stepNumber}
            steps={["", "", "", "", "", ""]}
          />         
        </div>
      </div>     
      
      <div className="appeal-form-step">   
        <h3>{stepHeader()}</h3>       
        <div className="step-panel">
          {stepNumber === Step.appealType && (
            <>
              <p>If you want to appeal on more than one basis, select the most appropriate service and provide information on the other basis for your appeal in the Appeal explanation/information.</p>
              <AppealTypeRadioGroup
                appealTypes={appealTypes}
                appealTypeId={appeal.appealTypeId}
                onChange={(appealTypeId) => {
                  onChange({
                    ...appeal,
                    appealTypeId,
                    learnerIdentifiers: []
                  })
                }}
              />
              <br/>
              <a className="help-icon"
                href={config.HELP_LINKS.APPEAL_TYPES_GUIDANCE}
                target="_blank"
                data-tooltip="Help"
              >
                <Icon icon="help" size="24" fill="information" />&nbsp;&nbsp;Need Help Understanding Appeal Service Types?
              </a>
            </>
          )}
          {stepNumber === Step.learners && appeal.appealTypeId && (
            <AppealLearnerCardList
              appealTypeId={appeal.appealTypeId}
              learners={allowedLearners}
              mode={appealFormMode}
              readOnly={false}
              selectedLearnerIdentifiers={appeal.learnerIdentifiers}
              onChange={(learnerIdentifiers) => {
                onChange({ ...appeal, learnerIdentifiers })
              }}
            />
          )}
          {stepNumber === Step.rationale && (
           <>
              <p>Please add your reason and rationale as to why you are submitting this appeal, including any supporting information. If you are submitting an Appeal Service 1, please add why data that was previously submitted and confirmed/authorised as being correct now needs to be changed.</p>
              <p>If you are appealing on more than one basis, please provide information here.</p>
              <AppealRationale
                rationale={appeal.rationale}
                onChange={(rationale) => {
                  onChange({ ...appeal, rationale })
                }}
              />
           </>
          )}
          {stepNumber === Step.evidence && (
            <AppealEvidenceCardList
              appealId={appeal.appealId}
              appealEvidence={appealEvidence}
              learningProviderId={learningProviderId}
              showDownloadLink={true}
              showUploadButton={true}
              onDownloadAppealEvidence={downloadAppealEvidence}
              onUploadAppealFile={onUploadAppealFile}
            />
          )}
          {stepNumber === Step.tAndCs && (
            <TermsAndConditions
              hoC={appeal.hoC ?? undefined}
              learnerConsent={appeal.learnerConsent ?? undefined}
              tandC={appeal.tandC ?? undefined}
              ucasLearners={appeal.ucasLearners ?? undefined}
              onChange={(hoC, learnerConsent, tandC, ucasLearners) => {
                onChange({ ...appeal, hoC, learnerConsent, tandC, ucasLearners })
              }}
            />
          )}
          {stepNumber === Step.submit && appeal.appealTypeId && (
            <AppealDetails
              appealId={appeal.appealId}
              appealEvidence={appealEvidence}
              appealLearners={_.filter(learners, learner => {
                return appeal.learnerIdentifiers.includes(learner.learnerIdentifier)
              })}
              appealStatusId={0}
              appealTypeId={appeal.appealTypeId}            
              learningProviderId={learningProviderId}
              rationale={appeal.rationale}
              showDownloadLink={false}
              showUploadButton={false}
              ucasLearners={appeal.ucasLearners ?? false}
            />
          )}
        </div>
        <div className="step-buttons">
          <div>
            {stepNumber > 1 && (
              <Button label="Previous" className="attention" onClick={onPrevious} />
            )}
          </div>
          <div>
            <Button label="Cancel" type="reset" onClick={onCancel} />
            <Button
              label={stepNumber === Step.submit ? "Submit" : "Next"}
              className="primary"
              disabled={disableNextButton()}
              onClick={onNext}
            />
          </div>
        </div>
      </div>
    </div>    
  )
};

AppealForm.defaultProps = {
  appealEvidence: [],
  appealTypes: [],
  learners: []
}

export default AppealForm;

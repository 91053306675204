import * as React from "react";
import { FormEvent } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "../../../../components/pearson/button";
import TextArea from "../../../../components/pearson/text-area";
import { AWAITING_FURTHER_INFOMATION, IN_PROGRESS } from "../../../../models/appeal-state";

interface MoveAppealModalProps {
  newStatusId: number;
  show: boolean;
  onCancel: () => void;
  onSave: (statusId: number, note: string) => void;
}

const MoveAppealModal = ({
  newStatusId,
  show,
  onCancel,
  onSave,
}: MoveAppealModalProps) => {

  const [note, setNote] = React.useState("");

  const getModalTitle = () => {
    switch (newStatusId) {
      case AWAITING_FURTHER_INFOMATION:
        return "Awaiting further information";
      case IN_PROGRESS:
        return "In progress";
      default:
        return "";
    }
  }

  const getModalText = () => {
    switch (newStatusId) {
      case AWAITING_FURTHER_INFOMATION:
        return "Please enter details of the additional information you have requested.";
      case IN_PROGRESS:
        return "Please detail why you are moving the appeal to 'In progress'.";
      default:
        return "";
    }
  } 

  const onFormSubmit = (
    event:
      FormEvent<HTMLFormElement> |
      React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    event.preventDefault();
    onSave(newStatusId, `Moved to ${getModalTitle()}\n${note}`); 
  };
  
  return (
    <div className="move-appeal-modal">
      <Modal
        show={show}
        onHide={onCancel}
        aria-labelledby={getModalTitle()}>
        <Modal.Header closeButton>
          <Modal.Title>{getModalTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={onFormSubmit}>
            <p>{getModalText()}</p>
            <TextArea 
              label="Note:" 
              value={note}
              onChange={(e) => setNote(e.currentTarget.value)}
            />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            label="Save"
            type="submit"
            disabled={note.length === 0}
            className="primary"
            onClick={onFormSubmit}
          />         
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MoveAppealModal;
import initialState from './initialState';
import { AppealActions } from "../actions/appeal.actions"
import { AppealState } from '../models/appeal-state';

export function appealStatesReducer(
  state: AppealState[] = initialState.appealStates,
  action: AppealActions 
): AppealState[] {

  switch (action.type) {
    default:
      return state;
  }
};


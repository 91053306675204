import { QualificationStatusCode } from "../types/qualification-status-code";

export class Qualification {
  qualificationGroupId: string;
  id: string;
  appealsAcknowledgedCount: number;
  appealsAcknowledgedCountString: string;
  appealsAwaitingFutherInformationCount: number;
  appealsAwaitingFutherInformationCountString: string;
  appealsClosedCount: number;
  appealsClosedCountString: string;
  appealsClosedPendingOutcomeLetterCount: number;
  appealsClosedPendingOutcomeLetterCountString: string;
  appealsIncompleteCount: number;
  appealsIncompleteCountString: string;
  appealsInProgressCount: number;
  appealsInProgressCountString: string;
  code: string;
  displayName: string;
  gradable: boolean;
  hasAppeals: boolean;
  hasErrors: boolean;
  hasWarnings: boolean;
  isProcessed: boolean;
  lastUpdated: Date | null;
  learnersProcessed: number | null;
  learnersProcessedString: string;
  name: string;
  numberOfAppeals: number;
  numberOfAppealsString: string;
  numberOfLearners: number | null;
  numberOfLearnersString: string;
  rankable: boolean;
  readOnly: boolean;
  searchString: string;
  statusCode: QualificationStatusCode;
  status: string;
  statusSortOrder: string;
}

import { Action } from "redux";

import { 
  AllAppealsPageState,
  AppealsPageState,  
  LearnerGradesPageState,
  QualificationsPageState,
  UploadFileHistoryPageState
} from "../models/page-state";

import { 
  SET_ALL_APPEALS_PAGE_STATE,
  SET_APPEALS_PAGE_STATE,
  SET_LEARNER_GRADES_PAGE_STATE,
  SET_QUALIFICATIONS_PAGE_STATE,
  SET_UPLOAD_FILE_HISTORY_PAGE_STATE
} from "./action-types";

interface SetAllAppealsPageStateAction extends Action {
  type: SET_ALL_APPEALS_PAGE_STATE;
  pageState: AllAppealsPageState;
}
interface SetAppealsPageStateAction extends Action {
  type: SET_APPEALS_PAGE_STATE;
  pageState: AppealsPageState;
}
interface SetLearnerGradesPageStateAction extends Action {
  type: SET_LEARNER_GRADES_PAGE_STATE;
  pageState: LearnerGradesPageState;
}
interface SetQualificationsPageStateAction extends Action {
  type: SET_QUALIFICATIONS_PAGE_STATE;
  pageState: QualificationsPageState;
}
interface SetUploadFileHistoryPageStateAction extends Action {
  type: SET_UPLOAD_FILE_HISTORY_PAGE_STATE;
  pageState: UploadFileHistoryPageState;
}

export const setAllAppealsPageState = (pageState: AllAppealsPageState)
  : SetAllAppealsPageStateAction => {
  return {
    type: SET_ALL_APPEALS_PAGE_STATE,
    pageState
  };
};
export const setAppealsPageState = (pageState: AppealsPageState)
  : SetAppealsPageStateAction => {
  return {
    type: SET_APPEALS_PAGE_STATE,
    pageState
  };
};
export const setLearnerGradesPageState = (pageState: LearnerGradesPageState)
  : SetLearnerGradesPageStateAction => {
  return {
    type: SET_LEARNER_GRADES_PAGE_STATE,
    pageState
  };
};
export const setQualificationsPageState = (pageState: QualificationsPageState)
  : SetQualificationsPageStateAction => {
  return {
    type: SET_QUALIFICATIONS_PAGE_STATE,
    pageState
  };
};
export const setUploadFileHistoryPageState = (pageState: UploadFileHistoryPageState)
  : SetUploadFileHistoryPageStateAction => {
  return {
    type: SET_UPLOAD_FILE_HISTORY_PAGE_STATE,
    pageState
  };
};

export type PageStateActions = 
  SetAllAppealsPageStateAction |
  SetAppealsPageStateAction |
  SetLearnerGradesPageStateAction |
  SetQualificationsPageStateAction |
  SetUploadFileHistoryPageStateAction;
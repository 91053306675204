import * as React from 'react';
import { Route, Redirect, Switch, RouteComponentProps, RouteProps } from 'react-router';
import AuthForgeRock from "../actions/auth/auth";
import _ from 'lodash';
import AccessDeniedPage from './access-denied-page/access-denied-page.container';
import Callback from '../components/callback/callback.container';
import ErrorPage from './error-page/error-page.container';
import Home from './home-page/home-page.container';
import FileHistory from './file-history/file-History.container';
import LearnerGrades from './learner-grades/learner-grades.container'; 
import NotFoundPage from './not-found-page/not-found-page.container';
import Qualifications from './qualifications/qualifications.container';
import UnavailablePage from './unavailable-page/unavailable-page.container';
import ViewAllAppealsPage from "./appeals/view-all-appeals/view-all-appeals-page.container";
import ViewAppealsPage from './appeals/view-appeals/view-appeals-page.container';
import CreateAppealPage from './appeals/create/create-appeal-page.container';
import EditAppealPage from './appeals/edit/edit-appeal-page.container';
import ViewAppealPage from './appeals/view/view-appeal-page.container';
import AppealsManagementPage from './appeals-management/appeals-management-page.container';
import { User } from '../models/user';


const auth = new AuthForgeRock();

export const Routes = (props: { user: User | null}) => {
  const isInternal = props.user ? props.user.isInternal : false;
  const showAppeals = props.user ? props.user.showAppeals : false;
  
  const PrivateRoute = ({ component, ...rest }: RouteProps) => {
    if (!component) {
      throw Error('component is undefined');
    }
    const Component = component; // JSX Elements have to be uppercase.
    const accessDenied = auth.isAuthenticated() && props.user && !props.user.isUserPermissionValid;  

    const render = (props: RouteComponentProps<{}>): React.ReactNode => {
      return accessDenied
        ? <Redirect to={{ pathname: '/access-denied' }} />
        : <Component {...props} />;
    };
    return <Route {...rest} render={render} />;
  };
  return (
    <Switch>
      <Route exact={true} path="/access-denied" component={AccessDeniedPage} />
      <Route exact={true} path="/error/:correlationId" component={ErrorPage} />
      <Route exact={true} path="/unavailable" component={UnavailablePage} />
      <Route exact={true} path="/not-found" component={NotFoundPage} />
      <Route exact={true} path="/callback" component={Callback} />
      <Route exact={true} path="/" component={Home} />
      <PrivateRoute exact={true} path={"/qualifications/:qualificationGroupId"} component={Qualifications} />
      <PrivateRoute exact={true} path={"/qualifications/:qualificationGroupId/:qualificationId/learners"} component={LearnerGrades} />
      <PrivateRoute exact={true} path={"/file-history/:qualificationId/qualifications"} component={FileHistory} />
      <PrivateRoute exact={true} path={"/file-history/:qualificationId/learners"} component={FileHistory} />
      {showAppeals && (
        <>
          <PrivateRoute exact={true} path={"/qualifications/:qualificationGroupId/:qualificationId/new-appeal"} component={CreateAppealPage} />
          <PrivateRoute exact={true} path={"/qualifications/:qualificationGroupId/:qualificationId/appeals/new"} component={CreateAppealPage} />
          <PrivateRoute exact={true} path={"/qualifications/:qualificationGroupId/:qualificationId/appeals/complete/:appealId"} component={EditAppealPage} />
          <PrivateRoute exact={true} path={"/qualifications/:qualificationGroupId/:qualificationId/appeals/view/:appealId"} component={ViewAppealPage} />
          <PrivateRoute exact={true} path={"/qualifications/:qualificationGroupId/:qualificationId/appeals"} component={ViewAppealsPage} />
          <PrivateRoute exact={true} path={"/all-appeals"} component={ViewAllAppealsPage} />
          <PrivateRoute exact={true} path={"/all-appeals/complete/:appealId"} component={EditAppealPage} />
          <PrivateRoute exact={true} path={"/all-appeals/view/:appealId"} component={ViewAppealPage} />
          {isInternal && (
            <>
              <PrivateRoute exact={true} path={"/appeals-management"} component={AppealsManagementPage} />
              <PrivateRoute exact={true} path={"/appeals-management/view/:learningProviderId/:appealId"} component={ViewAppealPage} />
            </>
           )}
        </>
      )}
      <Route exact={true} path="/home">
        <Redirect to={{ pathname: "/" }} />
      </Route>
      <Redirect to="/not-found" />
    </Switch>
  )
};

export default Routes;
import axios from 'axios';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { ajaxCallError, beginAjaxCall, AjaxCallActions } from './ajax-status.actions';
import { StoreState } from '../store/store-state';
import config from '../app.config';
import _ from 'lodash';
import { AppealSearch } from '../models/appeal-search';
import { AppealUser } from '../models/appeal-user';
import { AppealNote } from '../models/appeal-note';
import { AppealTemplate } from '../models/appeal-template';
import { Team } from '../models/team';
import { TeamAppeal } from '../models/team-appeal';

import {
  ADD_APPEAL_NOTE_SUCCESS,
  ASSIGN_APPEAL_SUCCESS,
  GET_APPEAL_TEMPLATES_SUCCESS,
  GET_APPEAL_BY_REFERENCE_CODE_SUCCESS,
  GET_MY_TEAM_APPEALS_SUCCESS,
  GET_TEAMS_SUCCESS,
  GET_TEAM_APPEALS_SUCCESS,
  GET_UNALLOCATED_TEAM_APPEALS_SUCCESS,
  SET_APPEALS_MANAGEMENT_ACTIVE_IDX,
  SET_APPEALS_MANAGEMENT_TEAM_ID,
  SET_APPEALS_MANAGEMENT_TEAM_WORK_SEARCH,
  TRANSFER_APPEAL_SUCCESS,
  UPDATE_APPEAL_STATUS_SUCCESS,
  CLOSE_APPEAL_SUCCESS,
  RE_OPEN_APPEAL_SUCCESS,
  SWITCH_APPEAL_TYPE_SUCCESS
} from './action-types';
import { AppealSearchPaging } from 'models/appeal-search-paging';

interface AddAppealNoteSuccessAction extends Action {
  type: ADD_APPEAL_NOTE_SUCCESS;
  note: AppealNote;
}
interface AssignAppealSuccessAction extends Action {
  type: ASSIGN_APPEAL_SUCCESS;
  learningProviderId: string;
  appealId: string;
  assignedUser: AppealUser;
}
interface CloseAppealSuccessAction extends Action {
  type: CLOSE_APPEAL_SUCCESS;
  learningProviderId: string;
  appealId: string;
  outcomeId: number;
  letterText: string;
}
interface GetAppealByReferenceCodeSuccessAction extends Action {
  type: GET_APPEAL_BY_REFERENCE_CODE_SUCCESS;
  referenceCode: string;
  appeal: TeamAppeal | null;
}
interface GetAppealTemplatesSuccessAction extends Action {
  type: GET_APPEAL_TEMPLATES_SUCCESS;
  appealTemplates: AppealTemplate[];
}
interface GetMyTeamAppealsSuccessAction extends Action {
  type: GET_MY_TEAM_APPEALS_SUCCESS;
  teamId: number;
  appealSearch: AppealSearch;
  appeals: TeamAppeal[];
}
interface GetTeamsSuccessAction extends Action {
  type: GET_TEAMS_SUCCESS;
  teams: Team[];
}
interface GetTeamAppealsSuccessAction extends Action {
  type: GET_TEAM_APPEALS_SUCCESS;
  teamId: number;
  appealSearch: AppealSearch;
  appeals: TeamAppeal[];
}
interface GetUnallocatedTeamAppealsSuccessAction extends Action {
  type: GET_UNALLOCATED_TEAM_APPEALS_SUCCESS;
  teamId: number;
  loadMoreWork: boolean;
  appealSearch: AppealSearchPaging;
  appeals: TeamAppeal[];
}
interface SetActiveIdxAction extends Action {
  type: SET_APPEALS_MANAGEMENT_ACTIVE_IDX;
  activeIdx: number;
}
interface SetTeamIdAction extends Action {
  type: SET_APPEALS_MANAGEMENT_TEAM_ID;
  teamId: number | null;
}
interface SetTeamWorkSearchAction extends Action {
  type: SET_APPEALS_MANAGEMENT_TEAM_WORK_SEARCH;
  teamWorkSearch: AppealSearch;
}
interface TransferAppealSuccessAction extends Action {
  type: TRANSFER_APPEAL_SUCCESS;
  learningProviderId: string,
  appealId: string,
  teamId: number;
}
interface UpdateAppealStatusSuccessAction extends Action {
  type: UPDATE_APPEAL_STATUS_SUCCESS;
  learningProviderId: string;
  appealId: string;
  statusId: number;
  note: string;
}
interface ReOpenAppealSuccessAction extends Action {
  type: RE_OPEN_APPEAL_SUCCESS;
  learningProviderId: string;
  appealId: string;
}
interface SwitchAppealTypeSuccessAction extends Action {
  type: SWITCH_APPEAL_TYPE_SUCCESS;
  learningProviderId: string;
  appealId: string;
  appealTypeId: number;
}

export type addAppealNoteSuccessAction =
  AddAppealNoteSuccessAction |
  AjaxCallActions;
export type assignAppealSuccessAction =
  AssignAppealSuccessAction |
  AjaxCallActions;
export type closeAppealSuccessAction =
  CloseAppealSuccessAction |
  AjaxCallActions;
export type getAppealByReferenceCodeSuccessAction =
  GetAppealByReferenceCodeSuccessAction |
  AjaxCallActions;
export type getAppealTemplatesSuccessAction =
  GetAppealTemplatesSuccessAction |
  AjaxCallActions;
export type getMyTeamAppealsSuccessAction =
  GetMyTeamAppealsSuccessAction |
  AjaxCallActions
export type getTeamsSuccessAction =
  GetTeamsSuccessAction |
  AjaxCallActions;
export type getTeamAppealsSuccessAction =
  GetTeamAppealsSuccessAction |
  AjaxCallActions;
export type getUnallocatedTeamAppealsSuccessAction =
  GetUnallocatedTeamAppealsSuccessAction |
  AjaxCallActions;
export type setTeamIdAction =
  SetTeamIdAction;
export type setTeamWorkSearchAction =
  SetTeamWorkSearchAction;
export type transferAppealSuccessAction =
  TransferAppealSuccessAction |
  AjaxCallActions;
export type updateAppealStatusSuccessAction =
  UpdateAppealStatusSuccessAction |
  AjaxCallActions;
export type reOpenAppealSuccessAction =
  ReOpenAppealSuccessAction |
  AjaxCallActions;
export type switchAppealTypeSuccessAction =
  SwitchAppealTypeSuccessAction |
  AjaxCallActions;

export const addAppealNoteSuccessAction = (
  note: AppealNote
): AddAppealNoteSuccessAction => ({
  note,
  type: ADD_APPEAL_NOTE_SUCCESS
});
export const assignAppealSuccessAction = (
  learningProviderId: string,
  appealId: string,
  assignedUser: AppealUser
): AssignAppealSuccessAction => ({
  learningProviderId,
  appealId,
  assignedUser,
  type: ASSIGN_APPEAL_SUCCESS
});
export const closeAppealSuccessAction = (
  learningProviderId: string,
  appealId: string,
  outcomeId: number,
  letterText: string
): CloseAppealSuccessAction => ({
  learningProviderId,
  appealId,
  outcomeId,
  letterText,
  type: CLOSE_APPEAL_SUCCESS
});
export const getAppealByReferenceCodeSuccessAction = (
  referenceCode: string,
  appeal: TeamAppeal | null,
): GetAppealByReferenceCodeSuccessAction => ({
  referenceCode,
  appeal,
  type: GET_APPEAL_BY_REFERENCE_CODE_SUCCESS
});
export const getAppealTemplatesSuccessAction = (
  appealTemplates: AppealTemplate[]
): GetAppealTemplatesSuccessAction => ({
  appealTemplates,
  type: GET_APPEAL_TEMPLATES_SUCCESS
});
export const getMyTeamAppealsSuccessAction = (
  teamId: number,
  appealSearch: AppealSearch,
  appeals: TeamAppeal[]
): GetMyTeamAppealsSuccessAction => ({
  teamId,
  appealSearch,
  appeals,
  type: GET_MY_TEAM_APPEALS_SUCCESS
});
export const getTeamsSuccessAction = (
  teams: Team[],
): GetTeamsSuccessAction => ({
  teams,
  type: GET_TEAMS_SUCCESS
});
export const getTeamAppealsSuccessAction = (
  teamId: number,
  appealSearch: AppealSearch,
  appeals: TeamAppeal[]
): GetTeamAppealsSuccessAction => ({
  teamId,
  appealSearch,
  appeals,
  type: GET_TEAM_APPEALS_SUCCESS
});
export const getUnallocatedTeamAppealsSuccessAction = (
  teamId: number,
  loadMoreWork: boolean,
  appealSearch: AppealSearchPaging,
  appeals: TeamAppeal[]
): GetUnallocatedTeamAppealsSuccessAction => ({
  teamId,
  loadMoreWork,
  appealSearch,
  appeals,
  type: GET_UNALLOCATED_TEAM_APPEALS_SUCCESS
});
export const setTeamIdAction = (
  teamId: number | null
): SetTeamIdAction => ({
  type: SET_APPEALS_MANAGEMENT_TEAM_ID,
  teamId
});
export const setTeamWorkSearchAction = (
  teamWorkSearch: AppealSearch
): SetTeamWorkSearchAction => ({
  type: SET_APPEALS_MANAGEMENT_TEAM_WORK_SEARCH,
  teamWorkSearch
});
export const transferAppealSuccessAction = (
  learningProviderId: string,
  appealId: string,
  teamId: number
): TransferAppealSuccessAction => ({
  learningProviderId, appealId, teamId,
  type: TRANSFER_APPEAL_SUCCESS
});
export const updateAppealStatusSuccessAction = (
  learningProviderId: string,
  appealId: string,
  statusId: number,
  note: string
): UpdateAppealStatusSuccessAction => ({
  learningProviderId,
  appealId,
  statusId,
  note,
  type: UPDATE_APPEAL_STATUS_SUCCESS
});
export const reOpenAppealSuccessAction = (
  learningProviderId: string,
  appealId: string
): ReOpenAppealSuccessAction => ({
  learningProviderId,
  appealId,
  type: RE_OPEN_APPEAL_SUCCESS
});
export const switchAppealTypeSuccessAction = (
  learningProviderId: string,
  appealId: string,
  appealTypeId: number
): SwitchAppealTypeSuccessAction => ({
  learningProviderId,
  appealId,
  appealTypeId,
  type: SWITCH_APPEAL_TYPE_SUCCESS
});

export const addAppealNote = (
  learningProviderId: string,
  appealId: string,
  note: string) => {
  return (dispatch: ThunkDispatch<StoreState, void, addAppealNoteSuccessAction>, getState: any) => {
    dispatch(beginAjaxCall());
    return axios
      .post(`${config.API_GATEWAY.URL}/appeal/${learningProviderId}/appeal/${appealId}/note`,
        JSON.stringify(note),
        { headers: { "content-type": "application/json" } }
      )
      .then((response) => {
        dispatch(addAppealNoteSuccessAction({
          addedDateTime: new Date(Date.now()),
          text: note,
          username: getState().auth.profile.name
        }))
      })
      .catch(error => {
        dispatch(ajaxCallError(error));
        throw error;
      });
  };
};

export const assignAppeal = (
  learningProviderId: string,
  appealId: string) => {
  return (dispatch: ThunkDispatch<StoreState, void, assignAppealSuccessAction>, getState: any) => {
    dispatch(beginAjaxCall());
    return axios
      .post(`${config.API_GATEWAY.URL}/appeal/${learningProviderId}/appeal/${appealId}/assign`,
        { headers: { "content-type": "application/json" } }
      )
      .then((response) => {
        dispatch(assignAppealSuccessAction(
          learningProviderId,
          appealId,
          {
            ...new AppealUser(),
            pearsonUserId: getState().auth.profile.pearsonUID,
            username: getState().auth.profile.name
          }
        ))
      })
      .catch(error => {
        dispatch(ajaxCallError(error));
        throw error;
      });
  };
};

export const closeAppeal = (
  learningProviderId: string,
  appealId: string,
  outcomeId: number,
  letterText: string) => {
  return (dispatch: ThunkDispatch<StoreState, void, closeAppealSuccessAction>, getState: any) => {
    dispatch(beginAjaxCall());
    return axios
      .post(`${config.API_GATEWAY.URL}/appeal/${learningProviderId}/appeal/${appealId}/close`,
        JSON.stringify({ outcomeId, letterText }),
        { headers: { "content-type": "application/json" } }
      )
      .then((response) => {
        dispatch(closeAppealSuccessAction(
          learningProviderId,
          appealId,
          outcomeId,
          letterText))
      })
      .catch(error => {
        dispatch(ajaxCallError(error));
        throw error;
      });
  };
};

export const getAppealByReferenceCode = (referenceCode: string) => {
  return (dispatch: ThunkDispatch<StoreState, void, getAppealByReferenceCodeSuccessAction>) => {
    dispatch(beginAjaxCall());
    return axios
      .get(`${config.API_GATEWAY.URL}/AppealWorkflow/search/reference/${referenceCode}`,
        { headers: { "content-type": "application/json" } }
      )
      .then(response => {
        const teamAppeal = response.status === 200
          ?  mapTeamAppeal(response.data)
          : null;
        dispatch(getAppealByReferenceCodeSuccessAction(referenceCode, teamAppeal))
      })
      .catch(error => {
        dispatch(ajaxCallError(error));
        throw error;
      });
  };
};

export const getAppealTemplates = () => {
  return (dispatch: ThunkDispatch<StoreState, void, getAppealTemplatesSuccessAction>) => {
    dispatch(beginAjaxCall());
    return axios
      .get(`${config.API_GATEWAY.URL}/appeal/appealtemplates`,
        { headers: { "content-type": "application/json" } }
      )
      .then(response => {
        const appealTemplates = response.data
        dispatch(getAppealTemplatesSuccessAction(
          _.map(appealTemplates, appealTemplate => {
            const at = new AppealTemplate();
            at.appealTypeId = appealTemplate.appealTypeId;
            at.outcomeId = appealTemplate.outcomeId;
            at.pdfTemplate = appealTemplate.pdfTemplate;
            at.reasonTemplate = appealTemplate.reasonTemplate;
            at.requiresReason = appealTemplate.requiresReason;
            return at;
          })
        ))
      })
      .catch(error => {
        dispatch(ajaxCallError(error));
        throw error;
      });
  };
};



export const getMyTeamAppeals = (teamId: number, appealSearch: AppealSearch) => {
  return (dispatch: ThunkDispatch<StoreState, void, getMyTeamAppealsSuccessAction>) => {
    dispatch(beginAjaxCall());
    return axios
      .post(`${config.API_GATEWAY.URL}/AppealWorkflow/search/user`,
        JSON.stringify({ teamId, ...appealSearch }),
        { headers: { "content-type": "application/json" } }
      )
      .then(response => {
        const appeals = _.map(response.data.page, appeal => {
          return mapTeamAppeal(appeal);
        })
        dispatch(getMyTeamAppealsSuccessAction(teamId, appealSearch, appeals))
      })
      .catch(error => {
        dispatch(ajaxCallError(error));
        throw error;
      });
  };
};

export const getTeams = () => {
  return (dispatch: ThunkDispatch<StoreState, void, getTeamsSuccessAction>) => {
    dispatch(beginAjaxCall());
    return axios
      .get(`${config.API_GATEWAY.URL}/appeal/appealteams`,
        { headers: { "content-type": "application/json" } }
      )
      .then(response => {
        const teams = response.data
        dispatch(getTeamsSuccessAction(
          _.orderBy(_.map(teams, team => {
            const t = new Team();
            t.id = team.id;
            t.name = team.name;
            return t;
          }), ["name"])
        ))
      })
      .catch(error => {
        dispatch(ajaxCallError(error));
        throw error;
      });
  };
};

export const getTeamAppeals = (teamId: number, appealSearch: AppealSearch) => {
  return (dispatch: ThunkDispatch<StoreState, void, getTeamAppealsSuccessAction>) => {
    dispatch(beginAjaxCall());
    return axios
      .post(`${config.API_GATEWAY.URL}/AppealWorkflow/search/team`,
        JSON.stringify({ teamId, ...appealSearch }),
        { headers: { "content-type": "application/json" } }
      )
      .then(response => {
        const appeals = _.map(response.data.page, appeal => {
          return mapTeamAppeal(appeal);
        })
        dispatch(getTeamAppealsSuccessAction(teamId, appealSearch, appeals))
      })
      .catch(error => {
        dispatch(ajaxCallError(error));
        throw error;
      });
  };
};

export const getUnallocatedTeamAppeals = (teamId: number, appealSearch: AppealSearchPaging, loadMoreWork: boolean) => {
  return (dispatch: ThunkDispatch<StoreState, void, getUnallocatedTeamAppealsSuccessAction>) => {
    dispatch(beginAjaxCall());
    return axios
      .post(`${config.API_GATEWAY.URL}/AppealWorkflow/search/work`,
        JSON.stringify({ teamId, ...appealSearch }),
        { headers: { "content-type": "application/json" } }
      )
      .then(response => {
        const token = response.data.token;
        const appeals = _.map(response.data.appeals, appeal => {
          return mapTeamAppeal(appeal);
        })
        const hasMoreWork = appealSearch.pageSize === appeals.length;
        dispatch(getUnallocatedTeamAppealsSuccessAction(
          teamId,
          loadMoreWork,
          { ...appealSearch, hasMoreWork, token },
          appeals
        ));
      })
      .catch(error => {
        dispatch(ajaxCallError(error));
        throw error;
      });
  };
};

export const setActiveIdx = (activeIdx: number): SetActiveIdxAction => ({
  type: SET_APPEALS_MANAGEMENT_ACTIVE_IDX,
  activeIdx
});

export const setTeamId = (teamId: number) => {
  return (dispatch: ThunkDispatch<StoreState, void, setTeamIdAction>) => {
    dispatch(setTeamIdAction(teamId));
  };
};

export const setTeamWorkSearch = (teamWorkSearch: AppealSearch) => {
  return (dispatch: ThunkDispatch<StoreState, void, setTeamWorkSearchAction>) => {
    dispatch(setTeamWorkSearchAction(teamWorkSearch));
  };
};

export const transferAppeal = (
  learningProviderId: string,
  appealId: string,
  teamId: number) => {
  return (dispatch: ThunkDispatch<StoreState, void, transferAppealSuccessAction>) => {
    dispatch(beginAjaxCall());
    return axios
      .post(`${config.API_GATEWAY.URL}/appeal/${learningProviderId}/appeal/${appealId}/transfer/${teamId}`,
        { headers: { "content-type": "application/json" } }
      )
      .then((response) => {
        dispatch(transferAppealSuccessAction(learningProviderId, appealId, teamId));
      })
      .catch(error => {
        dispatch(ajaxCallError(error));
        throw error;
      });
  };
};

export const updateAppealStatus = (
  learningProviderId: string,
  appealId: string,
  statusId: number,
  note: string) => {
  return (dispatch: ThunkDispatch<StoreState, void, updateAppealStatusSuccessAction>, getState: any) => {
    dispatch(beginAjaxCall());
    return axios
      .post(`${config.API_GATEWAY.URL}/appeal/${learningProviderId}/appeal/${appealId}/status/${statusId}`,
        JSON.stringify({ note }),
        { headers: { "content-type": "application/json" } }
      )
      .then((response) => {
        dispatch(updateAppealStatusSuccessAction(learningProviderId, appealId, statusId, note));
      })
      .catch(error => {
        dispatch(ajaxCallError(error));
        throw error;
      });
  };
};

export const reOpenAppeal = (
  learningProviderId: string,
  appealId: string) => {
  return (dispatch: ThunkDispatch<StoreState, void, reOpenAppealSuccessAction>, getState: any) => {
    dispatch(beginAjaxCall());
    return axios
      .post(`${config.API_GATEWAY.URL}/appeal/${learningProviderId}/appeal/${appealId}/reopen`,
        null,
        { headers: { "content-type": "application/json" } }
      )
      .then((response) => {
        dispatch(reOpenAppealSuccessAction(learningProviderId, appealId));
      })
      .catch(error => {
        dispatch(ajaxCallError(error));
        throw error;
      });
  };
};

export const switchAppealType = (
  learningProviderId: string,
  appealId: string,
  appealTypeId: number) => {
  return (dispatch: ThunkDispatch<StoreState, void, switchAppealTypeSuccessAction>, getState: any) => {
    dispatch(beginAjaxCall());
    return axios
      .post(`${config.API_GATEWAY.URL}/appeal/${appealId}/appealType/${appealTypeId}/switch`)
      .then((response) => {
        dispatch(switchAppealTypeSuccessAction(learningProviderId, appealId, appealTypeId));
      })
      .catch(error => {
        dispatch(ajaxCallError(error));
        throw error;
      });
  };
};

const mapTeamAppeal = (teamAppeal: any) => {
  const ta = new TeamAppeal();
  ta.appealId = teamAppeal.appealId;
  ta.appealTypeId = teamAppeal.appealTypeId;
  ta.appealTypeName = teamAppeal.appealTypeName;
  ta.assignedPearsonUserId = teamAppeal.assignedPearsonUserId;
  ta.assignedUserName = teamAppeal.assignedUserName;
  ta.lastUpdatedDateTime = new Date(teamAppeal.lastUpdatedDateTime);
  ta.learnerCount = teamAppeal.learnerCount;
  ta.learningProviderCode = teamAppeal.learningProviderCode;
  ta.learningProviderId = teamAppeal.learningProviderId;
  ta.learningProviderName = teamAppeal.learningProviderName;
  ta.statusId = teamAppeal.statusId;
  ta.submittedDateTime = new Date(teamAppeal.submittedDateTime);
  ta.teamId = teamAppeal.teamId;
  ta.teamName = teamAppeal.teamName;
  ta.ucasLearners = teamAppeal.ucasLearners;
  ta.qualificationGroupId = teamAppeal.qualificationGroupId;
  return ta;
}

export type AppealManagementActions =
  AddAppealNoteSuccessAction |
  AssignAppealSuccessAction |
  CloseAppealSuccessAction |
  GetAppealByReferenceCodeSuccessAction |
  GetAppealTemplatesSuccessAction |
  GetMyTeamAppealsSuccessAction |
  GetTeamsSuccessAction |
  GetTeamAppealsSuccessAction |
  GetUnallocatedTeamAppealsSuccessAction |
  SetActiveIdxAction |
  SetTeamIdAction |
  SetTeamWorkSearchAction |
  TransferAppealSuccessAction |
  UpdateAppealStatusSuccessAction |
  SwitchAppealTypeSuccessAction
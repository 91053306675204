
export class Option {
  name: string;
  value: string | number;
  disabled?: boolean = false;
}

export const EditLearnerGradeSearchOptions: Option[] = [
  { name: "All", value: "All" },
  { name: "Learners with warnings", value: "LearnerGradesWithWarnings" },
  { name: "Learners not processed", value: "LearnerGradesNotProcessed" },
  { name: "Learners processed", value: "LearnerGradesProcessed" }
];

export const LearnerAppealSearchOptions: Option[] = [
  { name: "All learners", value: "All" },
  { name: "Learners in appeal", value: "LearnersInAppeal" },
];

export const LearnerGradeSearchOptions: Option[] = [
  { name: "All", value: "All" },
  { name: "Errors in file", value: "ErrorsInFile" }, 
  { name: "Learners with warnings", value: "LearnerGradesWithWarnings" },
  { name: "Learners not processed", value: "LearnerGradesNotProcessed" },
  { name: "Learners processed", value: "LearnerGradesProcessed" }
];

export const QualificationSearchOptions: Option[] = [
  { name: "All", value: "All" },
  { name: "No data received", value: "NoDataReceived" },
  { name: "Processed with errors", value: "PartiallyProcessed" },
  { name: "Processed", value: "Processed" },
  { name: "Processing error", value: "ProcessingError" },
  { name: "Rejected", value: "Rejected" }
];

export const QualificationSortByOptions: Option[] = [
  { name: "Name", value: "name" },
  { name: "Code", value: "code" },
  { name: "Number of learners", value: "numberOfLearnersString,name" },
  { name: "Learners processed", value: "learnersProcessedString,name" },
  { name: "Last updated", value: "lastUpdated,name" },
  { name: "Status", value: "statusSortOrder,name" }
];

export const QualificationAppealSortByOptions: Option[] = [
  { name: "Name", value: "name" },
  { name: "Code", value: "code" },
  { name: "Number of learners", value: "numberOfLearnersString,name" },
  { name: "Number of appeals", value: "numberOfAppealsString,name" }
];

export const FileHistorySearchOptions: Option[] = [
  { name: "All", value: "All" },
  { name: "Processed with errors", value: "PartiallyProcessed" },
  { name: "Processed", value: "Processed" },
  { name: "Processing error", value: "ProcessingError" },
  { name: "Rejected", value: "Rejected" }
];

export const FileHistorySortByOptions: Option[] = [
  { name: "Uploaded date", value: "uploadedDate" },
  { name: "File name", value: "fileName,uploadedDate" },
  { name: "Status", value: "statusSortOrder,uploadedDate" },
  { name: "Processed", value: "processed,uploadedDate" },
  { name: "Warning", value: "warning,uploadedDate" },
  { name: "Type", value: "uploadType,uploadedDate" }
];

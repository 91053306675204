import * as React from 'react';
import { Link } from "react-router-dom";
import { RouteComponentProps, Redirect } from "react-router";
import Loader from "../../components/pearson/loader";
import { Qualification } from '../../models/qualification';
import FileCardList from './components/file-card-list';
import { UploadFileHistory } from '../../models/upload-file-history';
import { FileHistorySearchOption } from '../../types/file-history-search-option';
import { UploadFileHistoryPageState } from '../../models/page-state';
import { SortDirection } from '../../types/sort-direction';

export interface StateProps {
    learningProviderId: string | null;
    qualification: Qualification | undefined;
    uploadFileHistories: UploadFileHistory[] | null;
    pageState: UploadFileHistoryPageState;
}

export interface DispatchProps {
    getUploadFileHistory: (
      learningProviderId: string,
      qualificationId: string
    ) => void;
    setPageState : (
        pageState: UploadFileHistoryPageState
    ) => void;
    downloadArchiveCsv: (
      learningProviderId: string, 
      qualificationId: string, 
      versionId: string, 
      fileName: string
    ) => void;
}

export type OwnProps = RouteComponentProps<{ qualificationId: string }>;
interface Props extends DispatchProps, StateProps, OwnProps { }

export interface LocalState {
    loading: boolean;
    backToQualification: boolean;
  }

export class FileHistory extends React.Component<Props, LocalState> {
  constructor(props: Props) {
    super(props);
    this.state = {
        loading: true,
        backToQualification: this.props.location.pathname.endsWith('qualifications')
    }
    document.title = "File History";
  }
  render = () => {
    const {
        learningProviderId,
        qualification,
        uploadFileHistories,
        pageState
    } = this.props;

    const {
        loading
      } = this.state;

    if (!learningProviderId || !qualification) {
    return <Redirect to="/" />
    }

    return (
      <div className="file-history">
        <Loader loading={loading} loadingStateLabel="Loading file history..." />
        <div className="header jumbotron">
          { this.state.backToQualification ?
            <Link
              className="back-link"
              to={`/qualifications/${qualification.qualificationGroupId}`}>
              &lt;&lt; Back to {qualification.qualificationGroupId}
            </Link>
            :
            <Link
              className="back-link"
              to={`/qualifications/${qualification.qualificationGroupId}/${qualification.id}/learners`}>
              &lt;&lt; Back to {qualification.name}
            </Link>
          }         
          <h1>{qualification.name} - {document.title}</h1>
          {!loading && uploadFileHistories && (
            <>
                <FileCardList 
                    uploadFileHistories={uploadFileHistories} 
                    searchOption={pageState.searchOption}
                    searchText={pageState.searchText}
                    sortBy={pageState.sortBy}
                    sortDirection={pageState.sortDirection}
                    onSearch={this.onSearch}
                    onSort={this.onSort}
                    onDownload={this.onDownload} />
            </>
          )}
        </div>
      </div>
    );
  };

  componentDidMount = () => {
    const { learningProviderId, qualification, getUploadFileHistory } = this.props;
    if (learningProviderId && qualification) {
      this.setState({ loading: true });
      Promise.all([
        getUploadFileHistory(learningProviderId, qualification.id)
      ])
      .catch()
      .finally(() => this.setState({ loading: false }));
    }
  };

  onSearch = (searchOption: FileHistorySearchOption, searchText: string) => {    
    this.props.setPageState({ ...this.props.pageState, 
      searchOption, 
      searchText});
  };

  onSort = (sortBy: string, sortDirection: SortDirection) => {
    this.props.setPageState({
      ...this.props.pageState,
      sortBy,
      sortDirection
    })
  };

  onDownload = (versionId: string, fileName: string) => {
    const { learningProviderId, qualification } = this.props;
    if (learningProviderId && qualification) {
      this.props.downloadArchiveCsv(learningProviderId, qualification.id, versionId, fileName);
    }
  };
}

export default FileHistory;

import { connect } from "react-redux";
import { StoreState } from "store/store-state";
import { ThunkDispatch } from "redux-thunk";
import _ from "lodash";
import { CsvActions, downloadCsv, uploadCsv } from "../../actions/csv.actions";
import { LearnerGradeActions, getLearnerGrades, saveLearnerGrades } from "../../actions/learner-grade.actions";
import { QualificationActions, getQualification } from "../../actions/qualification.actions";
import { PageStateActions, setLearnerGradesPageState } from "../../actions/page-state.actions";
import { UploadFileActions, getUploadFile, refreshUploadFile } from "../../actions/upload-file.actions";
import { CsvUploadFile } from "../../models/csv-upload-file";
import { LearnerGrade } from "../../models/learner-grade";
import { LearnerGradesPageState } from "../../models/page-state";
import { Qualification } from "../../models/qualification";

import {
  LearnerGrades,
  StateProps,
  OwnProps,
  DispatchProps,
} from "./learner-grades";

const mapStateToProps = (state: StoreState, ownProps: OwnProps): StateProps => {
  const qualificationId = ownProps.match.params.qualificationId;
  return {
    csvUploadStatus: state.csvUploadStatus,
    internal: state.user ? state.user.isInternal : false,
    showAppeals: state.user ? state.user.showAppeals : false,
    learningProviderId: state.selectedLearningProviderId,
    learners: state.learnerGrades,
    qualification: _.find(state.qualifications, { "id": qualificationId }),
    pageState: state.pageState.learnerGradesPageState,
    uploadFile: state.uploadFile
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<StoreState, void,
  CsvActions |
  LearnerGradeActions |
  PageStateActions |
  UploadFileActions |
  QualificationActions
>)
  : DispatchProps => ({
    downloadCsvFile: (
      learningProviderId: string,
      qualification: Qualification) =>
      downloadCsv(learningProviderId, qualification),
    getLearnerGrades: (
      learningProviderId: string,
      qualification: Qualification) =>
      dispatch(getLearnerGrades(learningProviderId, qualification)),
    getUploadFile: (
      learningProviderId: string,
      qualificationId: string) =>
      dispatch(getUploadFile(learningProviderId, qualificationId)),
    refreshUploadFile: (
      learningProviderId: string,
      qualificationId: string,
      version: string | undefined) =>
      dispatch(refreshUploadFile(learningProviderId, qualificationId, version)),
    saveLearnerGrades: (
      learningProviderId: string,
      qualificationId: string,
      learners: LearnerGrade[]) =>
      dispatch(saveLearnerGrades(learningProviderId, qualificationId, learners)),
    setPageState: (pageState: LearnerGradesPageState) =>
      dispatch(setLearnerGradesPageState(pageState)),
    uploadCsvFile: (
      csvUploadFile: CsvUploadFile) =>
      dispatch(uploadCsv(csvUploadFile)),
    getQualification: (
      learningProviderId: string,
      qualificationId: string) =>
      dispatch(getQualification(learningProviderId, qualificationId)),
  });
export default connect<StateProps>(
  mapStateToProps,
  mapDispatchToProps
)(LearnerGrades);


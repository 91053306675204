import React, { ReactElement } from "react";
import Dropdown, { DropdownOption } from "./dropdown";
import Icon from "./icon";

// import "./tooltip.css";
// import Checkbox from "./checkbox";

// require("@pearson-ux/search-card");

interface SearchCardProps {
  id: string;
  headerElement: ReactElement;
  selected: boolean;
  informationMessage: string | JSX.Element | null;
  errorMessage: string | null;
  successMessage: string | null;
  warningMessage: string | null;
  dropdownMenuRight: boolean;
  actions?: DropdownOption[];  
  onSelect?: (id: string, selected: boolean) => void;
}

const SearchCard = ({
  id,
  headerElement,
  selected,
  informationMessage,
  errorMessage,
  successMessage,
  warningMessage,
  dropdownMenuRight,
  actions,
  // onSelect
}: SearchCardProps) => {

  const showIcon = informationMessage || errorMessage || successMessage || warningMessage;

  return (
    <div id={id} className="search-card">
      <div className="catalog card-container">
        <div className="card">
          <div className="info">
            {headerElement}           
            {/* {onSelect && (
             <div className="checkbox">
                <Checkbox
                  id={id}
                  checked={selected}
                  onChange={(e) => onSelect(id, e.currentTarget.checked)}
                />             
              </div>
            )} */}
            {showIcon && (
              <div className="icons">
                {informationMessage && (
                  <span data-tooltip={informationMessage}>
                    <Icon icon="information" size="24" fill="information" />
                  </span>
                )}
                {errorMessage && (
                  <span data-tooltip={errorMessage}>
                    <Icon icon="warning" size="24" fill="error" />
                  </span>
                )}
                {warningMessage && (
                  <span data-tooltip={warningMessage}>
                    <Icon icon="warning" size="24" fill="warning" />
                  </span>
                )}
                {successMessage && (
                  <span data-tooltip={successMessage}>
                    <Icon icon="correct" size="24" fill="success" />
                  </span>
                )}
              </div>
           )}
            {actions && actions.length > 0 && (
              <div className="actions">
                <Dropdown type="more" tooltip="Actions" dropdownMenuRight={dropdownMenuRight} options={actions} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

SearchCard.defaultProps = {
  selected: false,
  informationMessage: null,
  errorMessage: null,
  successMessage: null,
  warningMessage: null,
  dropdownMenuRight: false,
  actions: [],
};

export default SearchCard;

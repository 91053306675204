import { Reducer } from "redux";
import initialState from './initialState';
import { QualificationGroup } from "../models/qualification-group";
import { LearningProviderActions } from "../actions/learning-provider.actions";
import { QualificationGroupActions } from "../actions/qualification-group.actions";
import {
  GET_QUALIFICATION_GROUPS_SUCCESS,
  SET_QUALIFICATION_GROUP,
  SET_LEARNING_PROVIDER
} from "../actions/action-types";

export const selectedQualificationGroupIdReducer: Reducer<string | null> = (
  state: string | null = initialState.selectedQualificationGroupId,
  action: QualificationGroupActions
) => {
  switch (action.type) {
    case SET_QUALIFICATION_GROUP:
      return Object.assign({}, action.qualificationGroupId)
    default:
      return state;
  }
}

export function qualificationGroupsReducer(
  state: QualificationGroup[] = initialState.qualificationGroups,
  action: LearningProviderActions |
    QualificationGroupActions
): QualificationGroup[] {
  switch (action.type) {
    case GET_QUALIFICATION_GROUPS_SUCCESS:
      return [...action.qualificationGroups];
    case SET_LEARNING_PROVIDER:
      return [...initialState.qualifications];
    default:
      return state;
  }
};


import { StoreState } from "../store/store-state";
import AuthForgeRock from "../actions/auth/auth";

const authForgeRock = new AuthForgeRock();
const teamId = localStorage.getItem("teamId");

export const InitialState: StoreState = {
  ajaxCallsInProgress: 0,
  appealDetail: null,
  appeals: [],
  appealFileUploadStatus: null,
  appealsManagement: {
    activeIdx: 0,
    teamId: teamId ? parseInt(teamId) : null,
    myWork: [],
    myWorkSearch: {
      statusId: 0,
      pageNo: 1,
      pageSize: 100000,
      appealTypeId: 0,
      showNoAppealsMessage: false,
      sortField: "submittedDateTime",
      sortDirection: "asc",
      searchText: "",
      ucasOnly: false,
      assignedPearsonUserId: "All"
    },
    referenceCodeAppeal: null,
    referenceCodeIsValid: true,
    referenceCodeSearch: "",
    teamWork: [],
    teamWorkSearch: {
      statusId: 0,
      pageNo: 1,
      pageSize: 100000,
      appealTypeId: 0,
      showNoAppealsMessage: false,
      sortField: "submittedDateTime",
      sortDirection: "asc",
      searchText: "",
      ucasOnly: false,
      assignedPearsonUserId: "All"
    },
    unallocatedWork: [],
    unallocatedWorkSearch: {
      appealTypeId: 0,
      hasMoreWork: false,
      pageSize: 10,
      previous: false,
      showNoAppealsMessage: false,
      statusId: 2,
      token: null,
      ucasOnly: false
    },
  },
  appealOutcomes: [
    { id: 5, name: "Rejected (Insufficient Evidence)" },
    { id: 1, name: "Rejected (Not Allowed)" },
    { id: 2, name: "Invalid Application" },
    { id: 3, name: "Partially Upheld (Allowed)" },
    { id: 4, name: "Upheld (Allowed)" }
  ],
  appealStates: [
    { id: 1, name: "Incomplete", 
      propertyName: "appealsIncompleteCount", 
      internalOnly: false, 
      appealWorkflow: false},
    { id: 2, name: "Acknowledged", 
      propertyName: "appealsAcknowledgedCount", 
      internalOnly: false, 
      appealWorkflow: true },
    { id: 4, name: "Awaiting further information", 
      propertyName: "appealsAwaitingFutherInformationCount", 
      internalOnly: false, 
      appealWorkflow: true },
    { id: 3, name: "In progress", 
      propertyName: "appealsInProgressCount", 
      internalOnly: false, 
      appealWorkflow: true },
    { id: 5, name: "Closed (pending outcome letter)",
      propertyName: "appealsClosedPendingOutcomeLetterCount", 
      internalOnly: true, 
      appealWorkflow: true },
    { id: 6, name: "Closed", 
      propertyName: "appealsCompleteCount", 
      internalOnly: false, 
      appealWorkflow: true }
  ],
  appealTemplates: [],
  appealTypes: [],
  auth: {
    error: "",
    timestamp: 0,
    loading: true,
    authenticated: authForgeRock.isAuthenticated(),
    profile: authForgeRock.getProfile(),
  },
  callback: {
    error: "",
    loading: false,
  },
  csvUploadStatus: null,
  // error: null,
  learnerGrades: [],
  pageState: {
    allAppealsPageState: {
      searchOption: "All",
      searchText: "",
      sortBy: "qualificationDisplayName",
      sortDirection: "asc",
    },   
    appealsPageState: {
      searchOption: "All",
      searchText: "",
      sortBy: "statusId,appealTypeId",
      sortDirection: "asc",
    },
    learnerGradesPageState: {
      searchOption: "All",
      searchText: "",
      notProcessedSortBy: "forenames",
      notProcessedSortDirection: "asc",
      processedSortBy: "forenames",
      processedSortDirection: "asc",
      withWarningsSortBy: "forenames",
      withWarningsSortDirection: "asc"
    },
    qualificationsPageState: {
      searchOption: "All",
      searchText: "",
      sortBy: "name",
      sortDirection: "asc",
    },
    uploadFileHistoryPageState: {
      searchOption: "All",
      searchText: "",
      sortBy: "uploadedDate",
      sortDirection: "desc",
    }
  },
  qualificationGroups: [],
  qualifications: [],
  selectedLearningProviderId: null,
  selectedQualificationGroupId: null,
  teams: [],
  user: null,
  uploadFile: null,
  uploadFileHistories: null,
};

export default InitialState;

import axios from 'axios';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { ajaxCallError, beginAjaxCall, AjaxCallActions } from './ajax-status.actions';
import { StoreState } from '../store/store-state';
import config from '../app.config';
import _ from 'lodash';
import { GET_QUALIFICATIONS_SUCCESS, GET_QUALIFICATION_SUCCESS } from './action-types';
import { Qualification } from '../models/qualification';
import moment from 'moment';
import { QualificationStatusCode } from '../types/qualification-status-code';
import { User } from '../models/user';

interface GetQualificationSuccessAction extends Action {
  type: GET_QUALIFICATION_SUCCESS;
  qualification: Qualification;
}

interface GetQualificationsSuccessAction extends Action {
  type: GET_QUALIFICATIONS_SUCCESS;
  qualifications: Qualification[];
}

export type getQualificationSuccessAction =
  GetQualificationSuccessAction |
  AjaxCallActions;

export type getQualificationsSuccessAction =
  GetQualificationsSuccessAction |
  AjaxCallActions;

export const getQualificationSuccessAction = (
  qualification: Qualification
): GetQualificationSuccessAction => ({
  qualification,
  type: GET_QUALIFICATION_SUCCESS
});

export const getQualificationsSuccessAction = (
  qualifications: Qualification[]
): GetQualificationsSuccessAction => ({
  qualifications,
  type: GET_QUALIFICATIONS_SUCCESS
});

export const getQualification = (learningProviderId: string, qualificationId: string) => {
  return (dispatch: ThunkDispatch<StoreState, void, getQualificationSuccessAction>, getState: any) => {
    dispatch(beginAjaxCall());
    return axios
      .get(`${config.API_GATEWAY.URL}/qualification/${qualificationId}/learningprovider/${learningProviderId}`)
      .then(response => {
        const qualification = mapQualification(response.data, getState().user);
        dispatch(getQualificationSuccessAction(qualification));
      })
      .catch(error => {
        dispatch(ajaxCallError(error));
        throw error;
      });
  };
};

export const getQualifications = (learningProviderId: string, qualificationGroupId: string) => {
  return (dispatch: ThunkDispatch<StoreState, void, getQualificationsSuccessAction>, getState: any) => {   
    dispatch(beginAjaxCall());
    return axios
      .get(`${config.API_GATEWAY.URL}/qualification/learningprovider/${learningProviderId}/${qualificationGroupId}`)
      .then(response => {
        const qualifications = response.data;
        dispatch(getQualificationsSuccessAction(
          _.map(qualifications, (qualification) => {
            return mapQualification(qualification, getState().user);
          })
        ))
      })
      .catch(error => {
        dispatch(ajaxCallError(error));
        throw error;
      });
  };
};

const mapQualification = (qualification: any, user: User) => {
  const q = new Qualification();
  if (qualification) {
    q.id = qualification.id;
    q.appealsAcknowledgedCount = qualification.appealsAcknowledgedCount;
    q.appealsAcknowledgedCountString = padStart(qualification.appealsAcknowledgedCount);
    q.appealsAwaitingFutherInformationCount = qualification.appealsAwaitingFutherInformationCount;
    q.appealsAwaitingFutherInformationCountString = padStart(qualification.appealsAwaitingFutherInformationCount);

    if(user.isInternal) {
      q.appealsClosedCount = qualification.appealsClosedCount;
      q.appealsClosedCountString = padStart(qualification.appealsClosedCount);
      q.appealsClosedPendingOutcomeLetterCount = qualification.appealsClosedPendingOutcomeLetterCount;
      q.appealsClosedPendingOutcomeLetterCountString = padStart(qualification.appealsClosedPendingOutcomeLetterCount);
    } else {
      const closedCount = qualification.appealsClosedCount + qualification.appealsClosedPendingOutcomeLetterCount;
      q.appealsClosedCount = closedCount;
      q.appealsClosedCountString = padStart(closedCount);
      q.appealsClosedPendingOutcomeLetterCount = 0;
      q.appealsClosedPendingOutcomeLetterCountString = "0";
    }
    
    q.appealsIncompleteCount = qualification.appealsIncompleteCount;
    q.appealsIncompleteCountString = padStart(qualification.appealsIncompleteCount);    
    q.appealsInProgressCount = qualification.appealsInProgressCount;
    q.appealsInProgressCountString = padStart(qualification.appealsInProgressCount);
    q.code = qualification.code;
    q.gradable = qualification.gradable;
    q.lastUpdated = new Date(qualification.lastUpdated);
    q.learnersProcessed = qualification.gradesProcessed;
    q.learnersProcessedString = padStart(qualification.gradesProcessed);
    q.name = qualification.name;
    q.numberOfAppeals = qualification.appealCount;
    q.numberOfAppealsString = padStart(qualification.appealCount);
    q.numberOfLearners = qualification.numberOfLearners;
    q.numberOfLearnersString = padStart(qualification.numberOfLearners);
    q.qualificationGroupId = qualification.qualificationGroupId;
    q.rankable = qualification.rankable;
    q.readOnly = qualification.readOnly;
    q.status = getStatusDescription(qualification.status);
    q.statusCode = qualification.status;
    q.statusSortOrder = getStatusSortOrder(qualification.status);
  
    q.hasAppeals = qualification.appealCount ?? 0;

    q.hasErrors = q.statusCode === "PartiallyProcessed" ||
      q.statusCode === "Rejected" ||
      q.statusCode === "ProcessingError";

    q.hasWarnings = q.statusCode === "NoDataReceived";

    q.isProcessed = q.statusCode === "Processed";

    q.displayName = `${q.qualificationGroupId} ${q.name} ${q.code}`;

    const searchString = `${q.name} ${q.code} ${q.numberOfLearners ?? ""}`;

    q.searchString = (user.showAppeals
      ? `${searchString} ${q.numberOfAppeals}`
      : `${searchString} ${q.learnersProcessed ?? ""} ${q.status} 
        ${q.lastUpdated
          ? moment(q.lastUpdated).format("DD/MM/YYYY HH:mm")
          : ""}`).toLowerCase();
  }
  return q;
}

export const getStatusSortOrder = (statusCode: QualificationStatusCode) => {
  switch (statusCode) {
    case "ProcessingError": return "1";
    case "Rejected": return "2";
    case "NoDataReceived": return "3";
    case "PartiallyProcessed": return "4";
    case "Processed": return "5";
    default:
      return "0";
  }
}

export const getStatusDescription = (statusCode: QualificationStatusCode) => {
  switch (statusCode) {
    case "ProcessingError": return "Processing error";
    case "Rejected": return "Rejected";
    case "NoDataReceived": return "No data received";
    case "PartiallyProcessed": return "Processed with errors";
    case "Processed": return "Processed";
    default:
      return "";
  }
}

const padStart = (number: number | null) => {
  return number ? _.padStart(number.toString(), 5, '0') : "";
}

export type QualificationActions =
  | GetQualificationsSuccessAction
  | getQualificationSuccessAction
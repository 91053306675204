import * as React from "react";
import { useSelector } from "react-redux";
import { StoreState } from "../../../store/store-state";
import AppealStateSelect from "./selects/appeal-status-select";
import AppealTypeSelect from "./selects/appeal-type-select";
import Button from "../../../components/pearson/button";
import Toggle from "../../../components/pearson/toggle";
import PageSizeSelect from "./selects/page-size";

interface AppealSearchProps {
  appealTypeId: number;
  pageSize: number;
  statusId: number;
  ucasOnly: boolean;
}

interface TeamAppealSearchProps {
  appealSearch: AppealSearchProps;
  appealStateOptional: boolean;
  disableSearch: boolean;
  showPageSize: boolean;
  onChange: (appealSeach: AppealSearchProps) => void;
  onSearch: () => void;
}

export const TeamAppealSearch = ({
  appealSearch,
  appealStateOptional,
  disableSearch,
  showPageSize,
  onChange,
  onSearch
}: TeamAppealSearchProps) => {

  const appealTypes = useSelector((state: StoreState) => state.appealTypes);

  const appealStateChange = (statusId: number) => {
    onChange({ ...appealSearch, statusId })
  }
  const appealTypeChange = (appealTypeId: number) => {
    onChange({ ...appealSearch, appealTypeId })
  }
  const pageSizeChange = (pageSize: number) => {
    onChange({ ...appealSearch, pageSize })
  }
  const search = () => {
    onSearch();
  }
  const ucasOnlyChange = () => {
    onChange({ ...appealSearch, ucasOnly: !appealSearch.ucasOnly })
  }

  return (
    <div className="team-appeal-search">
      <AppealStateSelect
        statusId={appealSearch.statusId}
        optional={appealStateOptional}
        onChange={appealStateChange}
      />
      <AppealTypeSelect
        appealTypeId={appealSearch.appealTypeId}
        appealTypes={appealTypes}
        includeAllOption={true}
        onChange={appealTypeChange}
      />
      {showPageSize && (
        <PageSizeSelect
          pageSize={appealSearch.pageSize}
          onChange={pageSizeChange}
        />
      )}
      <Toggle
        id="ucas"
        label="UCAS only:"
        on={appealSearch.ucasOnly}
        value={appealSearch.ucasOnly}
        onChange={ucasOnlyChange}
      />  
      <div className="search-button">
        <Button
          label="Search"
          className="attention"
          disabled={disableSearch}
          onClick={search}
        />
      </div>
    </div>  
  )
};

export default TeamAppealSearch;

import * as React from "react";
import { RouteComponentProps, Redirect } from "react-router";
import { Link } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import { AppealDetail } from "../../../models/appeal-detail";
import { AppealFileUpload } from "../../../models/appeal-file-upload";
import { AppealTemplate } from "../../../models/appeal-template";
import { alert } from "../../../components/pearson/alert";
import AppealDetails from "../components/appeal-details";
import Loader from "../../../components/pearson/loader";
import {Dropdown, DropdownOption} from "../../../components/pearson/dropdown";
import Button from "../../../components/pearson/button";
import { User } from "../../../models/user";
import { Team } from "../../../models/team";
import AddNoteModal from "../../appeals-management/components/modals/add-note-modal";
import CloseAppealModal from "../../appeals-management/components/modals/close-appeal-modal";
import MoveAppealModal from "../../appeals-management/components/modals/move-appeal-modal";
import TransferTeamModal from "../../appeals-management/components/modals/transfer-team-modal";
import SwitchAppealTypeModal from "../../appeals-management/components/modals/switch-appeal-type-modal";
import TeamAppealNoteCardList from "../../../features/appeals-management/components/team-appeal-note-card-list";
import { AWAITING_FURTHER_INFOMATION, CLOSED, CLOSED_PENDING_OUTCOME_LETTER, IN_PROGRESS } from "../../../models/appeal-state";
import AppealStateDescription from "../components/appeal-state-description";
import AppealOutcomeDescription from "../components/appeal-outcome-description";
import ReOpenAppealModal from "../../appeals-management/components/modals/re-open-appeal-modal";
import { AppealType } from "../../../models/appeal-type";

export interface StateProps {
  appealId: string;
  appeal: AppealDetail | null;
  appealTemplates: AppealTemplate[];
  assignedToMe: boolean;
  learningProviderId: string | null;
  showAppealManagement: boolean;
  teams: Team[];
  appealTypes: AppealType[];
  user: User | null;
}

export interface DispatchProps {
  addAppealNote: (
    learningProviderId: string,
    appealId: string,
    note: string
  ) => Promise<void>;
  assignAppeal: (
    learningProviderId: string,
    appealId: string
  ) => Promise<void>;
  closeAppeal: (
    learningProviderId: string,
    appealId: string,
    outcomeId: number,
    letterText: string,
  ) => Promise<void>;
  downloadAppealEvidence: (
    learningProviderId: string,
    appealId: string,
    fileId: string) => void;
  getAppealDetails: (
    learningProviderId: string,
    appealId: string
  ) => Promise<void>;
  transferAppeal: (
    learningProviderId: string,
    appealId: string,
    teamId: number
  ) => Promise<void>;
  uploadAppealFile: (
    appealFileUpload: AppealFileUpload
  ) => Promise<void>;
  updateAppealStatus: (
    learningProviderId: string,
    appealId: string,
    statusId: number,
    note: string
  ) => Promise<void>;
  reOpenAppeal: (
    learningProviderId: string,
    appealId: string
    ) => Promise<void>;
  switchAppealType: (
    learningProviderId: string,
    appealId: string,
    appealTypeId: number
  ) => Promise<void>;
};

export type OwnProps = RouteComponentProps<{ appealId: string, learningProviderId: string }>;
interface Props extends DispatchProps, StateProps, OwnProps { }

export interface LocalState {
  loading: boolean;
  backToLinkName: string;
  backToLinkPath: string;
  newStatusId: number | null;
  saving: boolean;
  showAddNoteModal: boolean;
  showCloseAppealModal: boolean;
  showMoveAppealModal: boolean;
  showTransferTeamModal: boolean;
  showReOpenAppealModal: boolean;
  showSwitchAppealTypeModal: boolean;
}

export class ViewAppealPage extends React.Component<Props, LocalState> {
  constructor(props: Props) {
    super(props);
    document.title = props.appeal?.qualificationDisplayName ?? "";
    this.state = {
      loading: false,
      backToLinkName: "",
      backToLinkPath: "",
      newStatusId: null,
      saving: false,
      showAddNoteModal: false,
      showCloseAppealModal: false,
      showMoveAppealModal: false,
      showTransferTeamModal: false,
      showReOpenAppealModal: false,
      showSwitchAppealTypeModal: false,
    };
  }

  render = () => {
    const { appealId, appeal, appealTemplates, assignedToMe, learningProviderId, showAppealManagement, teams, appealTypes } = this.props;
    const { loading, backToLinkName, backToLinkPath, newStatusId, saving, showAddNoteModal, showCloseAppealModal, showMoveAppealModal, showTransferTeamModal, showSwitchAppealTypeModal, showReOpenAppealModal} = this.state;

    if (!learningProviderId || !appealId) {
      return <Redirect to="/" />
    }

    return (
      <div className="page view-appeal-page">
        <Loader loading={loading} loadingStateLabel="Loading appeal..." />
        <Loader loading={saving} loadingStateLabel="Saving..."/>
        {!loading && appeal && (
          <>
            <div className="header jumbotron">
              <Link 
                className="back-link"
                to={backToLinkPath}>
                &lt;&lt; {backToLinkName}
              </Link>
              <h1>Appeal</h1>
              <h2>{appeal.qualificationDisplayName}</h2>
              {showAppealManagement && (
               <>
                  <div className="summary">
                    <div className="status">
                      <dl>
                        <dt>Reference:</dt>
                        <dd>{appeal.referenceCode}</dd>
                      </dl>
                      <dl>
                        <dt>Team:</dt>
                        <dd>{appeal.team}</dd>
                      </dl>
                      <dl>
                        <dt>Allocated to:</dt>
                        <dd>{appeal.assignedUser?.username}</dd>
                      </dl>                     
                    </div>      
                    <div className="status">                      
                      <dl>
                        <dt>Status:</dt>
                        <dd>
                          <AppealStateDescription statusId={appeal.statusId} />
                        </dd>
                      </dl>                                   
                      <dl>
                        <dt>Last updated:</dt>
                        <dd>{moment(appeal.lastUpdateDateTime).format("DD/MM/YYYY HH:mm")}</dd>
                      </dl>  
                      {appeal.outcomeId && (
                        <dl>
                          <dt>Appeal outcome:</dt>
                          <dd>
                            <AppealOutcomeDescription outcomeId={appeal.outcomeId} />
                          </dd>
                        </dl>
                      )}                       
                    </div>             
                    <br/>
                  </div>
                  {appeal.statusId !== CLOSED_PENDING_OUTCOME_LETTER &&
                    appeal.statusId !== CLOSED && (
                    <div className="action-button">
                      {assignedToMe
                        ? <Dropdown
                          buttonText="Actions"
                          type="primary"
                          options={this.getActions()}
                        />
                        : <Button
                          className="primary"
                          label="Allocate to me"
                          onClick={this.allocateToMe}
                        />
                      }
                    </div>
                  )}   
                  {appeal.statusId === CLOSED_PENDING_OUTCOME_LETTER ||
                    appeal.statusId === CLOSED && (
                    <div className="action-button">
                      <Dropdown
                          buttonText="Actions"
                          type="primary"
                          options={this.getClosedActions()}
                        />
                    </div>
                  )}               
                  {showAddNoteModal && (
                    <AddNoteModal
                      show={showAddNoteModal}
                      onCancel={() => this.setState({ showAddNoteModal: false })}
                      onAddNote={this.addNote}
                    />
                  )}       
                  {showCloseAppealModal && (
                    <CloseAppealModal
                      appealTemplates={appealTemplates}
                      appealTypeId={appeal.appealTypeId}
                      show={true}
                      onCancel={this.hideCloseAppealModal}
                      onSave={this.closeAppeal}
                    />
                  )}         
                  {showMoveAppealModal && newStatusId && (
                    <MoveAppealModal
                      show={true}
                      newStatusId={newStatusId}
                      onCancel={this.hideMoveAppealModal}
                      onSave={this.updateAppealStatus}
                    />
                  )}          
                  {showTransferTeamModal && (
                    <TransferTeamModal
                      show={showTransferTeamModal}
                      teams={_.filter(teams, (team) => team.id !== appeal.teamId)}
                      onCancel={() => this.setState({ showTransferTeamModal: false })}
                      onTransfer={this.transferTeam}
                    />
                  )}     
                  {showReOpenAppealModal && (
                    <ReOpenAppealModal
                      show={showReOpenAppealModal}
                      onCancel={() => this.setState({ showReOpenAppealModal: false })}
                      onReOpen={this.reOpenAppeal}
                    />
                  )}                      
                  {showSwitchAppealTypeModal &&(
                    <SwitchAppealTypeModal
                      show={showSwitchAppealTypeModal}
                      appealTypes={_.filter(appealTypes, (appealType) => appealType.id !== appeal.appealTypeId)}
                      onCancel={() => this.setState({ showSwitchAppealTypeModal: false })}
                      onSwitch={this.switchAppealType}
                    />
                  )}                      
               </>
              )}              
            </div>
            <AppealDetails
              appealId={appeal.appealId}
              appealStatusId={appeal.statusId}
              appealTypeId={appeal.appealTypeId}
              appealEvidence={appeal.evidence}
              appealLearners={_.filter(appeal.learners, ["isInAppeal", true])}
              learningProviderId={appeal.learningProviderId}
              rationale={appeal.rationale}
              showDownloadLink={true}
              showUploadButton={appeal.statusId !== CLOSED}
              submittedByUser={appeal.submittedByUser}
              submittedDateTime={appeal.submittedDateTime}
              ucasLearners={appeal.ucasLearners ?? false}
              onDownloadAppealEvidence={this.downloadAppealEvidence}
              onUploadAppealFile={this.uploadAppealFile}
            />
            {showAppealManagement && (
              <>
                <h3>History</h3>
                <TeamAppealNoteCardList
                  appealNotes={appeal.notes}
                />
              </>
            )}
          </>
        )}
      </div>
    )
  };

  componentDidMount = () => {
    this.setState({ loading: true });
    this.getAppealDetails()
      .then(() => {
        this.setState({
          loading: false,
          backToLinkName: this.getBackToLinkName(),
          backToLinkPath: this.getBackToLinkPath()
        })
      })
      .catch((error) => {
        alert.error(error)
        this.setState({ loading: false })
      })
  };

  addNote = (note: string) => {
    const { appealId, learningProviderId, addAppealNote } = this.props;
    if (learningProviderId && appealId) {
      this.setState({ saving: true });
      addAppealNote(learningProviderId, appealId, note)
        .then(() => {
          this.getAppealDetails();
          alert.success("The note has been added to the appeal.")
        })
        .catch((error) => alert.error(error))
        .finally(() => {
          this.setState({
            saving: false,
            showAddNoteModal: false
          });
        })
    }
  }

  allocateToMe = () => {
    const { appealId, learningProviderId, assignAppeal } = this.props;
    if (appealId && learningProviderId) {
      this.setState({ saving: true });
      assignAppeal(learningProviderId, appealId)
        .then(() => {
          this.getAppealDetails();
          alert.success("The appeal has been allocated to you")
        })
        .catch((error) => {
          alert.error(error)
        })
        .finally(() => {
          this.setState({ saving: false });
        })
    }
  }

  closeAppeal = (outcomeId: number, letterText: string) => {
    const { learningProviderId, appealId, closeAppeal } = this.props;
    if (learningProviderId && appealId) {
      this.setState({ saving: true });
      closeAppeal(learningProviderId, appealId, outcomeId, letterText)
        .then(() => {
          this.getAppealDetails();
          alert.success(`The appeal has now been closed (pending outcome letter).`);
        })
        .catch((error) => {
          alert.error(error)
        })
        .finally(() => {
          this.setState({
            saving: false,
            showCloseAppealModal: false
          });
        })
    }
  }


  downloadAppealEvidence = (fileId: string) => {
    const { appealId, learningProviderId, downloadAppealEvidence } = this.props;
    if (learningProviderId && appealId) {
      downloadAppealEvidence(learningProviderId, appealId, fileId);
    }
  }

  getActions = () => {
    const { appeal } = this.props;
    const actions: DropdownOption[] = [];
    if (appeal) {
      actions.push({
        name: "Add note",
        onClick: () => this.setState({showAddNoteModal: true})
      })
      if (appeal.statusId !== AWAITING_FURTHER_INFOMATION) {
        actions.push({
          name: "Awaiting further information",
          onClick: () => this.showMoveAppealModal(AWAITING_FURTHER_INFOMATION)
        })
      }
      if (appeal.statusId !== CLOSED_PENDING_OUTCOME_LETTER && appeal.statusId !== CLOSED ) {
        actions.push({
          name: "Close appeal (pending outcome letter)",
          onClick: () => this.setState({showCloseAppealModal: true})
        })
      }
      if (appeal.statusId !== IN_PROGRESS) {
        actions.push({
          name: "Move to in progress",
          onClick: () => this.showMoveAppealModal(IN_PROGRESS)
        })
      }
      actions.push({
        name: "Transfer team",
        onClick: () => this.setState({ showTransferTeamModal: true }) 
      })
      actions.push({
        name: "Switch appeal service type",
        onClick: () => this.setState({ showSwitchAppealTypeModal: true }) 
      })
    }
    return actions;
  }

  getClosedActions = () => {
    const { appeal } = this.props;
    const actions: DropdownOption[] = [];
    if (appeal) {
      actions.push({
        name: "Re-open appeal",
        onClick: () => this.setState({showReOpenAppealModal: true})
      })
    }
    return actions;
  }

  getAppealDetails = () : Promise<void> => {
    const { appealId, learningProviderId, getAppealDetails } = this.props;
    if (appealId && learningProviderId) {
      return getAppealDetails(learningProviderId, appealId)
        .catch((error) => {alert.error(error)})
    }
    return Promise.reject()
  }

  getBackToLinkName = () => {
    const { appeal, location } = this.props;
    if (appeal && location.pathname.startsWith('/qualifications')) {
      return `Back to ${appeal.qualificationGroupId} appeals`;
    }
    if (location.pathname.startsWith('/all-appeals')) {
      return "Back to all appeals";
    }
    if (location.pathname.startsWith('/appeals-management')) {
      return "Back to appeals management";
    }
    return "/";
  }

  getBackToLinkPath = () => {
    const { appeal, location } = this.props;
    if (appeal && location.pathname.startsWith('/qualifications')) {
      return `/qualifications/${appeal.qualificationGroupId}/${appeal.qualificationId}/appeals`;
    }
    if (location.pathname.startsWith('/all-appeals')) {
      return "/all-appeals";
    }
    if (location.pathname.startsWith('/appeals-management')) {
      return "/appeals-management";
    }
    return "/";
  }

  hideCloseAppealModal = () => {
    this.setState({showCloseAppealModal: false })
  }

  hideMoveAppealModal = () => {
    this.setState({ newStatusId: null, showMoveAppealModal: false })
  }

  showMoveAppealModal = (newStatusId: number) => {
    this.setState({newStatusId, showMoveAppealModal: true})
  }

  transferTeam = (teamId: number) => {
    const { appealId, learningProviderId, teams, transferAppeal } = this.props;
    if (appealId && learningProviderId) {
      this.setState({ saving: true });
      transferAppeal(learningProviderId, appealId, teamId)
        .then(() => {
          this.getAppealDetails()          
          alert.success(`The appeal has been transfer to the '${_.find(teams, {"id": teamId})?.name}' team.`)
        })
        .catch((error) => {
          alert.error(error)
        })
        .finally(() => {
          this.setState({ 
            saving: false,
            showTransferTeamModal: false
          });
        })
    }
  }

  reOpenAppeal = () => {
    const { learningProviderId, appealId, reOpenAppeal } = this.props;
    if (learningProviderId && appealId) {
      this.setState({ saving: true });
      reOpenAppeal(learningProviderId, appealId)
        .then(() => {
          this.getAppealDetails();
          alert.success(`The appeal status has been re-opened.`);
        })
        .catch((error) => {
          alert.error(error)
        })
        .finally(() => {
          this.setState({
            saving: false,
            showReOpenAppealModal: false
          });
        })
    }
  }
  
  switchAppealType = (appealTypeId: number) => {
    const { learningProviderId, appealId, switchAppealType } = this.props;
    if (learningProviderId && appealId) {
      this.setState({ saving: true });
      switchAppealType(learningProviderId, appealId, appealTypeId)
        .then(() => {
          this.getAppealDetails();
          alert.success(`The appeal service type has been updated.`);
        })
        .catch((error) => {
          alert.error(error)
        })
        .finally(() => {
          this.setState({
            saving: false,
            showSwitchAppealTypeModal: false
          });
        })
    }
  }

  updateAppealStatus = (statusId: number, note: string) => {
    const { learningProviderId, appealId, updateAppealStatus } = this.props;
    if (learningProviderId && appealId) {
      this.setState({ saving: true });
      updateAppealStatus(learningProviderId, appealId, statusId, note)
        .then(() => {
          this.getAppealDetails();
          alert.success(`The appeal status has been updated.`);
        })
        .catch((error) => {
          alert.error(error)
        })
        .finally(() => {
          this.setState({
            saving: false,
            newStatusId: null,
            showMoveAppealModal: false
          });
        })
    }
  }

  uploadAppealFile = (file: AppealFileUpload) => {
    const { learningProviderId, appealId, uploadAppealFile } = this.props;
    if (learningProviderId && appealId) {
      return uploadAppealFile(file)
        .then(() => {
          this.getAppealDetails();         
        })      
    }
    return Promise.reject()
  }
}
import * as React from "react";
import { FormEvent } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { StoreState } from "../../../../store/store-state";
import Select from "../../../../components/pearson/select";
import { AppealOutcome } from "models/appeal-outcome";

interface AppealOutcomeSelectProps {
  outcomeId: number | null;
  onChange: (outcomeId: number | null) => void;
}
export const AppealOutcomeSelect = ({
  outcomeId,
  onChange
}: AppealOutcomeSelectProps) => {
  const appealOutcomes = useSelector((state: StoreState) => state.appealOutcomes);
  return (
    <div className="appeal-outcome-select">
      <Select
        id="appealOutcomeSelect"
        label="Appeal outcome:"
        placeholder="Please select..."
        value={outcomeId}
        options={_.map(appealOutcomes, (appealOutcome: AppealOutcome) => {
          return { value: appealOutcome.id, name: appealOutcome.name}
        })}
        onChange={(e: FormEvent<HTMLSelectElement>) => {
          onChange(parseInt(e.currentTarget.value))
        }}
      />
    </div>
  )
};

export default AppealOutcomeSelect;

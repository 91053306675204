import * as React from 'react';
import * as _ from 'lodash';
import { FormEvent } from "react";
import { Option } from "../../models/option"
import Select from './select';
import Icon from './icon';
import Input from './input';

import "./search-bar.scss";

interface SearchBarProps {
  label: string;
  startDisabled: boolean;
  options: Option[];
  placeholderOptions?: string;
  placeholderText?: string;
  searchOption?: string;
  searchText: string;
  searchTextMaxLength: number;
  onSearch: (searchOption: string, searchText: string) => void;
}

interface LocalState {
  searchOption: string;
  searchText: string;
  inputDisabled: boolean;
}

export class SearchBar extends React.Component<SearchBarProps, LocalState> {
  static defaultProps = {
    label: undefined,
    options: [],
    startDisabled: false,
    placeholderOptions: "",
    placeholderText: "",
    searchTextMaxLength: 250,
    selectedOption: "",
    selectedText: ""
  }

  raiseDoSearchWhenUserStoppedTyping = _.debounce(() => {
    this.props.onSearch(this.props.searchOption ?? "", this.state.searchText);
  }, 300);

  constructor(props: SearchBarProps) {
    super(props);
    this.state = {
      searchOption: this.props.searchOption ?? "",
      searchText: this.props.searchText,
      inputDisabled: this.props.startDisabled
    };
  }

  componentDidUpdate(prevProps: SearchBarProps, prevState: LocalState) {
    if (prevProps && prevProps.searchOption !== this.props.searchOption ||
      prevProps.searchText !== this.props.searchText ) {
      this.setState({ 
        searchOption: this.props.searchOption ?? "", 
        searchText: this.props.searchText 
      })
    }
  }

  onOptionChange = (event: FormEvent<HTMLSelectElement>) => {
    event.preventDefault();
    const option = (event.target as HTMLSelectElement).value;
    if (option !== "" && this.state.inputDisabled && this.props.startDisabled) {
      this.setState({ inputDisabled: false })
    }
    this.props.onSearch(option, this.props.searchText)
  };

  onTextChange = (event: FormEvent<HTMLInputElement>) => {
    this.setState({ searchText: (event.target as HTMLInputElement).value }, () => {
      this.raiseDoSearchWhenUserStoppedTyping();
    });
  }

  handleCriteriaChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({ searchText: e.currentTarget.value }, () => {
      this.raiseDoSearchWhenUserStoppedTyping();
    });
  };

  render() {
    return (
      <div className="search-bar">
        {this.props.label && (
          <label className="gr-label" htmlFor="searchText">
            {this.props.label}
          </label>
        )}  
        <div className="search-container">
          {this.props.options.length > 0 && (
            <Select
              id="searchOption"
              placeholder={this.props.placeholderOptions}
              value={this.state.searchOption}
              options={this.props.options}
              onChange={this.onOptionChange}
            />
          )}          
          <div className="gr-form-element input">                      
            <Input
              id="searchText"              
              placeholder={this.props.placeholderText}
              value={this.state.searchText}
              disabled={this.props.startDisabled}
              maxLength={this.props.searchTextMaxLength}
              onChange={this.onTextChange}
            />
          </div>
          <div className="btn-container">
            <button
              className="search-btn gr-secondary-bg"
              onClick={() => this.props.onSearch(this.props.searchOption ?? "", this.props.searchText)}>
              <Icon icon="search" size="18" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default SearchBar;
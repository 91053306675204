import * as React from 'react';
import * as _ from "lodash";
import { Option } from '../../models/option';
import Icon from './icon';
import { ChangeEvent } from 'react';

import "./radio.scss";

interface RadioProps {
  label?: string,
  groupName: string,
  options: Option[]
  selectedValue?: string | number | undefined | null;
  error?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const Radio = ({
  label,
  groupName,
  options,
  selectedValue,
  error,
  onChange
}: RadioProps) => {
  return (
    <div className="gr-form-element">
      <fieldset className="gr-fieldset">
        {label && (
          <legend className="gr-label">{label}</legend>
        )}       
        <ul>
          {_.map(options, (option, index) => (  
            <li className="gr-radio" key={index}>
              <input type="radio"                
                name={groupName} 
                id={`radio${index}`} 
                data-index={index} 
                value={option.value} 
                checked={option.value === selectedValue}
                onChange={onChange}
              />              
              <label htmlFor={`radio${index}`}>{option.name}</label>     
              <span>
                {selectedValue && option.value === selectedValue && (
                  <Icon icon="single-selected" size="18" fill="information" />
                )}
              </span>           
            </li>
          ))}
        </ul>
    </fieldset>
    {error && (
      <div className="error-state">
        <Icon icon="warning" size="18" fill="error" />
          <span className="gr-meta">{error}</span>
      </div>
    )}  
  </div >
)}

export default Radio;
import * as React from 'react';
import { useState } from "react";
import * as _ from "lodash";
import Icon from './icon';

import "./tooltip.css"; 

export interface DropdownOption {
  name: string;
  disabled?: boolean;
  onClick: () => void;
}

interface DropdownProps {
  buttonText?: string;
  disabled?: boolean;
  tooltip?: string;
  type?: "primary" | "attention" | "default" | "text" | "more";
  dropdownMenuRight: boolean;
  options: DropdownOption[];
}

export const Dropdown = ({
  buttonText,
  disabled,
  tooltip,
  type,
  dropdownMenuRight,
  options
}
  : DropdownProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const buttonClassName = type === "more" ? "btn icon-btn-24" : "gr-btn " + type;

  return (
    <div className="dropdown">
      <button
        className={buttonClassName}
        id="dropdownMenu"
        type="button"
        disabled={disabled}
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded={menuOpen}
        onClick={() => setMenuOpen(!menuOpen)}>
        {type === "more" 
        ? (
          <span {...tooltip ? {"data-tooltip": tooltip} : {}}>
              <Icon icon="more" size="24" fill={"gr-primary"} />
          </span>
        ) : (
          <>
            <span className="dropdown-text">{buttonText}</span>
            <Icon
              icon="drop-down"
              size="24"
              fill={type === "primary" ? "gr-white" : "gr-primary"}
            />
          </>
        )}
      </button>
      <div className={`dropdown-menu ${dropdownMenuRight ? "dropdown-menu-right" : ""}`} aria-labelledby="dropdownMenu">
        {_.map(options, (option: DropdownOption, index) => (
          <div
            key={index}
            className="dropdown-item"
            onClick={option.onClick}
            role="button">
            <a>{option.name}</a>
          </div>
        ))}
      </div>
    </div>
  );
};

Dropdown.defaultProps = {
  buttonText: "",
  disabled: false,
  tooltip: "",
  type: "default",
  showMoreIcon: false,
  multiSelect: false,
  textOnly: false,
  dropdownMenuRight: false
}

export default Dropdown;
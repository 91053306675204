import { connect } from "react-redux";
import { NotFoundPage, StateProps } from "./not-found-page";
import { StoreState } from "../../store/store-state";

const mapStateToProps = (state: StoreState): StateProps => {
  return { };
};

export default connect<StateProps>(
  mapStateToProps
)(NotFoundPage);


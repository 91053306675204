import { connect } from "react-redux";
import { AccessDeniedPage, StateProps } from "./access-denied-page";
import { StoreState } from "../../store/store-state";

const mapStateToProps = (state: StoreState): StateProps => {
  const isInternal = state.user ? state.user.isInternal : false;
  return { isInternal };
};

export default connect<StateProps>(
  mapStateToProps
)(AccessDeniedPage);


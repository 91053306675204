import initialState from './initialState';
import { AppealActions } from "../actions/appeal.actions"
import { Appeal } from "../models/appeal"
import _ from 'lodash';
import { 
  GET_APPEALS_SUCCESS,
  GET_ALL_APPEALS_SUCCESS,
  DELETE_APPEAL_SUCCESS
} from "../actions/action-types";


export function appealsReducer(
  state: Appeal[] = initialState.appeals,
  action: AppealActions 
): Appeal[] {

  switch (action.type) {
    case DELETE_APPEAL_SUCCESS:
      return _.filter(state, appeal => appeal.appealId !== action.appealId);
    case GET_APPEALS_SUCCESS:
    case GET_ALL_APPEALS_SUCCESS:
      return [...action.appeals];  
    default:
      return state;
  }
};


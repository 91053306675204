import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import _ from "lodash";
import { FileHistory, StateProps, OwnProps, DispatchProps } from "./file-history";
import { StoreState } from "../../store/store-state";
import { UploadFileHistoryActions, getUploadFileHistory } from "../../actions/upload-file-history.actions";
import { UploadFileHistoryPageState } from '../../models/page-state';
import { PageStateActions, setUploadFileHistoryPageState } from "../../actions/page-state.actions";
import { downloadArchiveCsv } from "../../actions/csv.actions";


const mapStateToProps = (state: StoreState, ownProps: OwnProps): StateProps => {
    const qualificationId = ownProps.match.params.qualificationId;

    return { 
        learningProviderId: state.selectedLearningProviderId,
        qualification: _.find(state.qualifications, { "id": qualificationId }),
        uploadFileHistories: state.uploadFileHistories,
        pageState: state.pageState.uploadFileHistoryPageState
    };
};
const mapDispatchToProps = (dispatch: ThunkDispatch<StoreState, void,
    PageStateActions |
    UploadFileHistoryActions
  >)
    : DispatchProps => ({
        getUploadFileHistory: (
            learningProviderId: string,
            qualificationId: string) =>
            dispatch(getUploadFileHistory(learningProviderId, qualificationId)),
        setPageState: (pageState: UploadFileHistoryPageState) =>
            dispatch(setUploadFileHistoryPageState(pageState)),
        downloadArchiveCsv: (
            learningProviderId: string, 
            qualificationId: string, 
            versionId: string, 
            fileName: string) =>
            downloadArchiveCsv(learningProviderId, qualificationId, versionId, fileName),
    });

export default connect<StateProps>(
  mapStateToProps,
  mapDispatchToProps
)(FileHistory);


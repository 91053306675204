import { Reducer } from "redux";

import { CallbackActions } from "../actions/callback.actions";
import { Callback } from "../models/callback";
import initialState from "./initialState";

import {
  CALLBACK_COMPLETE,
  CALLBACK_IN_PROGRESS,
} from "../actions/action-types";

export const CallbackReducer: Reducer<Callback> = (
  state: Callback = initialState.callback,
  action: CallbackActions
) => {
  switch (action.type) {
    case CALLBACK_COMPLETE:
      return { ...state, ...initialState.callback };
    case CALLBACK_IN_PROGRESS:
      return { ...state, ...initialState.callback, loading: true };
    default:
      return state;
  }
};

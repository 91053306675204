import * as React from "react";
import { StoreState } from "../../../store/store-state";
import { useSelector } from "react-redux";
import _ from "lodash";

interface AppealOutcomeDescriptionProps {
  outcomeId: number;
}

export const AppealOutcomeDescription = ({
  outcomeId
}: AppealOutcomeDescriptionProps) => {
  const appealOutcomes = useSelector((state: StoreState) => state.appealOutcomes);

  return (
    <>{_.find(appealOutcomes, { "id": outcomeId })?.name}</>
  )
};

export default AppealOutcomeDescription;
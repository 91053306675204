import * as React from 'react';
import * as _ from 'lodash';
import { TeamAppeal } from '../../../models/team-appeal';
import TeamAppealCard from './team-appeal-card';

interface TeamAppealCardListProps { 
  appeals: TeamAppeal[];
  showNoAppealsMessage: boolean;
  onViewAppeal: (appeal: TeamAppeal) => void;
}

export const TeamAppealCardList = ({
  appeals,
  showNoAppealsMessage,
  onViewAppeal
}: TeamAppealCardListProps) => {

  return (
    <div className="team-appeal-card-list">
      {_.map(appeals, (appeal, index) => (
        <TeamAppealCard
          key={index}
          appeal={appeal}
          onViewAppeal={onViewAppeal}
        />
      ))}
      {showNoAppealsMessage && appeals.length === 0 && (
        <p>No appeals match the search criteria.</p>
      )}
    </div>
  );
};

TeamAppealCardList.defaultProps = {
  showNoAppealsMessage: true
}

export default TeamAppealCardList


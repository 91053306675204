import { UploadFileError } from "./upload-file-error";

// export type UploadFileState = "InProgress" | "IncorrectFileType" | "Failed" | "Success" | "Processed" | null;

export class UploadFile {
  errorCount: number;
  lastUpdatedDateTime: Date;
  learningProviderId: string;
  numberOfLines: number;
  originalFilename: string;
  pearsonUserId: string;
  processedCount:number;
  qualificationId: string;
  uploadFileErrors: UploadFileError[];
  uploadType: string;
  username: string;
  versionId: string;
  warningCount: number;
}

import * as React from 'react';
import { FormEvent } from 'react';
import * as _ from 'lodash';
import { Grade } from '../../../models/grade';
import { LearnerGrade } from '../../../models/learner-grade';
import Select from '../../../components/pearson/select';

interface GradeSelectProps {
  learner: LearnerGrade;
  onChange: (learner: LearnerGrade) => void;
}

const GradeSelect = ({
  learner,
  onChange
}: GradeSelectProps) => {

  const getError = () => {
    if (learner.hasWarnings &&
      learner.warnings[0].errorDescription.toLowerCase().includes("grade")) {
      return learner.warnings[0].errorDescription;
    }
    return "";
  }

  const onGradeChange = (event: FormEvent<HTMLSelectElement>) => {
    event.preventDefault()
    const code = (event.target as HTMLSelectElement).value;
    const grade = _.find(learner.availableGrades, ["code", code]);
    onChange({
      ...learner,
      grade: grade
        ? grade.code
        : null,
      gradeIndex: grade
        ? _.padStart(grade.index.toString(), 5, '0')
        : ""
    })
  }

  return (
    <div className="grade-select">
      <Select
        id={learner.learnerIdentifier}
        value={learner.grade ?? ""}
        placeholder={" "}
        errors={getError()}
        options={_.map(learner.availableGrades, (grade: Grade) => {
          return {
            name: grade.code,
            value: grade.code
          }
        })}
        onChange={onGradeChange}
      />
    </div>
  )
};

GradeSelect.defaultProps = {
  disabled: false
}

export default GradeSelect;
import { connect } from "react-redux";
import { StoreState } from "store/store-state";
import { ThunkDispatch } from "redux-thunk";
import _ from "lodash";
import { AppealsPageState } from "../../../models/page-state";
import { AppealActions, deleteAppeal, getAppeals } from "../../../actions/appeal.actions";
import { PageStateActions, setAppealsPageState } from "../../../actions/page-state.actions";

import {
  ViewAppealsPage,
  StateProps,
  OwnProps,
  DispatchProps,
} from "./view-appeals-page";

const mapStateToProps = (state: StoreState, ownProps: OwnProps): StateProps => {
  const qualificationId = ownProps.match.params.qualificationId;
  return {
    appeals: state.appeals,
    internal: state.user ? state.user.isInternal : false,
    learningProviderId: state.selectedLearningProviderId,
    pageState: state.pageState.appealsPageState,
    qualification: _.find(state.qualifications, { "id": qualificationId })    
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    StoreState,
    void,
    AppealActions |
    PageStateActions
  >
): DispatchProps => ({
  deleteAppeal: (
    learningProviderId: string,
    appealId: string) =>
    dispatch(deleteAppeal(learningProviderId, appealId)),
  getAppeals: (
    learningProviderId: string,
    qualificationGroupId: string,
    qualificationId: string
  ) => dispatch(getAppeals(learningProviderId, qualificationGroupId, qualificationId)),
    setPageState: (pageState: AppealsPageState) =>
    dispatch(setAppealsPageState(pageState)),
});

export default connect<StateProps>(
  mapStateToProps,
  mapDispatchToProps
)(ViewAppealsPage);


import * as React from "react";
import { RouteComponentProps, Redirect, withRouter } from "react-router-dom";
import * as _ from 'lodash';
import { Appeal } from "../../../models/appeal";
import { AppealsPageState } from "../../../models/page-state";
import { alert } from "../../../components/pearson/alert";
import Loader from "../../../components/pearson/loader";
import AppealCardList from "../components/appeal-card-list";
import { SortDirection } from "../../../types/sort-direction";
import AppealSummary from "../components/appeal-summary";

export interface StateProps {
  appeals: Appeal[];
  internal: boolean;
  learningProviderId: string | null;
  pageState: AppealsPageState
}

export interface DispatchProps {
  deleteAppeal: (
    learningProviderId: string,
    appealId: string
  ) => Promise<void>;
  getAllAppeals: (
    learningProviderId: string
  ) => Promise<void>;
  setPageState: (
    pageState: AppealsPageState,
  ) => void;
}

interface Props extends DispatchProps, StateProps, RouteComponentProps { }

export interface LocalState {
  loading: boolean;
}

export class ViewAllAppeals extends React.Component<Props, LocalState> {
  constructor(props: Props) {
    super(props);    
    this.state = { loading: true };
  }

  render = () => {
    const { loading } = this.state;    
    const { appeals, internal, learningProviderId, pageState } = this.props;
    
    if (!learningProviderId) {
      return <Redirect to="/" />
    }

    return (
      <div className="page view-all-appeals-page">
        <Loader loading={loading} loadingStateLabel="Loading appeals..." />
        {!loading && (
          <>
            <div className="jumbotron header">
              <h1>All appeals:&nbsp;&nbsp;{appeals.length}</h1>
              <AppealSummary internal={internal} appeals={appeals} />
            </div>
            <AppealCardList
              internal={internal}
              appeals={appeals}
              showQualification={false}
              searchOption={pageState.searchOption}
              searchText={pageState.searchText}
              sortBy={pageState.sortBy}
              sortDirection={pageState.sortDirection}
              onSearch={this.search}
              onSort={this.sort}
              onCancelAppeal={this.cancelAppeal}
              onCompleteAppeal={this.completeAppeal}
              // onEditAppeal={this.editAppeal}
              onViewAppeal={this.viewAppeal}
            />
          </>
        )}
      </div>
    );
  };

  componentDidMount = () => {
    this.getAllAppeals();
  };

  cancelAppeal = (appeal: Appeal) => {
    this.props.deleteAppeal(appeal.learningProviderId, appeal.appealId)
      .then(() => {
        this.getAllAppeals();
        alert.success("The appeal has been cancelled.")
      })
      .catch((error) => alert.error(error))
      .finally(() => this.setState({
        loading: false
      }));
  }

  completeAppeal = (appeal: Appeal) => {
    this.props.history.push(`/all-appeals/complete/${appeal.appealId}`);
  };
  // editAppeal = (appealId: string) => {
  //   const qualification = this.props.qualification;
  //   if (qualification) {
  //     this.props.history.push(`/qualifications/${qualification.qualificationGroupId}/${qualification.id}/appeal/${appealId}`);
  //   }
  // };

  getAllAppeals = () => {
    const { learningProviderId, getAllAppeals} = this.props;
    if (learningProviderId) {
      this.setState({ loading: true });
      getAllAppeals(learningProviderId)
        .catch((error) => alert.error(error))
        .finally(() => this.setState({
          loading: false
        }));
    }
  }

  search = (searchOption: string, searchText: string) => {
    this.props.setPageState({ ...this.props.pageState, searchOption, searchText });
  };

  sort = (sortBy: string, sortDirection: SortDirection) => {
    this.props.setPageState({ ...this.props.pageState, sortBy, sortDirection });
  };

  viewAppeal = (appeal: Appeal) => {
    this.props.history.push(
      `/all-appeals/view/${appeal.appealId}`
    );
  };
}

export default withRouter(ViewAllAppeals);

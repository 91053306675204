export class AppealEvidence {
  fileId: string;
  filename: string;
  fileSize: null;
  fileStatus: number;
  fileStatusName: "";
  username: string;
  addedDateTime: Date;
  s3Key: string;
  allowDownload: boolean;
}
import * as React from "react";
import _ from "lodash";
import { AppealType } from "../../../models/appeal-type";
import Radio from "../../../components/pearson/radio";

interface AppealTypeRadioGroupProps {
  appealTypes: AppealType[];  
  appealTypeId: number | undefined;  
  onChange: (appealTypeId: number ) => void;
}
export const AppealTypeRadioGroup = ({
  appealTypeId,
  appealTypes,  
  onChange
}: AppealTypeRadioGroupProps ) => {
  
  return (   
    <div className="appeal-service-type">
      <Radio
        groupName="appealServiceTypes"        
        selectedValue={appealTypeId}
        options={_.map(appealTypes, appealType => {
          return { value: appealType.id, name: appealType.name }
        })}
        onChange={(e) => { onChange(parseInt(e.target.value)) }}
      />
    </div>
  )
};

export default AppealTypeRadioGroup;

import { Grade } from "./grade";
import { UploadFileError } from "./upload-file-error";

export class LearnerGrade {
  availableGrades: Grade[];
  dateOfBirth: Date | null;
  forenames: string;
  grade: string | null;
  gradeIndex: string | null;
  hasError: boolean;
  hasWarnings: boolean;
  isNotProcessed: boolean;
  isProcessed: boolean;
  learnerIdentifierName: string;
  learnerIdentifier: string;
  ranking: number | null;
  rankingString: string;
  searchString: string;
  surname: string; 
  warnings: UploadFileError[];
}

import * as React from "react";
import { FormEvent } from "react";
import Icon from "./icon";

interface TextAreaProps {
  id?: string;
  label?: string;
  name?: string;
  placeholder?: string;
  cols?: number;
  rows?: number;
  minLength: number;
  maxLength: number;
  wrap?: boolean;
  readOnly?: boolean;
  value?: string;
  errors?: string;
  warnings?: string;
  autoFocus?: boolean;
  onChange?: (event: FormEvent<HTMLInputElement>) => void;
}

const TextArea = ({
  id,
  label,
  placeholder,
  minLength,
  maxLength,
  wrap,
  cols,
  rows,
  readOnly,
  value,
  errors,
  warnings,
  autoFocus,
  onChange,
}: TextAreaProps) => {
  
  return (
    <div className="gr-form-element">
      {label && (
        <label className="gr-label" htmlFor={id}>
          {label}
        </label>
      )}
      <textarea 
        {...(id && { id })}
        {...(name && { name })}
        {...(placeholder && { placeholder })}
        value={value}
        className="gr-textarea" 
        cols={cols}
        rows={rows}
        minLength={minLength}
        maxLength={maxLength} 
        {...(autoFocus && { autoFocus: true })}
        {...(wrap && { wrap: true })}
        {...(readOnly && { readOnly: true })}      
        onChange={onChange}   
     />
      <p className="gr-meta" style={{display:"flex", justifyContent:"flex-end"}}>
        {`${maxLength - (value ? value.length : 0)} characters remaining.`}
      </p>
      {errors && errors != "" && (
        <div className="error-state">
          <Icon icon="warning" size="18" />
          <span className="gr-meta">{errors}</span>
        </div>
      )}
      {warnings && warnings != "" && (
        <div className="warning-state">
          <Icon icon="warning" size="18" />
          <span className="gr-meta">{warnings}</span>
        </div>
      )}
    </div>
  );
};

TextArea.defaultProps = {
  cols: "50",
  rows:"9",
  minLength: 0,
  maxLength: 5000,
  value: ""
};

export default TextArea;

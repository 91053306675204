import React, { ChangeEvent } from "react";
import Icon from "./icon";

interface CheckboxProps {
  id?: string;
  label?: string | JSX.Element;
  value?: string;
  checked?: boolean | undefined;
  disabled?: boolean;
  errors?: string;
  warnings?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const Checkbox = ({
  id,
  label,
  value,
  checked,
  disabled,
  errors,
  warnings,
  onChange 
} : CheckboxProps) => {
  return (
    <div className="gr-form-element">
      <div className="gr-checkbox">
        <input 
          id={id} 
          type="checkbox" 
          value={value}
          checked={checked}
          disabled={disabled ?? undefined}
          onChange={onChange}
        />
        <label htmlFor={id}>{label}</label> 
        <span>
          {checked && (
            <Icon icon="multi-correct" size="24" fill="gr-primary" />
          )}       
        </span>
      </div>
      {errors && errors != "" && (
        <div className="error-state">
          <Icon icon="warning" size="18" />
          <span className="gr-meta">{errors}</span>
        </div>
      )}
      {warnings && warnings != "" && (
        <div className="warning-state">
          <Icon icon="warning" size="18" />
          <span className="gr-meta">{warnings}</span>
        </div>
      )}  
    </div>
  )
};

Checkbox.defaultProps = {
  id: "checkbox"
}

export default Checkbox;

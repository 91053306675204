import * as React from 'react';
import { RouteComponentProps } from "react-router-dom";
import config from "../../app.config";

export interface StateProps {
    correlationId: string;
}

export type OwnProps = RouteComponentProps<{ correlationId: string }>;

interface Props extends StateProps, OwnProps { }

export class ErrorPage extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
    document.title = "Error";
  }
  render = () => {
    return (
      <div className="error-page">
        <div className="jumbotron">
          <h1>{document.title}</h1>
          <p>
            An error has occured.
          </p>
          <p>
            If this error persists please <a href={config.HELP_LINKS.CONTACT_US} target="_blank">contact us</a> quoting the following reference code: 
          </p>
          <p>
             {this.props.correlationId}
          </p>
        </div>  
      </div>
    );
  };
}

export default ErrorPage;

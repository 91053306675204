import * as React from 'react';

export interface StateProps {
  isInternal: boolean;
}

interface Props extends StateProps { }

export class AccessDeniedPage extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
    document.title = "Access Denied";
  }
  render = () => {
    return (
      <div className="access-denied">
        <div className="jumbotron">
          <h1>{document.title}</h1>
          <p>
            You are not authorised to access the Centre Assessment Grade Capture application.
          </p>
          <p>
            Please talk to your Edexcel Online account administrator to request permission to use this service.
        </p>
        </div>  
      </div>
    );
  };
}

export default AccessDeniedPage;

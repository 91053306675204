import * as React from "react";
import { FormEvent, CSSProperties } from "react";
import Icon from "./icon";

interface InputProps {
  id: string;
  type: "text" | "file";
  label?: string;
  placeholder?: string;
  value?: string;
  errors?: string;
  warnings?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  maxLength: number;
  style?: CSSProperties | undefined;
  onChange?: (event: FormEvent<HTMLInputElement>) => void;
}

const Input = ({
  id,
  type,
  label,
  placeholder,
  value,
  errors,
  warnings,
  disabled,
  autoFocus,
  maxLength,
  style,
  onChange,
}: InputProps) => {
  return (
    <div className="gr-form-element">
      {label && (
        <label className="gr-label" htmlFor={id}>
          {label}
        </label>
      )}
      <input
        id={id}
        type={type}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        autoFocus={autoFocus}
        {...(maxLength && { maxLength: maxLength })}
        className={`gr-input ${errors ? "error" : ""} ${warnings ? "warning" : ""}`}
        style={style}
        onChange={onChange}
      />
      {errors && errors != "" && (
        <div className="error-state">
          <Icon icon="warning" size="18" />
          <span className="gr-meta">{errors}</span>
        </div>
      )}     
      {warnings && warnings != "" && (
        <div className="warning-state">
          <Icon icon="warning" size="18" />
          <span className="gr-meta">{warnings}</span>
        </div>
      )}     
    </div>
  );
};

Input.defaultProps = {
  type: "text",
  autoFocus: false,
  maxLength: null,
  style: undefined,
};

export default Input;

import initialState from './initialState';
import { LearnerGrade } from '../models/learner-grade';
import { LearnerGradeActions } from '../actions/learner-grade.actions';
import { QualificationGroupActions } from '../actions/qualification-group.actions';
import { QualificationActions } from '../actions/qualification.actions';
import { LearningProviderActions } from "../actions/learning-provider.actions";
import { 
  GET_LEARNER_GRADES_SUCCESS, 
  GET_QUALIFICATIONS_SUCCESS,
  SET_LEARNING_PROVIDER,
  SET_QUALIFICATION_GROUP
} from "../actions/action-types";

export function learnerGradesReducer(
  state: LearnerGrade[] = initialState.learnerGrades,
  action: LearnerGradeActions | 
          LearningProviderActions | 
          QualificationGroupActions | 
          QualificationActions
): LearnerGrade[] {

  switch (action.type) {
    case GET_LEARNER_GRADES_SUCCESS:
      return [ ...action.learners ];
    case SET_LEARNING_PROVIDER:
    case SET_QUALIFICATION_GROUP:
    case GET_QUALIFICATIONS_SUCCESS:
      return [ ...initialState.learnerGrades ];
    default:
      return state;
  }
}


import * as React from 'react';
import { useState, useEffect } from 'react';
import * as _ from 'lodash';
import { SortDirection } from '../../../types/sort-direction';
import { LearnerGradeSearchOption } from '../../../types/learner-grade-search-option';
import SearchBar from '../../../components/pearson/search-bar';
import { LearnerGrade } from '../../../models/learner-grade';
import { LearnerGradesPageState } from '../../../models/page-state';
import { LearnerGradeSearchOptions } from "../../../models/option";
import { UploadFileError } from '../../../models/upload-file-error';
import FileErrorsCardList from '../components/fileline-errors-card-list';
import LearnerGradeCardList from '../components/learner-grade-card-list';

interface ViewLearnerGradesProps {
  pageState: LearnerGradesPageState,
  gradable: boolean;
  rankable: boolean;
  learners: LearnerGrade[],
  uploadFileErrors: UploadFileError[];
  setPageState: (pageState: LearnerGradesPageState) => void;
}

export const ViewLearnerGrades = ({
  pageState,
  gradable,
  rankable,
  learners,
  setPageState,
  uploadFileErrors
}: ViewLearnerGradesProps) => {

  const [learnerGradesNotProcessed, setLearnerGradesNotProcessed] = useState([] as LearnerGrade[]);
  const [learnerGradesProcessed, setLearnerGradesProcessed] = useState([] as LearnerGrade[]);
  const [learnerGradesWithWarnings, setLearnerGradesWithWarnings] = useState([] as LearnerGrade[]);

  useEffect(() => {
    setLearnerGradesNotProcessed(_.filter(learners, ["isNotProcessed", true]));
    setLearnerGradesProcessed(_.filter(learners, ["isProcessed", true]));
    setLearnerGradesWithWarnings(_.filter(learners, ["hasWarnings", true]))
  }, [learners]);

  
  const onSearch = (searchOption: LearnerGradeSearchOption, searchText: string) => {
    setPageState({
      ...pageState,
      searchOption,
      searchText
    });
  }

  const onSort = (listId: string, sortBy: string, sortDirection: SortDirection) => {
    switch (listId) {
      case "LearnerGradesWithWarnings":
        setPageState({
          ...pageState,
          withWarningsSortBy: sortBy,
          withWarningsSortDirection: sortDirection
        })
        break;
      case "LearnerGradesNotProcessed":
        setPageState({
          ...pageState,
          notProcessedSortBy: sortBy,
          notProcessedSortDirection: sortDirection
        })
        break;
      case "LearnerGradesProcessed":
        setPageState({
          ...pageState,
          processedSortBy: sortBy,
          processedSortDirection: sortDirection
        });
        break;
      default:
        break;
    }
  };

  return (
    <div className="view-learner-grades">                       
      <SearchBar
        options={LearnerGradeSearchOptions}
        searchOption={pageState.searchOption}
        searchText={pageState.searchText}
        onSearch={onSearch}
      />
      {uploadFileErrors && uploadFileErrors.length > 0 && (
        pageState.searchOption === "All" || pageState.searchOption === "ErrorsInFile") && (
          <FileErrorsCardList 
          searchText={pageState.searchText}
            fileErrors={uploadFileErrors} 
          />
      )}
      {learnerGradesWithWarnings.length > 0 && (
        <LearnerGradeCardList
          id="LearnerGradesWithWarnings"
          className="learner-grades-with-warnings"
          title="Learners with warnings"
          learners={learnerGradesWithWarnings}      
          gradable={gradable}
          rankable={rankable}
          readOnly={true}
          displaySearch={false}
          searchOption={pageState.searchOption}
          searchText={pageState.searchText}
          sortBy={pageState.withWarningsSortBy}
          sortDirection={pageState.withWarningsSortDirection}
          onSort={onSort}
        />
      )}
      {learnerGradesNotProcessed.length > 0 && (
        <LearnerGradeCardList
          id="LearnerGradesNotProcessed"
          className="learner-grades-not-processed"
          title="Learners not processed"
          learners={learnerGradesNotProcessed}     
          gradable={gradable}
          rankable={rankable}
          readOnly={true}
          displaySearch={false}
          searchOption={pageState.searchOption}
          searchText={pageState.searchText}
          sortBy={pageState.notProcessedSortBy}
          sortDirection={pageState.notProcessedSortDirection}
          onSort={onSort}
        />
      )}
      {learnerGradesProcessed.length > 0 && (
        <LearnerGradeCardList
          id="LearnerGradesProcessed"
          className="learner-grades-processed"
          title="Learners processed"
          learners={learnerGradesProcessed}    
          gradable={gradable}
          rankable={rankable}
          readOnly={true}
          displaySearch={false}
          searchOption={pageState.searchOption}
          searchText={pageState.searchText}
          sortBy={pageState.processedSortBy}
          sortDirection={pageState.processedSortDirection}
          onSort={onSort}
        />
      )}
    </div>
  );
};

export default ViewLearnerGrades
import * as React from "react";
import { FormEvent } from "react";
import _ from "lodash";
import { Team } from "../../../../models/team";
import Select from "../../../../components/pearson/select";

interface TeamSelectProps {
  teams: Team[];
  teamId: number | null;
  onChange: (teamId: number) => void;
}
export const TeamSelect = ({
  teams,
  teamId,
  onChange
}: TeamSelectProps) => {
  return (
    <div className="team-select">
      <Select
        id="teamSelect"
        label="Team:"
        placeholder="Select a team..."
        value={teamId}
        options={_.map(teams, team => {
          return { name: team.name, value: team.id }
        })}
        onChange={(e: FormEvent<HTMLSelectElement>) => {
          onChange(parseInt(e.currentTarget.value))
        }}
      />
    </div>
  )
};

export default TeamSelect;

import * as React from "react";
import { NavLink, Redirect } from "react-router-dom";
import { FormEvent, useState } from "react";
import _ from "lodash";
import { QualificationGroup } from "../models/qualification-group";
import { Option } from "../models/option";
import { User } from "../models/user";
import Select from "./pearson/select";
import SelectLearningProviderModal from "./select-learning-provider-modal";
import Icon from "./pearson/icon";
import config from "../app.config";
import "./pearson/tooltip.css";
import "./navigation-bar.scss";

interface NavigationBarProps {
  isAuthenticated: boolean;
  user: User | null;
  learningProviderId: string;
  qualificationGroups: QualificationGroup[];
  onLearningProviderChange: (learningProviderId: string | null) => void;
  onGetInternalUserLearningProvider: (
    learningProviderCode: string
  ) => Promise<boolean>;
}

export const NavigationBar = ({
  isAuthenticated,
  user,
  learningProviderId,
  qualificationGroups,
  onLearningProviderChange,
  onGetInternalUserLearningProvider,
}: NavigationBarProps) => {
  const [showModal, setShowModal] = useState(false);
  const [learningProviderCode, setLearningProviderCode] = useState("");
  const [error, setError] = useState("");

  const getLearningProviderOptions = () => {
    const options: Option[] = [];
    if (user?.learningProviders) {
      user.learningProviders.map((learningProvider) => {
        options.push({
          name: learningProvider.displayName,
          value: learningProvider.id,
          disabled: false,
        });
      });
    }
    return options;
  };

  const getLearningProviderInternalOptions = () => {
    const options: Option[] = [];
    if (user?.learningProviders) {
      user.learningProviders.map((learningProvider) => {
        options.push({
          name: learningProvider.internalDisplayName,
          value: learningProvider.id,
          disabled: false,
        });
      });
    }
    return options;
  };

  const learningProviderChange = (event: FormEvent<HTMLSelectElement>) => {
    event.preventDefault();
    const id = (event.target as HTMLSelectElement).value;
    onLearningProviderChange(id);
  };

  const learningProviderCodeChange = (learningProviderCode: string) => {
    setError("");
    setLearningProviderCode(learningProviderCode);
  };

  const cancelModal = () => {
    setLearningProviderCode("");
    setError("");
    setShowModal(false);
  };

  const getInternalUserLearningProvider = (learningProviderCode: string) => {
    onGetInternalUserLearningProvider(learningProviderCode)
      .then((response) => {
        if (response) {
          cancelModal();
        } else {
          setError("Invalid learning provider code.");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        return <Redirect to="/" />;
      });
  };

  return (
    <nav className="navigation-bar">
      <div className="box">
        <div className="navigation-links gr-tabbed-navigation theme--dark">
          <ul className="nav-links" role="tablist">
            <li key={0} className="pe-label dark" role="presentation">
              <NavLink
                to="/"
                exact={true}
                role="tab"
                activeClassName="activeTab">
                Home
              </NavLink>
            </li>
            {isAuthenticated && user && user.isUserPermissionValid && (
              <>
                {user.isInternal && user.showAppeals && (
                  <li key={1} className="pe-label" role="presentation">
                    <NavLink
                      to={`/appeals-management`}
                      className="tab-button"
                      activeClassName="activeTab">
                        Appeals management
                    </NavLink>
                  </li>
                )}
                {qualificationGroups.length > 0 && (                  
                  <>
                    {_.map(qualificationGroups, (qualificationGroup, index) => (
                      <li key={index + 2} className="pe-label" role="presentation">
                        <NavLink
                          to={`/qualifications/${qualificationGroup.id}`}
                          className="tab-button"
                          activeClassName="activeTab">
                          {qualificationGroup.id}
                        </NavLink>
                      </li>
                    ))}
                    {user.showAppeals && (
                      <li key={qualificationGroups.length + 2} className="pe-label" role="presentation">
                        <NavLink
                          to={`/all-appeals`}
                          className="tab-button"
                          activeClassName="activeTab">
                          All Appeals
                        </NavLink>
                      </li>
                    )}
                  </>
                )}
              </>
            )}         
          </ul>
        </div>
        {isAuthenticated && user && user.isUserPermissionValid && (
          <span className="learning-provider">
            {!user.isInternal && user.learningProviders.length === 1 && (
              <span className="name">
                {user.learningProviders[0].displayName}
              </span>
            )}
            {!user.isInternal && user.learningProviders.length > 1 && (
              <Select
                id="learningProviderSelect"
                placeholder="Select site"
                options={getLearningProviderOptions()}
                value={learningProviderId}
                onChange={learningProviderChange}
              />
            )}
            {user.isInternal && (
              <>
                <span className="name">
                  {user.learningProviders.length === 0 && (
                    <>Select learning provider</>
                  )}
                  {user.learningProviders.length === 1 && user.learningProviders[0].internalDisplayName}
                  {user.learningProviders.length > 1 && 
                    <Select
                      id="learningProviderSelect"
                      placeholder="Select site"
                      options={getLearningProviderInternalOptions()}
                      value={learningProviderId}
                      onChange={learningProviderChange}
                    />
                  }
                </span>
                <button 
                  type="button"
                  className="search-icon"
                  onClick={() => setShowModal(true)}>
                  <span
                    data-tooltip="Select Learning Provider"
                    data-for="selectLearningProviderModal">
                    <Icon icon="search" size="24" fill="gr-white" />
                  </span>
                </button>
                <SelectLearningProviderModal
                  id="selectLearningProviderModal"
                  show={showModal}
                  learningProviderCode={learningProviderCode}
                  error={error}
                  onCancel={cancelModal}
                  onSearch={getInternalUserLearningProvider}
                  onLearningProviderCodeChange={learningProviderCodeChange}
                />
              </>
            )}
            <a className="help-icon"
              href={config.HELP_LINKS.GENERAL_HELP_GUIDE}
              target="_blank"
              data-tooltip="Help"
            >
              <Icon icon="help" size="24" fill="gr-white" />
            </a>
          </span>
        )}        
      </div>
    </nav>
  );
};

NavigationBar.defaultProps = {
  learningProviderId: "",
  theme: "dark",
};

export default NavigationBar;

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { IntlProvider } from "react-intl";
import configureStore from "./store/configureStore";
import { requireBearerToken, requireCorrelationId, reaponseInterceptor } from "./axios-interceptors";
import { InitialState } from "./reducers/initialState";
import registerServiceWorker from './registerServiceWorker';
import App from "./features/app.container";
// import { getAppealTypes } from './actions/appeal-type.actions';

import "./index.scss";

const store = configureStore(InitialState);

requireBearerToken();
requireCorrelationId();
reaponseInterceptor();

ReactDOM.render(
  <Provider store={store}>
    <IntlProvider locale={"en-GB"}>
      <App />
    </IntlProvider>
  </Provider>,

  document.getElementById("root")
);

registerServiceWorker();
import initialState from './initialState';
import { UploadFileActions } from "../actions/upload-file.actions";
import { GET_UPLOAD_FILE_SUCCESS } from "../actions/action-types";
import { UploadFile } from '../models/upload-file';

export function uploadFileReducer(
  state: UploadFile | null = initialState.uploadFile,
  action: UploadFileActions 
): UploadFile | null {

  switch (action.type) {
    case GET_UPLOAD_FILE_SUCCESS:
      return Object.assign({}, action.uploadFile) 
    default:
      return state;
  }
};


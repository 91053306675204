import axios from "axios";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { User } from "../models/user";
import * as _ from "lodash";
import * as action_types from "./action-types";
import {
  ajaxCallError,
  beginAjaxCall,
  AjaxCallActions
} from "./ajax-status.actions";

import config from "../app.config";
import { StoreState } from "../store/store-state";
import { setLearningProvider } from "./learning-provider.actions";
import { getQualificationGroups } from "./qualification-group.actions";

interface GetInternalUserLearningProviderSuccessAction extends Action {
  type: action_types.GET_INTERNAL_USER_LEARNING_PROVIDER_SUCCESS;
  user: User;
  getStates: any;
}

interface GetUserSuccessAction extends Action {
  type: action_types.GET_USER_SUCCESS;
  user: User;
  getStates: any;
}

export const getInternalUserLearningProviderSuccessAction = (
  user: User, getStates: any
): GetInternalUserLearningProviderSuccessAction => ({
  user,
  getStates,
  type: action_types.GET_INTERNAL_USER_LEARNING_PROVIDER_SUCCESS
});

export const getUserSuccessAction = (
  user: User, getStates: any
): GetUserSuccessAction => ({
    user,
  getStates,
    type: action_types.GET_USER_SUCCESS
});

export const getInternalUserAndLearningProvider = (learningProviderCode: string) => {
  return (dispatch: ThunkDispatch<StoreState, void, UserActions>, getState: any) => {
    dispatch(beginAjaxCall());
    return axios
      .get(
        `${config.API_GATEWAY.URL}/user/getuser/LearningProvider/${learningProviderCode}`
      )
      .then(response => {
        if (response.status === 200) {
          const user = mapUser(response.data);
          dispatch(getInternalUserLearningProviderSuccessAction(user, getState()));
          
          if (user.learningProviders.length === 1) {
            const learningProviderId = user.learningProviders[0]
              ? user.learningProviders[0].id
              : null;
            dispatch(setLearningProvider(learningProviderId));

            if (learningProviderId) {
              dispatch(getQualificationGroups(learningProviderId));
            }
            return true;
          }

          if (user.learningProviders.length > 1) {

            dispatch(setLearningProvider(null));
            return true;
          }
        }
        return false;
      })
      .catch(error => {
        dispatch(ajaxCallError(error));
        throw error;
      });
  };
};

export const getUser = (forgeRockId: string) => {
  return (dispatch: ThunkDispatch<StoreState, void, UserActions>, getState: any) => {
    dispatch(beginAjaxCall());    
    return axios
      .get(
        `${config.API_GATEWAY.URL}/user/getuser/${forgeRockId}`
      )
      .then(response => {
        const user = mapUser(response.data);
        dispatch(getUserSuccessAction(user, getState()));

        if (user.learningProviders.length === 1) {
          let learningProviderId: string = user.learningProviders[0].id;
          dispatch(setLearningProvider(learningProviderId));
          dispatch(getQualificationGroups(learningProviderId))
        }
      })
      .catch(error => {
        dispatch(ajaxCallError(error));
        throw error;
      });
  };
}

const mapUser = (user: any) : User => {
  return {
    ...user,
    learningProviders: _.map(user.learningProviders, (learningProvider) => {
      return {
        id: learningProvider.learningProviderId,
        name: learningProvider.learningProviderName,
        displayName: learningProvider.learningProviderDisplayName,
        internalDisplayName: learningProvider.learningProviderInternalDisplayName
      }
    })   
  }
}

export type UserActions =
| GetUserSuccessAction
| GetInternalUserLearningProviderSuccessAction
| AjaxCallActions;
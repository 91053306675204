import { connect } from "react-redux";
import { StoreState } from "store/store-state";
import { ThunkDispatch } from "redux-thunk";
import { AppealActions, acknowledgeAppeal, getAppealDetails, saveAppeal } from "../../../actions/appeal.actions";
import { AppealFileActions, downloadAppealEvidence, uploadAppealFile } from "../../../actions/appeal-upload.actions";
import { AppealFileUpload } from "../../../models/appeal-file-upload";
import { AppealSubmit } from "../../../models/appeal-submit";

import {
  EditAppealPage,
  StateProps,
  OwnProps,
  DispatchProps,
} from "./edit-appeal-page";

const mapStateToProps = (state: StoreState, ownProps: OwnProps): StateProps => {
  const appealId = ownProps.match.params.appealId;
  return {
    appealId,
    appeal: state.appealDetail,
    appealFileUploadStatus: state.appealFileUploadStatus,
    appealTypes: state.appealTypes,
    internal: state.user ? state.user.isInternal : false,
    learningProviderId: state.selectedLearningProviderId
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<StoreState, void,
  AppealActions |
  AppealFileActions
>): DispatchProps => ({
  acknowledgeAppeal: (
    learningProviderId: string,
    appealId: string) =>
    dispatch(acknowledgeAppeal(learningProviderId, appealId)),
  downloadAppealEvidence: (
    learningProviderId: string,
    appealId: string,
    fileId: string) =>
    downloadAppealEvidence(learningProviderId, appealId, fileId),
  getAppealDetails: (
    learningProviderId: string,
    appealId: string) =>
    dispatch(getAppealDetails(learningProviderId, appealId)),
  saveAppeal: (
    learningProviderId: string,
    qualificationId: string,
    appeal: AppealSubmit) =>
    dispatch(saveAppeal(learningProviderId, qualificationId, appeal)),
  uploadAppealFile: (
    appealFileUpload: AppealFileUpload) =>
    dispatch(uploadAppealFile(appealFileUpload))
});

export default connect<StateProps>(
  mapStateToProps,
  mapDispatchToProps
)(EditAppealPage);

import { Action } from "redux";
import { SET_LEARNING_PROVIDER} from "./action-types";
import { ThunkAction } from "redux-thunk";
import { StoreState } from "store/store-state";

interface SetLearningProviderAction extends Action {
  type: SET_LEARNING_PROVIDER;
  learningProviderId: string |  null
}

type ThunkResult<R> = ThunkAction<R, StoreState, undefined, LearningProviderActions>;

export const setLearningProvider = (learningProviderId: string | null)
  : ThunkResult<Promise<void>>  => {
  return (dispatch) => {
    dispatch({
      type: SET_LEARNING_PROVIDER,
      learningProviderId
    });
    return Promise.resolve();
  }
}

export type LearningProviderActions =
  | SetLearningProviderAction
import * as React from "react";
import { RouteComponentProps, Redirect, withRouter, Link } from "react-router-dom";
import * as _ from 'lodash';
import { Appeal } from "../../../models/appeal";
import { Qualification } from "../../../models/qualification";
import { AppealsPageState } from "../../../models/page-state";
import { alert } from "../../../components/pearson/alert";
import Loader from "../../../components/pearson/loader";
import Button from "../../../components/pearson/button";
import { SortDirection } from "../../../types/sort-direction";
import AppealCardList from "../components/appeal-card-list";
import AppealSummary from "../components/appeal-summary";

export interface StateProps {
  appeals: Appeal[];
  internal: boolean;
  learningProviderId: string | null;
  pageState: AppealsPageState;
  qualification: Qualification | undefined;
}

export interface DispatchProps {
  deleteAppeal: (
    learningProviderId: string,
    appealId: string
  ) => Promise<void>;
  getAppeals: (
    learningProviderId: string,
    qualificationGroupId: string,
    qualificationId: string | null
  ) => Promise<void>;
  setPageState: (
    pageState: AppealsPageState,
  ) => void;
}

export type OwnProps = RouteComponentProps<{ qualificationId: string }>;
interface Props extends DispatchProps, StateProps, OwnProps { }

export interface LocalState {
  loading: boolean;
}

export class ViewAppealsPage extends React.Component<Props, LocalState> {
  constructor(props: Props) {
    super(props);
    this.state = { loading: true };
    if (props.qualification) {
      document.title = props.qualification.displayName;
    }
  }

  render = () => {
    const loading = this.state.loading;
    const { appeals, internal, learningProviderId, pageState, qualification } = this.props;

    if (!learningProviderId && !qualification) {
      return <Redirect to="/" />
    }

    return (
      <div className="page view-appeals-page">
        <Loader loading={loading} loadingStateLabel="Loading appeals..." />
        {!loading && qualification && (
          <>
            <div className="jumbotron header">
              <Link
                className="back-link"
                to={`/qualifications/${qualification.qualificationGroupId}`}>
                &lt;&lt; Back to {qualification.qualificationGroupId}
              </Link>
              <h1>{qualification.displayName}</h1>
              <h2>Appeals:&nbsp;&nbsp;{appeals.length}</h2>
              <AppealSummary internal={internal} appeals={appeals}/>
              <Button
                label="Create new appeal"
                className="primary"
                onClick={this.createAppeal}
              />             
            </div>
            <AppealCardList
              internal={internal}
              appeals={appeals}
              showQualification={false}
              searchOption={pageState.searchOption}
              searchText={pageState.searchText}
              sortBy={pageState.sortBy}
              sortDirection={pageState.sortDirection}
              onSearch={this.search}
              onSort={this.sort}
              onCancelAppeal={this.cancelAppeal}
              onCompleteAppeal={this.completeAppeal}
              // onEditAppeal={this.editAppeal}
              onViewAppeal={this.viewAppeal}
            />
          </>
        )}
      </div>
    );
  };

  componentDidMount = () => {
    this.getAppeals();
  };

  cancelAppeal = (appeal: Appeal) => {
    this.props.deleteAppeal(appeal.learningProviderId, appeal.appealId)
      .then(() => {
        this.getAppeals();
        alert.success("The appeal has been cancelled.")
      })
      .catch((error) => alert.error(error))
      .finally(() => this.setState({
        loading: false
      }));
  }

  createAppeal = () => {
    const qualification = this.props.qualification;
    if (qualification) {
      this.props.history.push(`/qualifications/${qualification.qualificationGroupId}/${qualification.id}/appeals/new`);
    }
  };
  
  completeAppeal = (appeal: Appeal) => {
    this.props.history.push(
      `/qualifications/${appeal.qualificationGroupId}/${appeal.qualificationId}/appeals/complete/${appeal.appealId}`);
  };

  // editAppeal = (appealId: string) => {
  //   const qualification = this.props.qualification;
  //   if (qualification) {
  //     this.props.history.push(`/qualifications/${qualification.qualificationGroupId}/${qualification.id}/appeal/${appealId}/edit`);
  //   }
  // };

  getAppeals = () => {
    const { learningProviderId, qualification, getAppeals } = this.props;
    if (learningProviderId && qualification) {
      this.setState({ loading: true });
      getAppeals(learningProviderId, qualification.qualificationGroupId, qualification.id)
        .catch((error) => alert.error(error))
        .finally(() => this.setState({
          loading: false
        }));
    }
  }
  
  search = (searchOption: string, searchText: string) => {
    this.props.setPageState({ ...this.props.pageState, searchOption, searchText });
  };

  sort = (sortBy: string, sortDirection: SortDirection) => {
    this.props.setPageState({ ...this.props.pageState, sortBy, sortDirection });
  };

  viewAppeal = (appeal: Appeal) => {
    this.props.history.push(
      `/qualifications/${appeal.qualificationGroupId}/${appeal.qualificationId}/appeals/view/${appeal.appealId}`);
  };
}

export default withRouter(ViewAppealsPage);

import * as React from "react";
import AuthForgeRock from "../../actions/auth/auth";
import { Redirect } from "react-router";
import { User } from "../../models/user";

const auth = new AuthForgeRock();

export interface StateProps {
  isAuthenticated: boolean;
  user: User | null;
  learningProviderId: string | null;
}

interface Props extends StateProps { }

export class Home extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
  }

  render = () => {
    const { isAuthenticated, user, learningProviderId } = this.props;

    document.title = user && user.showAppeals
      ? "Centre Assessment Grades Appeals"
      : "Centre Assessment Grades Capture";

    if (isAuthenticated && user && !user.isUserPermissionValid) {
      return <Redirect to="/access-denied" />
    }

    return (
      <div className="home">
        <div className="jumbotron">
          <h1>{document.title}</h1>
          {learningProviderId === null && (
            <p>Select a learning provider from the menu above to get started.</p>
          )}
          {learningProviderId && user && user.showAppeals === false && (
            <p>Select a qualification group from the menu above to view available qualifications.</p>
          )}
          {learningProviderId && user && user.showAppeals && (
            <>
              <p>Welcome to the appeals system for qualifications with Centre Assessed Grades and/or Ranks.</p>
              <p>Select a qualification area to raise new appeals. Select 'All appeals' to review existing appeals.</p>
              <p>Your Centre Assessed Grades and Ranks can still be downloaded in spreadsheet form from the subject pages.</p>
            </>
          )}
        </div>
      </div>
    )
  };

  componentDidMount = () => {
    if (!auth.isAuthenticated()) {
      auth.signin();
    }
  }
}

export default Home;
import * as React from "react";
import * as _ from "lodash";
import moment from "moment";
import { Qualification } from "../../../models/qualification";
import SearchCard from "../../../components/pearson/search-card";
import { DropdownOption } from "../../../components/pearson/dropdown";

interface QualificationCardProps {
  qualification: Qualification;
  internal: boolean;
  onViewLearners: (qualification: Qualification) => void;
  onDownloadTemplate: (qualification: Qualification) => void;
  onViewFileHistory: (qualification: Qualification) => void;
}

export const QualificationCard = ({
  qualification,
  internal,
  onViewLearners,
  onDownloadTemplate,
  onViewFileHistory
}: QualificationCardProps) => {
  const {
    name,
    code,
    status,
    statusCode,
    numberOfLearners,
    learnersProcessed,
    lastUpdated,
    hasWarnings,
    hasErrors,
    isProcessed,
  } = qualification;

  const getActions = () => {
    const options: DropdownOption[] = [
      { 
        name: `${qualification.readOnly ? "View learners" : "Upload and view learners"}`, 
        onClick: () => onViewLearners(qualification) },
      { 
        name: "Download template", 
        onClick: () => onDownloadTemplate(qualification)
      },
    ];
    if(internal) {
      options.push({name: "View file history", onClick: () => onViewFileHistory(qualification)});
    }
    return options;
  };

  return (
    <div className="qualification-card">
      <SearchCard
        id={code}
        headerElement={
          <>
            <span className="qualification" aria-label="Qualification name">
              <strong>{name}</strong>
            </span>
            <dl className="code">
              <dt>Code:</dt>
              <dd>{code}</dd>
            </dl>
            <dl className="status">
              <dt>Status:</dt>
              <dd>{status}</dd>
            </dl>
            {statusCode !== "NoDataReceived" && (
              <>
                <dl className="number-of-learners">
                  <dt>Number of learners:</dt>
                  <dd>{numberOfLearners}</dd>
                </dl>
                <dl className="learners-processed">
                  <dt>Learners processed:</dt>
                  <dd>{learnersProcessed}</dd>
                </dl>
                <dl className="last-updated">
                  <dt>Last updated:</dt>
                  <dd>
                    {lastUpdated
                      ? moment(lastUpdated).format("DD/MM/YYYY HH:mm")
                      : ""}
                  </dd>
                </dl>
              </>
            )}
          </>
        }
        dropdownMenuRight={true}
        errorMessage={hasErrors ? status : null}
        warningMessage={hasWarnings ? status : null}
        successMessage={isProcessed ? status : null}
        actions={getActions()}
      />
    </div>
  );
};

export default QualificationCard;

import initialState from './initialState';
import _ from "lodash";
import { AppealManagementActions } from "../actions/appeal-management.actions"
import { AppealsManagement } from '../models/appeals-management';
import { TeamAppeal } from '../models/team-appeal';
import {
  ASSIGN_APPEAL_SUCCESS,
  GET_APPEAL_BY_REFERENCE_CODE_SUCCESS,
  GET_MY_TEAM_APPEALS_SUCCESS,
  GET_TEAM_APPEALS_SUCCESS,
  GET_UNALLOCATED_TEAM_APPEALS_SUCCESS,
  SET_APPEALS_MANAGEMENT_ACTIVE_IDX,
  SET_APPEALS_MANAGEMENT_TEAM_ID,
  SET_APPEALS_MANAGEMENT_TEAM_WORK_SEARCH
} from "../actions/action-types";

export function appealsManagementReducer(
  state: AppealsManagement = initialState.appealsManagement,
  action: AppealManagementActions
): AppealsManagement {

  switch (action.type) {
    case ASSIGN_APPEAL_SUCCESS:
      const assignedAppeal = _.find(state.unallocatedWork, { "appealId": action.appealId });
      return {
        ...state,
        myWork: assignedAppeal
          ? [assignedAppeal, ...state.myWork]
          : [...state.myWork],
        unallocatedWork: _.filter(state.unallocatedWork, (appeal: TeamAppeal) => {
          return appeal.appealId !== action.appealId;
        })
      };
    case GET_APPEAL_BY_REFERENCE_CODE_SUCCESS:
      return {
        ...state,
        referenceCodeAppeal: action.appeal,
        referenceCodeIsValid: action.appeal !== null,
        referenceCodeSearch: action.referenceCode
      };
    case GET_MY_TEAM_APPEALS_SUCCESS:
      return {
        ...state,
        myWork: [...action.appeals],
        myWorkSearch: { ...action.appealSearch, showNoAppealsMessage: true },
      };
    case GET_TEAM_APPEALS_SUCCESS:
      return {
        ...state,
        teamWork: [...action.appeals],
        teamWorkSearch: { ...action.appealSearch,
          assignedPearsonUserId: "All", 
          showNoAppealsMessage: true  
        }
      };
    case GET_UNALLOCATED_TEAM_APPEALS_SUCCESS:
      return {
        ...state,
        unallocatedWork: action.loadMoreWork
          ? [...state.unallocatedWork, ...action.appeals]
          : [...action.appeals],
        unallocatedWorkSearch: { 
          ...action.appealSearch, 
          showNoAppealsMessage: true 
        }
      };
    case SET_APPEALS_MANAGEMENT_ACTIVE_IDX:
      return {
        ...state,
        activeIdx: action.activeIdx,
      };
    case SET_APPEALS_MANAGEMENT_TEAM_ID:
      return {
        ...state,       
        teamId: action.teamId,
        myWork: [],
        myWorkSearch: {...state.myWorkSearch, showNoAppealsMessage: false},
        teamWork: [],
        teamWorkSearch: {...state.teamWorkSearch, showNoAppealsMessage: false},
        unallocatedWork: [],
        unallocatedWorkSearch: { ...state.unallocatedWorkSearch, 
          hasMoreWork:false, 
          showNoAppealsMessage: false,
          token: null
        }
      };
    case SET_APPEALS_MANAGEMENT_TEAM_WORK_SEARCH:
      return {
        ...state,
        teamWorkSearch: action.teamWorkSearch,
      }
    default:
      return state;
  }
};
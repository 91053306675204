import * as React from "react";
import { StoreState } from "../../../store/store-state";
import { useSelector } from "react-redux";
import _ from "lodash";

interface AppealStateDescriptionProps {
  statusId: number;
}

export const AppealStateDescription = ({
  statusId
}: AppealStateDescriptionProps) => {
  const appealStates = useSelector((state: StoreState) => state.appealStates);

  return (
    <>{_.find(appealStates, {"id" : statusId})?.name}</>
  )
};

export default AppealStateDescription;
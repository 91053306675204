import * as React from "react";
import { FormEvent } from "react";
import * as _ from 'lodash';
import Modal from "react-bootstrap/Modal";
import Button from "../../../components/pearson/button";

interface CancelAppealModalProps {
  show: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const CancelAppealModal = ({
  show,
  onCancel,
  onConfirm,
}: CancelAppealModalProps) => {
 
  const onFormSubmit = (
    event:
      FormEvent<HTMLFormElement> |
      React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    event.preventDefault();
    onConfirm(); 
  };

  return (
    <div className="cancel-appeal-modal">
      <Modal
        show={show}
        onHide={onCancel}
        aria-labelledby="Cancel appeal">
        <Modal.Header closeButton>
          <Modal.Title>Cancel appeal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="cancel-appeal-form" onSubmit={onFormSubmit}>
            <p>You are about to cancel this appeal, are you sure you want to proceed?</p>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            label="Cancel"
            type="button"
            onClick={onCancel}
          />         
          <Button
            label="Ok"
            type="submit"
            className="primary"
            onClick={onFormSubmit}
          />         
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CancelAppealModal;
export const AJAX_CALL_ERROR = "AJAX_CALL_ERROR";
export type AJAX_CALL_ERROR = typeof AJAX_CALL_ERROR;

export const BEGIN_AJAX_CALL = "BEGIN_AJAX_CALL";
export type BEGIN_AJAX_CALL = typeof BEGIN_AJAX_CALL;

export const AUTH_ERROR = "AUTH_ERROR";
export type AUTH_ERROR = typeof AUTH_ERROR;

export const AUTH_IN_PROGRESS = "AUTH_IN_PROGRESS";
export type AUTH_IN_PROGRESS = typeof AUTH_IN_PROGRESS;

export const AUTH_USER = "AUTH_USER";
export type AUTH_USER = typeof AUTH_USER;

export const UNAUTH_USER = "UNAUTH_USER";
export type UNAUTH_USER = typeof UNAUTH_USER;

export const CALLBACK_IN_PROGRESS = "CALLBACK_IN_PROGRESS";
export type CALLBACK_IN_PROGRESS = typeof CALLBACK_IN_PROGRESS;

export const CALLBACK_COMPLETE = "CALLBACK_COMPLETE";
export type CALLBACK_COMPLETE = typeof CALLBACK_COMPLETE;

export const ACKNOWLEDGE_APPEAL_SUCCESS = 'ACKNOWLEDGE_APPEAL_SUCCESS';
export type ACKNOWLEDGE_APPEAL_SUCCESS = typeof ACKNOWLEDGE_APPEAL_SUCCESS;

export const ADD_APPEAL_NOTE_SUCCESS = "ADD_APPEAL_NOTE_SUCCESS";
export type ADD_APPEAL_NOTE_SUCCESS = typeof ADD_APPEAL_NOTE_SUCCESS;

export const APPEAL_FILE_UPLOADED = 'APPEAL_FILE_UPLOADED';
export type APPEAL_FILE_UPLOADED = typeof APPEAL_FILE_UPLOADED;

export const APPEAL_FILE_UPLOAD_IN_PROGRESS = 'APPEAL_FILE_UPLOAD_IN_PROGRESS';
export type APPEAL_FILE_UPLOAD_IN_PROGRESS = typeof APPEAL_FILE_UPLOAD_IN_PROGRESS;

export const APPEAL_FILE_UPLOAD_INCORRECT_FILE_TYPE = 'APPEAL_FILE_UPLOAD_INCORRECT_FILE_TYPE';
export type APPEAL_FILE_UPLOAD_INCORRECT_FILE_TYPE = typeof APPEAL_FILE_UPLOAD_INCORRECT_FILE_TYPE;

export const APPEAL_FILE_UPLOAD_FAILED = 'APPEAL_FILE_UPLOAD_FAILED';
export type APPEAL_FILE_UPLOAD_FAILED = typeof APPEAL_FILE_UPLOAD_FAILED;

export const ASSIGN_APPEAL_SUCCESS = 'ASSIGN_APPEAL_SUCCESS';
export type ASSIGN_APPEAL_SUCCESS = typeof ASSIGN_APPEAL_SUCCESS;

export const CLOSE_APPEAL_SUCCESS = 'CLOSE_APPEAL_SUCCESS';
export type CLOSE_APPEAL_SUCCESS = typeof CLOSE_APPEAL_SUCCESS;

export const CSV_UPLOADED = 'CSV_UPLOADED';
export type CSV_UPLOADED = typeof CSV_UPLOADED;

export const CSV_UPLOAD_IN_PROGRESS = 'CSV_UPLOAD_IN_PROGRESS';
export type CSV_UPLOAD_IN_PROGRESS = typeof CSV_UPLOAD_IN_PROGRESS;

export const CSV_UPLOAD_INCORRECT_FILE_TYPE = 'CSV_UPLOAD_INCORRECT_FILE_TYPE';
export type CSV_UPLOAD_INCORRECT_FILE_TYPE = typeof CSV_UPLOAD_INCORRECT_FILE_TYPE;

export const CSV_UPLOAD_FAILED = 'CSV_UPLOAD_FAILED';
export type CSV_UPLOAD_FAILED = typeof CSV_UPLOAD_FAILED;

export const DELETE_APPEAL_SUCCESS = 'DELETE_APPEAL_SUCCESS';
export type DELETE_APPEAL_SUCCESS = typeof DELETE_APPEAL_SUCCESS;

export const GET_ALL_APPEALS_SUCCESS = 'GET_ALL_APPEALS_SUCCESS';
export type GET_ALL_APPEALS_SUCCESS = typeof GET_ALL_APPEALS_SUCCESS;

export const GET_APPEALS_SUCCESS = 'GET_APPEALS_SUCCESS';
export type GET_APPEALS_SUCCESS = typeof GET_APPEALS_SUCCESS;

export const GET_APPEAL_DETAILS_SUCCESS = 'GET_APPEAL_DETAILS_SUCCESS';
export type GET_APPEAL_DETAILS_SUCCESS = typeof GET_APPEAL_DETAILS_SUCCESS;

export const GET_APPEAL_LEARNERS_SUCCESS = 'GET_APPEAL_LEARNERS_SUCCESS';
export type GET_APPEAL_LEARNERS_SUCCESS = typeof GET_APPEAL_LEARNERS_SUCCESS;

export const GET_APPEAL_TEMPLATES_SUCCESS = 'GET_APPEAL_TEMPLATES_SUCCESS';
export type GET_APPEAL_TEMPLATES_SUCCESS = typeof GET_APPEAL_TEMPLATES_SUCCESS;

export const GET_APPEAL_TYPES_SUCCESS = 'GET_APPEAL_TYPES_SUCCESS';
export type GET_APPEAL_TYPES_SUCCESS = typeof GET_APPEAL_TYPES_SUCCESS;

export const GET_APPEAL_BY_REFERENCE_CODE_SUCCESS = 'GET_APPEAL_BY_REFERENCE_CODE_SUCCESS';
export type GET_APPEAL_BY_REFERENCE_CODE_SUCCESS = typeof GET_APPEAL_BY_REFERENCE_CODE_SUCCESS;

export const GET_GRADESETS_SUCCESS = 'GET_GRADESETS_SUCCESS';
export type GET_GRADESETS_SUCCESS = typeof GET_GRADESETS_SUCCESS;

export const GET_INTERNAL_USER_LEARNING_PROVIDER_SUCCESS = 'GET_INTERNAL_USER_LEARNING_PROVIDER_SUCCESS';
export type GET_INTERNAL_USER_LEARNING_PROVIDER_SUCCESS = typeof GET_INTERNAL_USER_LEARNING_PROVIDER_SUCCESS;

export const GET_LEARNER_GRADES_SUCCESS = 'GET_LEARNER_GRADES_SUCCESS';
export type GET_LEARNER_GRADES_SUCCESS = typeof GET_LEARNER_GRADES_SUCCESS;

export const GET_MY_TEAM_APPEALS_SUCCESS = 'GET_MY_TEAM_APPEALS_SUCCESS';
export type GET_MY_TEAM_APPEALS_SUCCESS = typeof GET_MY_TEAM_APPEALS_SUCCESS;

export const GET_QUALIFICATION_GROUPS_SUCCESS = 'GET_QUALIFICATION_GROUPS_SUCCESS';
export type GET_QUALIFICATION_GROUPS_SUCCESS = typeof GET_QUALIFICATION_GROUPS_SUCCESS;

export const GET_QUALIFICATIONS_SUCCESS = 'GET_QUALIFICATIONS_SUCCESS';
export type GET_QUALIFICATIONS_SUCCESS = typeof GET_QUALIFICATIONS_SUCCESS;

export const GET_QUALIFICATION_SUCCESS = 'GET_QUALIFICATION_SUCCESS';
export type GET_QUALIFICATION_SUCCESS = typeof GET_QUALIFICATION_SUCCESS;

export const GET_TEAMS_SUCCESS = 'GET_TEAMS_SUCCESS';
export type GET_TEAMS_SUCCESS = typeof GET_TEAMS_SUCCESS;

export const GET_TEAM_APPEALS_SUCCESS = 'GET_TEAM_APPEALS_SUCCESS';
export type GET_TEAM_APPEALS_SUCCESS = typeof GET_TEAM_APPEALS_SUCCESS;

export const GET_UNALLOCATED_TEAM_APPEALS_SUCCESS = 'GET_UNALLOCATED_TEAM_APPEALS_SUCCESS';
export type GET_UNALLOCATED_TEAM_APPEALS_SUCCESS = typeof GET_UNALLOCATED_TEAM_APPEALS_SUCCESS;

export const GET_UPLOAD_FILE_HISTORY_SUCCESS = 'GET_UPLOAD_FILE_HISTORY_SUCCESS';
export type GET_UPLOAD_FILE_HISTORY_SUCCESS = typeof GET_UPLOAD_FILE_HISTORY_SUCCESS;

export const GET_UPLOAD_FILE_SUCCESS = 'GET_UPLOAD_FILE_SUCCESS';
export type GET_UPLOAD_FILE_SUCCESS = typeof GET_UPLOAD_FILE_SUCCESS;

export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export type GET_USER_SUCCESS = typeof GET_USER_SUCCESS;

export const HIDE_ERROR = "HIDE_ERROR";
export type HIDE_ERROR = typeof HIDE_ERROR;

export const RE_OPEN_APPEAL_SUCCESS = "RE_OPEN_APPEAL_SUCCESS";
export type RE_OPEN_APPEAL_SUCCESS = typeof RE_OPEN_APPEAL_SUCCESS;

export const SAVE_APPEAL_SUCCESS = "SAVE_APPEAL_SUCCESS";
export type SAVE_APPEAL_SUCCESS = typeof SAVE_APPEAL_SUCCESS;

export const SAVE_LEARNER_GRADES_SUCCESS = 'SAVE_LEARNER_GRADES_SUCCESS';
export type SAVE_LEARNER_GRADES_SUCCESS = typeof SAVE_LEARNER_GRADES_SUCCESS;

export const SET_ALL_APPEALS_PAGE_STATE = "SET_ALL_APPEALS_PAGE_STATE";
export type SET_ALL_APPEALS_PAGE_STATE = typeof SET_ALL_APPEALS_PAGE_STATE;

export const SET_APPEALS_MANAGEMENT_ACTIVE_IDX = "SET_APPEALS_MANAGEMENT_ACTIVE_IDX";
export type SET_APPEALS_MANAGEMENT_ACTIVE_IDX = typeof SET_APPEALS_MANAGEMENT_ACTIVE_IDX;

export const SET_APPEALS_MANAGEMENT_TEAM_ID = "SET_APPEALS_MANAGEMENT_TEAM_ID";
export type SET_APPEALS_MANAGEMENT_TEAM_ID = typeof SET_APPEALS_MANAGEMENT_TEAM_ID;

export const SET_APPEALS_MANAGEMENT_TEAM_WORK_SEARCH = "SET_APPEALS_MANAGEMENT_TEAM_WORK_SEARCH";
export type SET_APPEALS_MANAGEMENT_TEAM_WORK_SEARCH = typeof SET_APPEALS_MANAGEMENT_TEAM_WORK_SEARCH;

export const SET_APPEALS_PAGE_STATE = "SET_APPEALS_PAGE_STATE";
export type SET_APPEALS_PAGE_STATE = typeof SET_APPEALS_PAGE_STATE;

export const SET_ERROR = "SET_ERROR";
export type SET_ERROR = typeof SET_ERROR;

export const SET_LEARNER_GRADES_PAGE_STATE = 'SET_LEARNER_GRADES_PAGE_STATE';
export type SET_LEARNER_GRADES_PAGE_STATE = typeof SET_LEARNER_GRADES_PAGE_STATE;

export const SET_LEARNING_PROVIDER = 'SET_LEARNING_PROVIDER';
export type SET_LEARNING_PROVIDER = typeof SET_LEARNING_PROVIDER;

export const SET_QUALIFICATION = 'SET_QUALIFICATION';
export type SET_QUALIFICATION = typeof SET_QUALIFICATION;

export const SET_QUALIFICATION_GROUP = 'SET_QUALIFICATION_GROUP';
export type SET_QUALIFICATION_GROUP = typeof SET_QUALIFICATION_GROUP;

export const SET_QUALIFICATIONS_PAGE_STATE = "SET_QUALIFICATIONS_PAGE_STATE";
export type SET_QUALIFICATIONS_PAGE_STATE = typeof SET_QUALIFICATIONS_PAGE_STATE;

export const SET_UPLOAD_FILE_HISTORY_PAGE_STATE = 'SET_UPLOAD_FILE_HISTORY_PAGE_STATE';
export type SET_UPLOAD_FILE_HISTORY_PAGE_STATE = typeof SET_UPLOAD_FILE_HISTORY_PAGE_STATE;

export const SWITCH_APPEAL_TYPE_SUCCESS = 'SWITCH_APPEAL_TYPE_SUCCESS';
export type SWITCH_APPEAL_TYPE_SUCCESS = typeof SWITCH_APPEAL_TYPE_SUCCESS;

export const TRANSFER_APPEAL_SUCCESS = 'TRANSFER_APPEAL_SUCCESS';
export type TRANSFER_APPEAL_SUCCESS = typeof TRANSFER_APPEAL_SUCCESS;

export const UPDATE_APPEAL_STATUS_SUCCESS = 'UPDATE_APPEAL_STATUS_SUCCESS';
export type UPDATE_APPEAL_STATUS_SUCCESS = typeof UPDATE_APPEAL_STATUS_SUCCESS;

export const VALIDATE_USER_PERMISSIONS_SUCCESS = 'VALIDATE_USER_PERMISSIONS_SUCCESS';
export type VALIDATE_USER_PERMISSIONS_SUCCESS = typeof VALIDATE_USER_PERMISSIONS_SUCCESS;
import * as React from "react";
import { FormEvent } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "./pearson/button";
import Input from "./pearson/input";

interface SelectLearningProviderModalProps {
  id:string;
  show: boolean;
  learningProviderCode: string;
  error: string;
  onLearningProviderCodeChange: (learningProviderCode: string) => void;
  onCancel: () => void;
  onSearch: (learningProviderCode: string) => void;
}

const SelectLearningProviderModal = ({
  id,
  show,
  learningProviderCode,
  error,
  onLearningProviderCodeChange,
  onCancel,
  onSearch,
}: SelectLearningProviderModalProps) => {

  const onChange = (event: FormEvent<HTMLInputElement>) => {
    event.preventDefault;
    onLearningProviderCodeChange((event.target as HTMLInputElement).value);
  };

  const onFormSubmit = (
    event:
      | FormEvent<HTMLFormElement>
      | React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    event.preventDefault();
    onSearch(learningProviderCode);
  };

  const onShow = () => {
    document.getElementById("searchTextInput")?.focus();
  };

  return (
    <div className="select-learning-provider-modal">
      <Modal
        id={id}
        show={show}
        onHide={onCancel}
        onShow={onShow}
        aria-labelledby="Select Learning Provider">
        <Modal.Header closeButton>
          <Modal.Title>Select Learning Provider</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={onFormSubmit}>
            <p>Enter a learning provider code below and press search.</p>
            <span className="search-text-input">
              <Input
                id="searchTextInput"
                label="Learning provider code:"
                value={learningProviderCode}
                errors={error}
                maxLength={15}
                style={{ width: "200px" }}
                onChange={onChange}
              />
            </span>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            label="Select"
            type="submit"
            className="primary"
            disabled={learningProviderCode.trim() === ""}
            onClick={onFormSubmit}
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SelectLearningProviderModal;
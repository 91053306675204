import { connect } from "react-redux";
import { StoreState } from "store/store-state";
import { ThunkDispatch } from "redux-thunk";
import { AppealActions, getAppealDetails } from "../../../actions/appeal.actions";
import { AppealFileActions, downloadAppealEvidence, uploadAppealFile } from "../../../actions/appeal-upload.actions";
import { AppealFileUpload } from "../../../models/appeal-file-upload";
import { AppealManagementActions, addAppealNote, assignAppeal, closeAppeal, transferAppeal, updateAppealStatus, switchAppealType, reOpenAppeal } from "../../../actions/appeal-management.actions";

import {
  ViewAppealPage,
  StateProps,
  OwnProps,
  DispatchProps,
} from "./view-appeal-page";

const mapStateToProps = (state: StoreState, ownProps: OwnProps): StateProps => {
  const appealId = ownProps.match.params.appealId;
  const learningProviderId = ownProps.match.params.learningProviderId;
  const appeal = state.appealDetail;
  const user = state.user;
  const internal = user ? user.isInternal : false;
  const showAppealManagement = internal && appeal && appeal.statusId > 1;
  const assignedToMe = user && internal && appeal && appeal.assignedUser && appeal.assignedUser.pearsonUserId === user.pearsonUserId;

  return {
    appealId,
    appeal: appeal,
    appealTemplates: state.appealTemplates,
    assignedToMe: assignedToMe ?? false,
    learningProviderId: internal && learningProviderId 
      ? learningProviderId 
      : state.selectedLearningProviderId,
    showAppealManagement: showAppealManagement ?? false,
    teams: state.teams,
    appealTypes: state.appealTypes,
    user
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<StoreState, void,
  AppealActions |
  AppealFileActions |
  AppealManagementActions
>): DispatchProps => ({
  addAppealNote: (
    learningProviderId: string,
    appealId: string,
    note: string) =>
    dispatch(addAppealNote(learningProviderId, appealId, note)),
  assignAppeal: (
    learningProviderId: string,
    appealId: string) =>
    dispatch(assignAppeal(learningProviderId, appealId)),
  closeAppeal: (
    learningProviderId: string,
    appealId: string,
    outcomeId: number,
    letterText: string) =>
    dispatch(closeAppeal(learningProviderId, appealId, outcomeId, letterText)),
  downloadAppealEvidence: (
    learningProviderId: string,
    appealId: string,
    fileId: string) =>
    downloadAppealEvidence(learningProviderId, appealId, fileId),
  getAppealDetails: (
    learningProviderId: string,
    appealId: string) =>
    dispatch(getAppealDetails(learningProviderId, appealId)),
  transferAppeal: (
    learningProviderId: string,
    appealId: string,
    teamId: number) =>
    dispatch(transferAppeal(learningProviderId, appealId, teamId)),    
  updateAppealStatus: (
    learningProviderId: string,
    appealId: string,
    statusId: number,
    note: string) =>
    dispatch(updateAppealStatus(learningProviderId, appealId, statusId, note)),
  uploadAppealFile: (
    appealFileUpload: AppealFileUpload) =>
    dispatch(uploadAppealFile(appealFileUpload)),
  reOpenAppeal: (learningProviderId: string, appealId: string) => 
    dispatch(reOpenAppeal(learningProviderId, appealId)),
  switchAppealType: (
      learningProviderId: string,
      appealId: string,
      appealTypeId: number
    ) => 
    dispatch(switchAppealType(learningProviderId, appealId, appealTypeId))
});

export default connect<StateProps>(
  mapStateToProps,
  mapDispatchToProps
)(ViewAppealPage);

import * as React from "react";
import Routes from "./routes";
import { BrowserRouter } from "react-router-dom";
import _ from "lodash";
import { User } from "../models/user";
import { AuthProfile } from "models/auth";
import { QualificationGroup } from "../models/qualification-group";
// import ErrorNotification from "../components/pearson/error-notification";
import Header from "../components/pearson/header";
import NavagationBar from "../components/navigation-bar";
import Footer from "../components/pearson/footer";
import config from "../app.config";
import { alert } from "../components/pearson/alert";
import "@pearson-ux/gravity-css";

export interface DispatchProps {
  getAppealTemplates: () => void;
  getAppealTypes: () => Promise<void>;
  getInternalUserLearningProvider: (learningProviderCode: string) => Promise<boolean>;
  getQualificationGroups: (learningProviderId: string) => Promise<void>;
  getTeams: () => Promise<void>;
  getUser: (forgeRockId: string) => Promise<void>;
  setLearningProvider: (learningProviderId: string | null) => Promise<void>;
  signinUser: () => void;
  signoutUser: () => void;
}

export interface StateProps {
  isAuthenticated: boolean;
  learningProviderId: string | null;
  profile?: AuthProfile;
  qualificationGroups: QualificationGroup[];
  user: User | null;
}

type Props = DispatchProps & StateProps;

export class App extends React.Component<Props, {}> {
  LEARNING_PROVIDER_PLACEHOLDER_TEXT: string = "Select a Learning Provider";

 constructor(props: Props) {
    super(props);
  }

  render = () => {    
    const {
      isAuthenticated,
      learningProviderId,
      profile,
      user,
      qualificationGroups,
      getInternalUserLearningProvider
    } = this.props;
    
    const userName = isAuthenticated && profile
      ? profile.name
      : "";  

    return (
      <>
        <BrowserRouter>
          {/* <ErrorNotification/> */}
          <div className="content">
            <Header
              className="header"
              applicationName={user && user.showAppeals
                ? "Centre Assessment Grades Appeals"
                : "Centre Assessment Grades Capture"}
              loggedIn={isAuthenticated}
              name={userName}
              logout={this.logout}
            />
            <NavagationBar
              isAuthenticated={isAuthenticated}
              user={user}
              learningProviderId={learningProviderId ?? ""}
              qualificationGroups={qualificationGroups}
              onLearningProviderChange={this.onLearningProviderChange}
              onGetInternalUserLearningProvider={getInternalUserLearningProvider}
            />
            <main id="main" className="main ">
              <Routes user={user} />
            </main>
          </div>
         <div className="footer">
            <Footer
              theme="dark"
              links={[
                {
                  name: "Terms of Use",
                  href: config.HELP_LINKS.TERMS_OF_USE
                },
                {
                  name: "Privacy Policy",
                  href: config.HELP_LINKS.PRIVACY_POLICY
                },
                {
                  name: "Contact Us",
                  href: config.HELP_LINKS.CONTACT_US
                }
              ]}
            />
         </div>
        </BrowserRouter>
      </>
    );
  };

  componentDidMount = () => {
    const { 
      learningProviderId,
      profile, 
      isAuthenticated,
      getAppealTemplates,
      getAppealTypes,
      getQualificationGroups,
      getTeams,
      getUser
    } = this.props;  
    if (profile && profile.pearsonUID) {
      Promise.all([
        getAppealTemplates(),
        getAppealTypes(),
        getTeams(),
        getUser(profile.pearsonUID)
      ])
      .then(() => {        
        if (isAuthenticated && learningProviderId) {
          getQualificationGroups(learningProviderId)
            .then()
            .catch(err => alert.error(err));
        }
      })
      .catch(err => alert.error(err));
    }
  }

  login = () => {
    this.props.signinUser!();
  };

  logout = () => {
    this.props.signoutUser!();
  };

  onLearningProviderChange = (learningProviderId: string | null) => {
    const { setLearningProvider, getQualificationGroups } = this.props;   
    if (learningProviderId) { 
      setLearningProvider(learningProviderId)
        .then(() => {
            getQualificationGroups!(learningProviderId)
              .then()
              .catch(e => console.log(e))
        }).catch(e => console.log(e))
    }
  }
}

export default App;

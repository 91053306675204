import { AppealEvidence } from "./appeal-evidence";
import { AppealLearner } from "./appeal-learner";
import { AppealUser } from "./appeal-user";
import { AppealNote } from "./appeal-note";
import { LearningProvider } from "./learning-provider";
import { Qualification } from "./qualification";

export class AppealDetail {
  appealId: string;
  appealTypeId: number;
  assignedUser: AppealUser | null;
  evidence: AppealEvidence[] = [];
  hoC: boolean | undefined;
  lastUpdateDateTime: Date | null;
  lastUpdatedUser: AppealUser | null;
  learnerConsent: boolean | undefined;
  learningProvider: LearningProvider;
  learningProviderId: string;
  learners: AppealLearner[] = [];
  learnersInAppealCount: number;
  notes: AppealNote[] = [];
  outcomeId: number | null;
  qualification: Qualification;
  qualificationDisplayName: string;
  qualificationId: string;
  qualificationGroupId: string;
  rationale: string;
  referenceCode: string;
  statusId: number;
  submittedDateTime: Date | null;
  submittedByUser: AppealUser | null;
  tandC: boolean | undefined;
  teamId: number | null;
  team: string;
  totalLearnerCount: number;
  ucasLearners: boolean | undefined;
}
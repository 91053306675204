import * as React from "react";
import { useSelector } from "react-redux";
import { FormEvent } from "react";
import { StoreState } from "../../../../store/store-state";
import _ from "lodash";
import Select from "../../../../components/pearson/select";
import {Option} from "../../../../models/option";

interface AppealStatusSelectProps {
  statusId: number;
  optional: boolean;
  onChange: (statusId: number) => void;
}
export const AppealStatusSelect = ({
  statusId,
  optional,
  onChange
}: AppealStatusSelectProps) => {

  const getOptions = () => {
    const options: Option[] = [];
    const appealStates = _.filter(
      useSelector((state: StoreState) => state.appealStates), { "appealWorkflow": true }
    );    
    if (optional) {
      options.push({ value: 0, name: "All" });
    };
    _.each(appealStates, appealState => {
      options.push({ value: appealState.id, name: appealState.name })
    }); 
    return options;
  }

  return (
    <div className="appeal-status-select">
      <Select
        id="appealStatusSelect"
        label="Appeal status:"
        value={statusId}
        options={getOptions()}
        onChange={(e: FormEvent<HTMLSelectElement>) => {
          onChange(parseInt(e.currentTarget.value))
        }}
      />
    </div>
  )
};

AppealStatusSelect.defaultProps = {
  optional: true
}

export default AppealStatusSelect;

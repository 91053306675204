import * as React from 'react';

interface LoaderProps {
  loading: boolean,
  loadingStateLabel?: string,
  finishedStateLabel?: string
}

export const Loader = ({
         loading,
         loadingStateLabel,
         finishedStateLabel,
       }: LoaderProps) =>
         loading ? (
           <div className="loader">
             <pearson-loader
               loading={loading}
               loadingStateLabel={loadingStateLabel}
               finishedStateLabel={finishedStateLabel}
             />
           </div>
         ) : (
           <></>
         );

export default Loader;

Loader.defaultProps = {
  loadingStateLabel: ""
}
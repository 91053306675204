import * as React from 'react';
import { useEffect } from 'react';

<script src="https://unpkg.com/@pearson-ux/header" />;
interface HeaderProps {
  className: string,
  notifications?: number,
  name?: string,
  loggedIn: boolean;
  theme?: "light" | "dark";
  logolink?: string;
  applicationName?: string;
  logout: () => void;
}

const Header = ({
  className,
  notifications,
  name,
  loggedIn,
  theme,
  logolink,
  applicationName,
  logout
}: HeaderProps) => {
  
  const headerRef: any = React.createRef();
 
  useEffect(() => {
    headerRef.current.addEventListener('logout', () => {
      logout();
    });
  }, []);  

  return (
    <div style={{ width: "100%" }} className="header">
      <pearson-header
        ref={headerRef}
        notifications={notifications!.toString()}
        name={name}
        theme={theme}
        logolink={logolink}
        loggedIn={loggedIn ? "true" : "false"}
      >        
        {applicationName && (
          <span className="application-name" style={{ margin: "5px 20px" }}>{applicationName}</span>
        )}
      </pearson-header>
    </div>
  );
};

Header.defaultProps = {
  notifications: 0,
  loggedIn: false,
  theme: "light"
}

export default Header;
import initialState from './initialState';
import { AppealActions } from "../actions/appeal.actions"
import { AppealManagementActions } from "../actions/appeal-management.actions"
import { AppealDetail } from '../models/appeal-detail';

import { 
  ADD_APPEAL_NOTE_SUCCESS,
  ASSIGN_APPEAL_SUCCESS,
  GET_APPEAL_DETAILS_SUCCESS,
  SAVE_APPEAL_SUCCESS
} from "../actions/action-types";

export function appealDetailReducer(
  state: AppealDetail | null = initialState.appealDetail,
  action: AppealActions | AppealManagementActions
): AppealDetail | null  {

  switch (action.type) {

    case GET_APPEAL_DETAILS_SUCCESS:
    case SAVE_APPEAL_SUCCESS:
      return {...action.appealDetail};

    case ADD_APPEAL_NOTE_SUCCESS:
      if (state) {
        return { ...state, notes: [action.note, ...state.notes]}
      };
      return state;
    case ASSIGN_APPEAL_SUCCESS:
      if (state) {
        return { ...state, assignedUser: action.assignedUser}
      };
      return state;
    default:
      return state;
  }
};


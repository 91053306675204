import * as React from "react";
import * as _ from "lodash";
import moment from "moment";
import { AppealEvidence } from "../../../models/appeal-evidence";
import Icon from "../../../components/pearson/icon";

interface AppealEvidenceCardProps {
  appealEvidence: AppealEvidence;
  showDownloadLink: boolean;
  onDownloadAppealEvidence?: (fileId: string) => void;
}
export const AppealEvidenceCard = ({
  appealEvidence, 
  showDownloadLink,
  onDownloadAppealEvidence
}: AppealEvidenceCardProps) => {

  const downloadAppealEvidence = () => {
    onDownloadAppealEvidence!(appealEvidence.fileId)
  }
  
  return (
    <div className="appeal-evidence-card">
      <div className="card-container">
        <div className="card">
          <div className="info">
            <span className="filename" aria-label="Filename">
              <strong>{appealEvidence.filename}</strong>
            </span>
            <dl className="username">
              <dt>Uploaded by:</dt>
              <dd>{appealEvidence.username}</dd>
            </dl>
            <dl className="added-date">
              <dt>Uploaded date:</dt>
              <dd>{moment(appealEvidence.addedDateTime).format("DD/MM/YYYY HH:mm")}</dd>
            </dl>           
            <dl className="file-status">
              <dt>Status:</dt>
              <dd>{appealEvidence.fileStatusName}</dd>
            </dl>
            {showDownloadLink && appealEvidence.allowDownload && onDownloadAppealEvidence && (
              <div className="actions" onClick={downloadAppealEvidence}>
                <span data-tooltip="Download attachment">
                  <Icon icon="download" size="24" fill="information" />
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppealEvidenceCard;
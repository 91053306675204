import initialState from './initialState';
import { UploadFileHistoryActions } from "../actions/upload-file-history.actions";
import { GET_UPLOAD_FILE_HISTORY_SUCCESS } from "../actions/action-types";
import { UploadFileHistory } from '../models/upload-file-history';

export function uploadFileHistoryReducer(
  state: UploadFileHistory[] | null = initialState.uploadFileHistories,
  action: UploadFileHistoryActions 
): UploadFileHistory[] | null {

  switch (action.type) {
    case GET_UPLOAD_FILE_HISTORY_SUCCESS:
      return Object.assign({}, action.uploadFileHistories) 
    default:
      return state;
  }
};


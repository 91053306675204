import * as React from "react";
import { RouteComponentProps, Redirect } from "react-router";
import _ from "lodash";
import { AppealDetail } from "../../../models/appeal-detail";
import { AppealFileUpload } from "../../../models/appeal-file-upload";
import { AppealFileUploadStatus } from "../../../models/appeal-file-upload-status";
import { AppealLearner } from "../../../models/appeal-learner";
import { INCOMPLETE } from "../../../models/appeal-state";
import { AppealSubmit } from "../../../models/appeal-submit";
import { AppealType } from "../../../models/appeal-type";
import { alert } from "../../../components/pearson/alert";
import AppealForm from "../components/appeal-form";
import Loader from "../../../components/pearson/loader";

export interface StateProps {
  internal: boolean;
  appealId: string;
  appeal: AppealDetail | null;
  appealFileUploadStatus: AppealFileUploadStatus | null;
  appealTypes: AppealType[];
  learningProviderId: string | null;
}

export interface DispatchProps {
  acknowledgeAppeal: (
    learningProviderId: string,
    appealId: string
  ) => Promise<void>;
  downloadAppealEvidence: (
    learningProviderId: string,
    appealId: string,
    fileId: string) => void;
  getAppealDetails: (
    learningProviderId: string,
    appealId: string
  ) => Promise<void>;
  saveAppeal: (
    learningProviderId: string,
    qualificationId: string,
    appeal: AppealSubmit
  ) => Promise<void>;
  uploadAppealFile: (
    appealFileUpload: AppealFileUpload
  ) => Promise<void>;
};

export type OwnProps = RouteComponentProps<{ appealId: string }>;
interface Props extends DispatchProps, StateProps, OwnProps { }

export interface LocalState {
  availableAppealTypes: AppealType[];
  backToAllAppeals: boolean;
  editedAppeal: AppealSubmit;
  loading: boolean;  
}

export class EditAppealPage extends React.Component<Props, LocalState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      availableAppealTypes: _.filter(props.appealTypes, "isAvailable"),
      backToAllAppeals: props.location.pathname.startsWith('/all-appeals'),
      editedAppeal: this.mapEditedAppeal(props.appeal),
      loading: true
    };
  }

  render = () => {
    const { appealId, appeal, internal, learningProviderId, saveAppeal, history} = this.props;
    const { availableAppealTypes, backToAllAppeals, editedAppeal, loading } = this.state;
   
    if (!learningProviderId || !appealId) {
      return <Redirect to="/" />
    }

    if (appeal && !internal && appeal.statusId !== INCOMPLETE) {
      return <Redirect to="/access-denied" />
    }

    const backLinkName = backToAllAppeals
      ? "Back to all appeals"
      : `Back to ${appeal?.qualificationGroupId} appeals`

    const backToPath = backToAllAppeals
      ? "/all-appeals"
      : `/qualifications/${appeal?.qualificationGroupId}/${appeal?.qualificationId}/appeals`


    return (
      <div className="page edit-appeal">
        <Loader loading={loading} loadingStateLabel="Loading..." />
        {!loading && appeal && (
          <AppealForm
            appealFormMode={appeal.statusId === INCOMPLETE ? "Complete" : "Edit"}
            appeal={editedAppeal}
            appealEvidence={appeal.evidence}
            appealTypes={availableAppealTypes}
            backLinkName={backLinkName}
            backToPath={backToPath}
            learners={appeal.learners}
            learningProviderId={learningProviderId}
            qualificationDisplayName={appeal.qualificationDisplayName ?? ""}            
            onCancel={() => history.push(backToPath)}
            onChange={(editedAppeal) => this.setState({ editedAppeal })}
            onDownloadAppealEvidence={this.props.downloadAppealEvidence}
            onUploadAppealFile={this.props.uploadAppealFile}
            onAcknowledgeAppeal={() => {
              return this.props.acknowledgeAppeal(learningProviderId, appeal.appealId);
            }}
            onSaveAppeal={() => {
              return saveAppeal(learningProviderId, appeal.qualificationId, editedAppeal);
            }}                      
          />          
        )}       
      </div>
    )
  };

  componentDidMount = () => {
    const { learningProviderId, appealId, getAppealDetails } = this.props;
    if (learningProviderId && appealId) {
      getAppealDetails(learningProviderId, appealId)
      .catch((error) => alert.error(error))
      .finally(() => {
        this.setState({ loading: false })
      })
    }
  };

  componentDidUpdate(prevProps: StateProps, prevState: LocalState) {
    const { learningProviderId, appeal } = this.props;
    if (learningProviderId && appeal) {     
      if (!_.isEqual(prevProps.appeal, appeal)) {
        this.setState({ 
          editedAppeal: this.mapEditedAppeal(appeal) 
        })
      }
    }
  }

  downloadAppealEvidence = (fileId: string) => {
    const { appealId, learningProviderId, downloadAppealEvidence } = this.props;
    if (learningProviderId && appealId) {
      downloadAppealEvidence(learningProviderId, appealId, fileId);
    }
  }

  mapEditedAppeal = (appeal: AppealDetail | null) => {
    const editedAppeal = new AppealSubmit();    
    if(appeal) {
      const appealType = _.find(this.state.availableAppealTypes, ["id", appeal.appealTypeId]);
      editedAppeal.appealId = appeal.appealId;
      editedAppeal.appealTypeId = appealType ? appeal.appealTypeId : undefined;
      editedAppeal.rationale = appeal.rationale;
      editedAppeal.ucasLearners = appeal.ucasLearners;
      if (appeal.statusId !== INCOMPLETE) {
        editedAppeal.hoC = appeal.hoC;
        editedAppeal.learnerConsent = appeal.learnerConsent;       
        editedAppeal.tandC = appeal.tandC;
      }      
      editedAppeal.learnerIdentifiers = _.map(_.filter(appeal.learners, ["isInAppeal", true]),
        (learner: AppealLearner) => learner.learnerIdentifier
      );
    }
    return editedAppeal;
  }
}

export default EditAppealPage;